import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { MissionsBackground } from "../../../Data/export_images";

const missions = ["PROMOTE", "ENCOURAGE", "NURTURE"];

const missionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: "easeInOut" } },
    exit: { opacity: 0, y: -20, transition: { duration: 0.5, ease: "easeInOut" } }
};

const MissionsComponent = () => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % missions.length);
        }, 3000); // Change mission every 3 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="relative flex flex-col items-center justify-center max-md:h-[400px] h-[40vw] z-0 py-20 max-[480px]:py-10 max-lg:py-12">
            <img className="absolute inset-0 -z-10 w-full max-md:h-[400px] max-md:object-fill min-h-[500px]" src={MissionsBackground}></img>
            <div className='absolute inset-0 bg-black opacity-50 -z-10'/>
            <div className="w-3/5 max-[480px]:w-4/5 h-fit flex-shrink-0 text-[rgb(207,161,78)] font-meslo text-5xl font-bold leading-normal mx-auto text-center flex flex-col items-center max-lg:gap-y-6 gap-y-10">
                <h1 className="max-[480px]:text-[2rem] max-md:text-[2.5rem] max-xl:text-[3rem] text-[3.5rem]">DEDICATED TO</h1>
                <AnimatePresence mode="wait">
                    <motion.div
                        key={missions[index]}
                        variants={missionVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        className="max-lg:text-3xl max-xl:text-4xl text-5xl"
                    >
                        {missions[index]}
                    </motion.div>
                </AnimatePresence>
                <h1 className="max-[480px]:h-fit max-[480px]:text-[2rem] max-md:text-[2.5rem]  max-xl:text-[3rem] text-[3.5rem]">THE CAPTIVATING WORLD OF CINEMA</h1>
            </div>
        </div>
    );
};

export default MissionsComponent;
