import { motion, useMotionValue } from "framer-motion";
import { useState, FC, useEffect, useRef } from "react";
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";
import { Film } from "../../../Data/films";
import FilmCardCarousel from "./FilmCardCarousel";

interface CarouselProps {
  films: Film[];
  slides_count: number;
}

interface FilmContainerProps {
  films: Film[];
}

const FilmCardContainer:FC<FilmContainerProps> = ({films}) => {
  return(
    <>
      {films.map((film, index) => {
        return (
          <FilmCardCarousel key={film.name} film={film} />
        );
      })}
    </>
    
  )
}

interface SlidesProps {
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  films: Film[];
  setDotsCount: React.Dispatch<React.SetStateAction<number>>;
  dots_count: number;
}


const Slides = ({current, setCurrent, films, dots_count, setDotsCount} : SlidesProps) => {
  const DRAG_BUFFER = 50;
  const slidesRef = useRef<HTMLDivElement>(null);
  
  const dragX = useMotionValue(0);

  const onDragEnd = () => {
    const x = dragX.get();

    if (x <= -DRAG_BUFFER && current < films.length - 1) {
      setCurrent((current) => current + 1);
    } else if (x >= DRAG_BUFFER) {
      if (current === 0) setCurrent(films.length - 1);
      else setCurrent((current) => current - 1);
    }
  }


  const FILM_CARD_WIDTH_LARGE = 300;
  const FILM_CARD_WIDTH_SMALL = 200;
  const gapX = 40;
  const gapXInitial = 20;
  let gapTotal = 0;
  let slidesWidthTotal = slidesRef.current?.offsetWidth;
  let slidesWidthActual = slidesWidthTotal! - gapTotal;
  let films_per_slide_temp = Math.floor(slidesWidthTotal! / FILM_CARD_WIDTH_LARGE);
  let films_per_slide_actual = Math.floor(slidesWidthActual / FILM_CARD_WIDTH_LARGE) || 1;

  const [film_card_width, setFilmCardWidth] = useState<number>(FILM_CARD_WIDTH_LARGE);
  
  const updateValues = () => {
    if (slidesRef.current) {
      slidesWidthTotal = slidesRef.current.offsetWidth;
      films_per_slide_temp = Math.floor(slidesWidthTotal / film_card_width);
      gapTotal = gapXInitial + gapX * films_per_slide_temp;
      slidesWidthActual = slidesWidthTotal - gapTotal;
      films_per_slide_actual = Math.floor(slidesWidthActual / film_card_width) || 1;
      console.log(films_per_slide_actual);
      setDotsCount(Math.ceil(films.length / films_per_slide_actual));
      console.log(slidesWidthTotal, films_per_slide_temp, films_per_slide_actual, gapTotal, slidesWidthActual);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth > 600) {
        setFilmCardWidth(FILM_CARD_WIDTH_LARGE);
      } else {
        setFilmCardWidth(FILM_CARD_WIDTH_SMALL);
      }

      updateValues();
    }
  
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', updateValues);
  }, []);

  useEffect(() => {
    console.log(current);
    console.log(films_per_slide_actual);
    console.log(current * films_per_slide_actual * (film_card_width + gapX) + gapXInitial);
    // console.log(-1 * (current * films_per_slide_actual * (film_card_width + gapX) + gapXInitial));
  }, [current]);

  return (
    <div ref={slidesRef} className="overflow-hidden flex flex-col justify-center relative bg-black rounded-xl  shadow-white shadow-md">
      <motion.div
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        animate={{ translateX: (-1 * (current * films_per_slide_actual * (film_card_width + gapX) + gapXInitial)) + "px" }}
        className={`flex pl-12 max-sm:py-5 py-10 gap-x-10 max-sm:px-10 max-[400px]:px-5 transition ease-out duration-40`}
        onDragEnd={onDragEnd}
        style={{ x: dragX }}      
      >
        <FilmCardContainer films={films} />
      </motion.div>
    </div>
  );
}

interface DotsProps {
  films: Film[];
  setCurrent: (i: number) => void;
  current: number;
  dots_count: number;
}

const FILMS_PER_SLIDE = 3;


const Dots = ({films, setCurrent, current, dots_count}: DotsProps) => {
  const dots_count_array = Array.from(Array(dots_count+1).keys());


  useEffect(() => {
    console.log(dots_count);
    console.log(dots_count_array);
  }, [dots_count]);

  return ( 
    <div className="py-20 flex flex-wrap justify-center gap-3 w-full">
      {dots_count_array.map((_, i) => {
        return (
          <div
            onClick={() => {
              setCurrent(i);
            }}
            key={"circle" + i}
            className={`rounded-full w-5 max-[480px]:w-4 h-5 max-[480px]:h-4 cursor-pointer  ${i === current ? "bg-white" : "bg-gray-500"}`}
          ></div>
        );
      })}
    </div>
  );
}

const CarouselMoviesTest: FC<CarouselProps> = ({ films, slides_count }) => {
  let [current, setCurrent] = useState<number>(0);
  // let LAST_SLIDE_INDEX = Math.ceil(films.length / FILMS_PER_SLIDE) - 1;
  const [dots_count, setDotsCount] = useState<number>(0);
  // dots_count = Math.ceil(films.length / FILMS_PER_SLIDE);
  let previousSlide = () => {
    if (current === 0) setCurrent(dots_count);
    else setCurrent(current - 1);
  };

  let nextSlide = () => {
    if (current === dots_count) setCurrent(0);
    else setCurrent(current + 1);
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-x-10 max-sm:gap-x-5 px-10 max-sm:px-5 w-[100vw] h-[550px] max-sm:h-fit">
        <button onClick={previousSlide}>
          <BsFillArrowLeftCircleFill className="w-10 h-10 max-sm:w-5 max-sm:h-5"/>
        </button>
        <Slides current={current} setCurrent={setCurrent} films={films} setDotsCount={setDotsCount} dots_count={dots_count} />
        <button onClick={nextSlide}>
          <BsFillArrowRightCircleFill className="w-10 h-10 max-sm:w-5 max-sm:h-5"/>
        </button>  
      </div>
      <Dots films={films} setCurrent={setCurrent} current={current} dots_count={dots_count}/>
      
    </div>
    
  );
}

export default CarouselMoviesTest;

