import FooterLink from "./FooterLink";

const FooterLinkContainer = ()  => {
    return (
        <section className='flex justify-center ml-10 mr-10 2xl:ml-20 2xl:mr-20 max-lg:order-3'>
            <div className="grid max-md:grid-cols-2  md:grid-cols-3 font-sans max-w-4xl">
                <FooterLink link="/" text="Home"/>
                <FooterLink link="/nomination" text="Nominations 2024"/>
                <FooterLink link="/sponsors" text="Our Sponsors"/>
                <FooterLink link="/about" text="About Us"/>
                <FooterLink link="/contact" text="Contact Us"/>
                <FooterLink link="/sponsor-us" text="Sponsor Us"/>
                <FooterLink link="https://filmfreeway.com/iffa-awards?tpclid=facebook.PAZXh0bgNhZW0CMTEAAabJeQpWqtNQoaQyng5NgUKY924NJ4lZXs8zxwIkFKDRGb-A8_Q4MsSGpGg_aem_rRIoYWLW6NfghTFaAgz4Zw" text="Submit Your Work"/>
                <FooterLink link="/partners" text="Partners"/>
                <FooterLink link="/policy" text="Privacy Policy"/>
            </div>
        </section>
    );
}

export default FooterLinkContainer;