import Navbar from "../Components/Navbar/Navbar";
import Footer from '../Components/Footer/Footer';

import { motion } from 'framer-motion';
import { PartnersBackground } from '../Data/export_images';

const Partners = () => {
    const partnersData = [
        {
            alt: 'RMIT',
            description: 'RMIT University',
            url: 'https://www.rmit.edu.au/'
        },
        {
            alt: 'TORRENS',
            description: 'Torrens University Australia',
            url: 'https://torrens.edu.au/'
        },
        {
            alt: 'FORDHAM PR',
            description: 'Fordham PR',
            url: 'http://www.fordhampr.ca/introducing-the-international-film-festival-of-australia/'
        },
        {
            alt: 'FIFTY ACRES',
            description: 'Fifty Acres',
            url: 'https://fiftyacres.com.au/?utm_source=google&utm_medium=cpc&utm_campaign=PPC_Feb_June_22&gad_source=1&gbraid=0AAAAAobAlNbsvWs0zY9mgmumg2WE3dMIf&gclid=CjwKCAjw8fu1BhBsEiwAwDrsjCq_Iy_CI-cUlXdeGY_m7QnK5etUMJ2eQQJ9IU6C9bSWVCwvohBaZhoCqyYQAvD_BwE'
        },
        {
            alt: 'Istituto Italiano di Cultura di Melbourne',
            description: 'Istituto Italiano di Cultura di Melbourne',
            url: ''
        },
        {
            alt: 'Consulate of the Republic of Korea',
            description: 'Consulate of the Republic of Korea',
            url: ''
        },
        {
            alt: "Creative Production Studio",
            description: "Creative Production Studio",
            url: ""
        }, 
        {
            alt: "Universal Entertainment",
            description: "Universal Entertainment",
            url: ""
        }

    ];

    return (
        <div className="bg-[black] mx-[auto] my-[0] font-[Arial,_sans-serif]">
            <Navbar />
            <motion.main
                initial={{ opacity: 0, x:-150 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1.5, delay: 1 }}
            >
                <header className='relative flex items-center justify-center h-44 text-white'>
                    <h1 className='z-20 shadow-2xl shadow-white rounded-lg px-5 py-2 font-bold text-4xl'>PARTNERS</h1>
                    <div className='absolute z-10 inset-0 bg-black opacity-50'></div>
                    <img src={PartnersBackground} className='absolute top-0 h-full w-full object-cover'></img>
                </header>

                <section className="partners-section text-center mb-[50px] text-[4rem] font-semibold text-[#f1f1f1] tracking-[2px]">
                    <div className="partners-grid max-w-[1000px] grid text-center mr-auto ml-auto grid-cols-[repeat(2,_1fr)] max-sm:grid-cols-1 gap-[50px 50px]">
                        {partnersData.map((partner, index) => (
                            partner.url !== "" ? (
                                <a href={partner.url} target="_blank" rel="noopener noreferrer" key={index}>
                                  <div className="partner p-[20px] [transition:transform_0.3s_ease,_color_0.3s_ease] hover:scale-125">
                                    <p className="text-[30px] text-[#d0d0d0] my-[20px] border-b-2 border-solid border-[#d0d0d0] pb-[20px] transition-colors duration-500 hover:text-[#d3a955] hover:border-[#d3a955]">{partner.description}</p>
                                  </div>
                                </a>
                              ) : (
                                <div key={index}>
                                  <div className="partner p-[20px] [transition:transform_0.3s_ease,_color_0.3s_ease] hover:scale-125">
                                    <p className="text-[30px] text-[#d0d0d0] my-[20px] border-b-2 border-solid border-[#d0d0d0] pb-[20px] transition-colors duration-500 hover:text-[#d3a955] hover:border-[#d3a955]">{partner.description}</p>
                                  </div>
                                </div>
                              )
                        ))}
                    </div>
                </section>
            </motion.main>
            
            <Footer />
        </div>
    );
};

export default Partners;

/* Responsive Styles */
// @media (max-width: 768px) {
//     .partner-reponsive-area{
//         height: 35px;
//     }
    
//     .partners-header {
        
//         height: 150px;
//     }

//     .partners-header h1 {

//         font-size: 2.5rem;
//     }

//     .partners-section h2 {
//         font-size: 2.5rem;
//         margin-bottom: 30px;
//     }

//     .partners-grid {

//         grid-template-columns: 1fr;
//         gap: 30px;
//     }

//     .partner {
//         padding: 15px;
//     }

//     .partner p {
//         font-size: 1.5rem;
//     }

//     .partner img {
//         height: 150px;
//     }
// }

// @media (max-width: 480px) {
//     .partner-reponsive-area{
//         height: 30px;
//     }
//     .partners-header {
//         height: 120px;
//     }

//     .partners-header h1 {
//         font-size: 2rem;
//     }

//     .partners-section h2 {
//         font-size: 2rem;
//         margin-bottom: 20px;
//     }

//     .partner p {
//         font-size: 1.2rem;
//     }

//     .partner img {
//         height: 120px;
//     }
// }