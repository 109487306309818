import Footer from '../Components/Footer/Footer';
import MissionsComponent from '../Components/Home/Missions/Missions';
import HeroSection from '../Components/Home/HeroSection/HeroSection';
import Submissions from '../Components/Home/SubmissionsHome/SubmissionsHome';
import { MonkeyManTrailerCropped } from '../Data/export_videos';

function Home() {
  return (
    <div className='bg-black relative'>
      <HeroSection videoBackground={MonkeyManTrailerCropped}></HeroSection>
      <MissionsComponent></MissionsComponent>
      <Submissions></Submissions>
      <Footer />
    </div>
  );
}

export default Home;
