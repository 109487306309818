import { useNavigate } from "react-router-dom";
import { Film } from "../../Data/films";
import FilmCardSubmission from "../Submission/FilmCardSubmission";
import { useState } from "react";

interface NominationSectionProps {
    title: string;
    nominationList: Film[];
}

const NominationSection = ({...props}: NominationSectionProps) => {
    return (
        <div>
            <div className="flex max-lg:justify-center">
                <h2 className="text-5xl max-[250px]:text-2xl max-[350px]:text-4xl text-light-gold hover:shadow-lg hover:shadow-light-gold rounded-lg max-sm:p-0 p-4 font-bold max-[300px]:w-full max-sm:w-fit w-96 max-lg:text-center mb-10">
                    {props.title}
                </h2>    
            </div>
            <div className="flex items-center ">
                <div className="flex flex-wrap max-sm:gap-x-5 gap-10 justify-left max-lg:justify-center">
                    {props.nominationList.map((film, index) => (          
                        <FilmCardSubmission
                            key={film.name}
                            film={film}
                        />       
                    ))}
                </div>
            </div>
            
        </div>
          
    )
}

export default NominationSection;