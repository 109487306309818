import Navlink from "./Navlink";

interface NavlinkContainerProps {
    isOpen: boolean;
    menuRef: React.RefObject<HTMLDivElement>;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    submissionLink: string;
}

const NavlinkContainer = ({...props} : NavlinkContainerProps) => {
    const submissionLink = "https://filmfreeway.com/iffa-awards?tpclid=facebook.PAZXh0bgNhZW0CMTEAAabJeQpWqtNQoaQyng5NgUKY924NJ4lZXs8zxwIkFKDRGb-A8_Q4MsSGpGg_aem_rRIoYWLW6NfghTFaAgz4Zw";

    return (
        <div>
            <div
                className={`absolute top-full mt-2 right-0 shadow-lg shadow-light-gold bg-black flex flex-col gap-y-4 p-8 rounded-lg ${props.isOpen ? "flex" : "hidden"}`}
                ref={props.menuRef}
            >
                <Navlink setIsOpen={props.setIsOpen} link="/submission" text="FILM LIST" />
                <Navlink setIsOpen={props.setIsOpen} link="/nomination" text="NOMINATION" />
                <Navlink setIsOpen={props.setIsOpen} link="/about" text="ABOUT" />
                <Navlink setIsOpen={props.setIsOpen} link="/contact" text="CONTACT" />
                <Navlink setIsOpen={props.setIsOpen} link="/partners" text="PARTNERS" />
                <Navlink setIsOpen={props.setIsOpen} link={submissionLink} text="SUBMIT YOUR WORK" isSubmit></Navlink>
            </div>
        </div>
    );
}

export default NavlinkContainer;