import { useState, useEffect } from "react";
import axios from 'axios'; // Import axios for API requests
import { useNavigate } from 'react-router-dom';
import Footer from '../Components/Footer/Footer';
import FilmCardSubmission from "../Components/Submission/FilmCardSubmission";
import Pagination from "../Components/Submission/Pagination";
import { allFilmsWithIds} from "../Data/films";
import HeroSection from "../Components/Home/HeroSection/HeroSection";
import { LoveLiesBleedingTrailer } from "../Data/export_videos";

// Define the Film interface (as per your request)
export interface Film {
    name: string;
    poster: any;
    synopsis: string;
    link: string;
    background: any;
    director: {
        name: string;
        image?: string;
    } | {name: string[], image?: string}[];
    year: string;
    country: string;
    duration: string;
}

const Submissions = () => {
    const filmsPerPage = 30;
    const [currentPage, setCurrentPage] = useState(1);

    const [films, setFilms] = useState<Film[]>([]); // State for fetched films
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState<string | null>(null); // Error state

    const navigate = useNavigate();

    // Fetch the films from the backend API when the component mounts
    useEffect(() => {
        const fetchFilms = async () => {
            try {
                const response = await axios.get('http://3.26.69.117:4000/api/films');
                const filmsReordered = response.data.slice(-2).concat(response.data.slice(0, -2)); // Reorder the films
                setFilms(filmsReordered); // Set the films from the API response
                // console.log("data: ", response.data);

                setLoading(false); // Stop loading once data is fetched
            } catch (err) {
                setError("Failed to fetch films."); // Correctly typing the error state
                setLoading(false); // Stop loading if there's an error
            }
        };

        fetchFilms();

    }, []); // Empty array ensures this runs only once when the component mounts

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPage])
    
    // Pagination logic
    const totalPages = Math.ceil(allFilmsWithIds.length / filmsPerPage);
    const startIdx = (currentPage - 1) * filmsPerPage;
    const endIdx = startIdx + filmsPerPage;
    const currentFilms = allFilmsWithIds.slice(startIdx, endIdx);


    // Show loading spinner or message
    // if (loading) {
    //     return <div>Loading films...</div>;
    // }

    // Show error message if something went wrong
    // if (error) {
    //     return <div>{error}</div>;
    // }

    return (
        <div className="Submissions w-full bg-[black] overflow-x-hidden">
            {/* <Navbar /> */}
            <HeroSection videoBackground={LoveLiesBleedingTrailer}/>
            <main>
                <div>
                    <h1 className="text-white text-5xl font-semibold not-italic text-center my-20">SUBMISSIONS 2024</h1>
                </div>
                <div className="mb-20">
                    <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={totalPages}
                    />
                    <div className="flex flex-wrap gap-10 w-4/5 items-center justify-center mt-10 m-auto">
                        {currentFilms.map((film, index) => (
                            <FilmCardSubmission
                                key={index}
                                film={film}
                            />
                        ))}
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={totalPages}
                    />
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default Submissions;