import React from "react";
import { Link } from "react-router-dom";
// import SubmissionSwiper from "./SubmissionSwiper/SubmissionSwiper";
import CarouselMoviesTest from "./MoviesCarousel";
import { allFilmsWithIds, cinemaMovies, featureFilms, internationalAnimation, internationalDocumentary, shortFeatureFilms, under18Films } from "../../../Data/films";

// const filmGroups = [
//     ...cinemaMovies,
//     ...featureFilms,
//     ...internationalAnimation,
//     ...internationalDocumentary,
//     ...shortFeatureFilms,
//     ...under18Films
//   ];
  
function SubmissionsHome() {

    const films = allFilmsWithIds.slice(0, 10);

    return (
        <div className="pt-[10vh] relative bg-black text-white flex flex-col items-center text-center overflow-x-hidden mb-10">
            <main>
                <div className="submissions-header flex items-baseline justify-center">
                    <h1 className="text-3xl md:text-6xl font-sans">SUBMISSIONS 2024</h1>
                </div>
                <div className="mt-5 flex flex-col items-center w-full">
                    {/* <SubmissionSwiper /> */}
                    <CarouselMoviesTest films={films} slides_count={3}/>
                    <Link to="/submission">
                        <button className="text-4xl font-bold text-light-gold shadow-xl shadow-light-gold rounded-md py-2 px-4 mx-auto">
                            See All Movies
                        </button>
                    </Link>        
                </div>
            </main>
        </div>
    )
}

export default SubmissionsHome;
