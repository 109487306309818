import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
// import "./Navbar.css";
import { IFFALogo } from "../../Data/export_images";
import NavlinkContainer from "./NavlinkContainer";
import GetTicketButton from "./GetTicketButton";

const NavbarTitle = () => {
    return (
        <div className="max-sm:hidden xl:ml-40 ml-10 flex flex-col items-start text-white font-raleway text-center ">
            <div className="flex flex-col items-center text-light-gold hover:shadow-light-gold hover:shadow-lg rounded-xl p-4">
                <h1 className="text-3xl xl:text-5xl text-light-gold font-bold ">IFFA Award Ceremony</h1>
                <h4 className="text-xl xl:text-3xl ml-38">5th of November 2024</h4>
            </div>
        </div>
    );
    
}

const Navbar = ({ isHomePage = false }: { isHomePage?: boolean }) => {
    const rootPath = window.location.origin;
    

    const [isOpen, setIsOpen] = useState(false);
    const submissionLink = "https://filmfreeway.com/iffa-awards?tpclid=facebook.PAZXh0bgNhZW0CMTEAAabJeQpWqtNQoaQyng5NgUKY924NJ4lZXs8zxwIkFKDRGb-A8_Q4MsSGpGg_aem_rRIoYWLW6NfghTFaAgz4Zw";

    const menuRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const closeDropdown = (e: MouseEvent) => {
            if (
                e.composedPath()[0] !== menuRef.current &&
                e.composedPath()[0] !== buttonRef.current
            ) {
                setIsOpen(false); // Always close the menu if clicking outside
            }
        };

        document.body.addEventListener("click", closeDropdown);

        return () => {
            document.body.removeEventListener("click", closeDropdown);
        };
    }, []);

    return (
        <motion.div
            initial={{opacity: 0, y: -150}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 1.5}}
            className={`flex justify-between items-center gap-x-4 w-full h-fit p-4 max-sm:px-10 max-sm:pl-20 max-md:px-20 md:px-40 z-50 ${isHomePage ? "bg-transparent absolute max-md:top-0" : "bg-black/40"}`}
        >
            <div className="flex items-center">
                <a href="./" className="mr-10">
                    <img className="transition-transform duration-[0.3s] ease-[ease] scale-[2.3] ~min-[40px]/lg:~h-[40px]/[70px] ~min-[40px]/lg:~w-[60px]/[100px] min-w-[60px]" id="logo" src={rootPath + '/' + IFFALogo} alt="IFFA Logo"/>
                </a>
            </div>
            
            <NavbarTitle />
            <div className="flex items-center justify-between">
                <GetTicketButton />
                <div className="relative z-50">
                    {/* Menu Button */}
                    <div>
                        <div className="text-[2rem] text-white cursor-pointer" ref={buttonRef}
                             onClick={() => setIsOpen(prev => !prev)}>
                            &#9776; {/* Menu icon */}
                        </div>
                    </div>
                    <NavlinkContainer isOpen={isOpen} menuRef={menuRef} setIsOpen={setIsOpen} submissionLink={submissionLink} />
                </div>
            </div>


        </motion.div>
    );
};

export default Navbar;



// function NavlinkContainer(isOpen: boolean, menuRef: React.RefObject<HTMLDivElement>, setIsOpen: React.Dispatch<React.SetStateAction<boolean>>, submissionLink: string) {
//     return <div>
//         <div
//             className={`absolute top-full mt-2 right-0 shadow-lg shadow-light-gold bg-black flex flex-col gap-y-4 p-8 rounded-lg max-lg:bg-white ${isOpen ? "flex" : "hidden"}`}
//             ref={menuRef}
//         >
//             <Navlink setIsOpen={setIsOpen} link="/submission" text="FILM LIST" />
//             <Navlink setIsOpen={setIsOpen} link="/nomination" text="NOMINATION" />
//             <Navlink setIsOpen={setIsOpen} link="/about" text="ABOUT" />
//             <Navlink setIsOpen={setIsOpen} link="/contact" text="CONTACT" />
//             <Navlink setIsOpen={setIsOpen} link="/partners" text="PARTNERS" />
//             <Navlink setIsOpen={setIsOpen} link={submissionLink} text="SUBMIT YOUR WORK" isSubmit></Navlink>
//         </div>
//     </div>;
// }

