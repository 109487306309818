// src/App.js
import React from 'react';
import Home from './pages/Home';
import './index.css';
import Submissions from './pages/Submissions';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import Synopsis from './pages/Synopsis';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Policy from './pages/Policy';
import SponsorUs from './pages/SponsorUs';
import CastDetail from './pages/CastDetails';
import OurSponsors from "./pages/OurSponsors";
import Partners from './pages/Partners';
import Navbar from './Components/Navbar/Navbar';
import Nominations from './pages/Nominations';
import TestPage from './pages/test';

function App() {
  // const moviesCastList = createMoviesCastList(casts);
  // console.log(moviesCastList)
  return (
    <Router>
      <div>
        <Outlet />
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<AboutUs />} />
        <Route path="submission" element={<Submissions />} />
        <Route path="sponsor-us" element={<SponsorUs />} />
        <Route path="synopsis/:filmId" element={<Synopsis />} />
        <Route path="contact" element={<ContactUs />} />
        <Route path="policy" element={<Policy />} />
        <Route path="cast" element={<CastDetail />} />
        <Route path="sponsors" element={<OurSponsors />} />
        <Route path="partners" element={<Partners />} />
        <Route path="nomination" element={<Nominations />} />
        <Route path="test" element={<TestPage />} />

      </Routes>
    </Router>
  );
}

export default App;
