import { Link } from "react-router-dom";

const FooterLink = ({ link, text }: { link: string, text: string }) => {
    return (
        <Link to={link} className="text-left text-gray-400 text-sm sm:text-base hover:text-black hover:bg-white p-2 h-fit min-w-20 max-w-40 px-3 py-4 rounded-sm">
            {text}
        </Link>
    );
}

export default FooterLink;