import { motion } from "framer-motion"
import { Link } from "react-router-dom"

const GetTicketButton = () => {
    const ticketOrderLink = "https://www.ticketmaster.com.au/international-film-festival-tickets/artist/1347704";

    return (
        <div className="max-sm:hidden">
            <motion.div
                initial="hidden"
                animate="visible"
                whileHover={{scale: 1.15}}
                transition={{type: 'spring', stiffness: 300}}
                className="flex items-center justify-center text-white w-fit px-5 xl:w-64 font-bold text-center transition-all duration-500 ease-in-out rounded-xl"
                style={{willChange: "transform"}} // Ensure smooth rendering
            >
                <Link
                    to={ticketOrderLink}
                    className="xl:text-2xl lg:text-xl"
                >
                    Get Tickets
                </Link>
            </motion.div>
        </div>
    )
}

export default GetTicketButton;