import React from 'react';
// import Logo from "../../images/IFFA_logo_2024_Logo Gold (1).png";
import { IFFALogo } from '../../Data/export_images';

import { Link } from 'react-router-dom';
import FooterSocials from './FooterSocials';
import CountryAcknowledgement from './CountryAcknowledgement';

import {motion} from 'framer-motion';
import FooterLink from './FooterLink';
import FooterLinkContainer from './FooterLinkContainer';

function Footer() {
    const rootPath = window.location.origin;
    return (
        <motion.footer
            className="w-full bg-[#0d0d0d] py-4 font-sans px-2"
            initial={{ opacity: 0, x:-150 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.5 }}
        >
            <div className='flex justify-center max-lg:hidden mb-10'>
                <CountryAcknowledgement />
                <FooterLinkContainer />
                <img src={rootPath + "/" + IFFALogo} alt='IFFA-logo' className='lg:h-40 max-lg:h-32 mb-3 max-lg:order-1 self-center'></img>
            </div>
            <div className='flex flex-col justify-center max-md:hidden lg:hidden mb-10'>
                <div className='flex justify-between px-20 mb-5'>
                    <CountryAcknowledgement />    
                    <img src={rootPath + "/" + IFFALogo} alt='IFFA-logo' className='lg:h-40 max-lg:h-32 mb-3 max-lg:order-1 self-center'></img>
                </div>
                <FooterLinkContainer />
            </div>
            <div className='flex flex-col gap-y-5 justify-center md:hidden mb-10'>
                <CountryAcknowledgement />
                <FooterLinkContainer />
                <img src={rootPath + "/" + IFFALogo} alt='IFFA-logo' className='lg:h-40 max-lg:h-32 mb-3 max-lg:order-1 self-center'></img>
            </div>
            
            {/* <p className='headings'>Follow us on</p> */}
            <FooterSocials/>


            <p className='line text-[#9da2ac] text-center text-sm'>© IFFA-International Film Festival of Australia</p>

        </motion.footer>
    );
}

export default Footer;