import React, { useState, useEffect, useRef } from "react";
// import "./frontpage.css";
import MobileHeroSection from "./MobileHeroSection";
import DesktopHeroSection from "./DesktopHeroSection";
import { DriveTrailer } from "../../../Data/export_videos";
// import BackgroundVids from "../../Videos/Monkey_man.mp4"
// import title from "./title.svg";

// const BackgroundVids = monkeyManTrailer;
const bgVid = DriveTrailer;

export interface HeroSectionProps {
    videoBackground: string;
}

const HeroSection = ({videoBackground} : HeroSectionProps) => {
    return (
        <>
            <DesktopHeroSection videoBackground={videoBackground}/>
            <MobileHeroSection videoBackground={videoBackground}/>
        </>
        
        
    );
}

export default HeroSection;
