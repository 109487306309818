import Footer from '../Components/Footer/Footer';
import {motion} from 'framer-motion';
import Navbar from '../Components/Navbar/Navbar';
//sponsors

import {
    arbSponsor,
    atSponsor,
    auroraSponsor,
    bfcSponsor,
    crownSponsor,
    fdSponsor,
    hwSponsor,
    imSponsor,
    itSponsor,
    pfSponsor,
    productionsSponsor,
    raSponsor,
    rsSponsor,
    suppsSponsor,
    taSponsor,
    tazwinSponsor,
    unseenSponsor,
    visualsSponsor
} from '../Data/export_images';

const sponsors = [
    { src: arbSponsor, text: "Angel Insurance brokers" },
    { src: atSponsor, text: "Airways Travel" },
    { src: auroraSponsor, text: "AURORA Receptions" },
    { src: bfcSponsor, text: "Bhullar Finance Co." },
    { src: crownSponsor, text: "Crown" },
    { src: fdSponsor, text: "Freshy designs" },
    { src: hwSponsor, text: "Hamper Warehouse" },
    { src: imSponsor, text: "Icon Media" },
    { src: itSponsor, text: "Indian Traders Association" },
    { src: pfSponsor, text: "Pixion Film" },
    { src: productionsSponsor, text: "Productions" },
    { src: raSponsor, text: "Reliable Accountants" },
    { src: rsSponsor, text: "RS Finance" },
    { src: suppsSponsor, text: "Supps 24-7" },
    { src: taSponsor, text: "The Tea Accent" },
    { src: tazwinSponsor, text: "Tazwin" },
    { src: unseenSponsor, text: "Unseen" },
    { src: visualsSponsor, text: "Visuals" }
];

function OurSponsors() {
    return(
        <div className='bg-black'>
            <Navbar />
            <motion.main
                initial={{ opacity: 0}}
                animate={{ opacity: 1}}
                transition={{ duration: 1.5, delay: 1 }}
            >
                <div className="flex items-center justify-center flex-col">
                    <h1 className='text-dark-gold text-lg sm:text-xl md:text-2xl lg:text-3xl pt-5 pb-6'>Our Sponsors</h1>
                    <div className="p-4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-1">
                        {sponsors.map((sponsor, index) => (
                            <div key={index} className="flex flex-col items-center m-4 w-64 text-dark-gold">
                                <img src={sponsor.src} alt={sponsor.text} className="max-w-[300px] max-h-[170px]" />
                            </div>
                        ))}
                    </div>
                </div>
            </motion.main>
            <Footer />
        </div>
    );
}

export default OurSponsors;