// films.js
// import DreamPoster from "../Dreamfinity/Dreamfinity.jpeg";

import {
    BetelgeusePoster,
    BetelgeuseBG,
    CelebrationABlissParadePoster,
    CelebrationABlissParadeBG,
    HEPoster,
    HEBG,
    BlackFridayPoster,
    BlackFridayBG,
    GhostTownCricketPoster,
    GhostTownCricketBG,
    CabriniPoster,
    CabriniBG,
    RobotPoster,
    RobotBG,
    WhereIAmPoster,
    WhereIAmBG,
    ThePodPoster,
    ThePodBG,
    BForNaooPoster,
    BForNaooBG,
    FathersFootstepsBG,
    FatherFootstepsPoster,
    LetMeOutPoster,
    LetMeOutBG,
    MindTheCartPoster,
    MindTheCartBG,
    MyHandsAreShakingPoster,
    MyHandsAreShakingBG,
    RajKapoorsLoniTunesPoster,
    RajKapoorsLoniTunesBG,
    WhyWaitPoster,
    WhyWaitBG,
    ClownPoster,
    ClownBG,
    IdolPoster,
    IdolBG,
    GoodMorningPoster,
    GoodMorningBG,
    ParolePoster,
    ParoleBG,
    TheHeroOfAdelaidePoster,
    TheHeroOfAdelaideBG,
    TheGarmentologistPoster,
    TheGarmentologistBG,
    LilithPoster,
    LilithBG,
    OPIAPoster,
    OPIABG,
    SoleSistaPoster,
    SoleSistaBG,
    PapaAurSmartphonePoster,
    PapaAurSmartphoneBG,
    WDSRAPoster,
    WDSRABG,
    VanavaaPoster,
    VanavaaBG,
    ThePiccadillyPoster,
    ThePicadillyBG,
    ArchiesPoster,
    ArchiesBG,
    KingdomOfThePlanetOfTheApesPoster,
    KingdomOfThePlanetOfTheApesBG,
    Gadar2Poster,
    Gadar2BG,
    ForceOfNaturePoster,
    ForceOfNatureBG,
    MonkeymanPoster,
    MonkeymanBG,
    AlBangeriPoster,
    AlBangeriBG,
    TheWanderingIslandPoster,
    TheWanderingIslandBG,
    SexForSurvivalPoster,
    SexForSurvivalBG,
    SexForSurvivalDirector,
    UpgradedPoster, 
    UpgradedBG,
    UnsungHeroPoster,
    UnsungHeroBG,
    DaalRotiPoster,
    DaalRotiBG,
    DMPoster,
    HR2Poster,
    HR2BG,
    IRaHPoster,
    IRaHBG,
    MeantimePoster,
    MeantimeBG,
    PJPoster,
    PJBG,
    TaarikhPoster,
    TaarikhBG,
    TheNotePoster,
    TheNoteBG,
    UndercoverPoster,
    UndercoverBG,
    WhitePanjabPoster,
    WhitePanjabBG,
    AbahAndHisBandPoster,
    AbahAndHisBandBG,
    DarwinPoster,
    DarwinBG,
    InHalfPoster,
    InHalfBG,
    QBJNPoster,
    QBJNBG,
    TweenbotPoster,
    TweenbotBG,
    BackToRealityPoster,
    BackToRealityBG,
    CABTKTPoster,
    FreelandPoster,
    DreamFinityPoster,
    DreamfinityBG,
    ChhavaPoster,
    ChhavaBG,
    ColebrookPoster,
    ColebrookBG,
    DreamweaversPoster,
    DreamweaversBG,
    FlintFatiguePoster,
    FlintFatigueBG,
    GenerationsToComePoster,
    GenerationsToComeBG,
    InTheTrenchesPoster,
    InTheTrenchesBG,
    InvisiblesMéxicoPoster,
    InvisiblesMéxicoBG,
    JoyOfArtPoster,
    JoyOfArtBG,
    LaGrandeSignoraPoster,
    LaGrandeSignoraBG,
    NoorPoster,
    NoorBG,
    ObsessedPoster,
    ObsessedBG,
    ParadisePoster,
    ParadiseBG,
    RadaTheFilmPoster,
    RadaTheFilmBG,
    RBAMGPoster,
    RBAMGBG,
    RamnamiPoster,
    RamnamiBG,
    ReclaimingOurHumanityPoster,
    ReclaimingOurHumanityBG,
    ShahroorPoster,
    ShahroorBG,
    TheDarksideOfSocietyPoster,
    TheDarksideOfSocietyBG,
    TheGoldenChainOfMercyPoster,
    TheGoldenChainOfMercyBG,
    TiVulissiMangiariPoster,
    TiVulissiMangiariBG,
    VietnamStoryPoster,
    VietnamStoryBG,
    AnatomyOfTheMindPoster,
    AnatomyOfTheMindBG,
    AntarnaadTheVoiceWithinPoster,
    AntarnaadTheVoiceWithinBG,
    ARTEMPoster,
    ARTEMBG,
    BAAZPoster,
    BAAZBG,
    BlackholePoster,
    BlackholeBG,
    BluOlandaPoster,
    BluOlandaBG,
    CircoloPoster,
    CircoloBG,
    DepressionPoster,
    DepressionBG,
    DigPoster,
    DigBG,
    EchoPoster,
    EchoBG,
    FEverythingButLifePoster,
    FEverythingButLifeBG,
    FriendlyFirePoster,
    FriendlyFireBG,
    KindnessCrossingPoster,
    KindnessCrossingBG,
    KissForMePoster,
    KissForMeBG,
    LasyaSongPoster,
    LasyaSongBG,
    LatitudesPoster,
    LatitudesBG,
    LeftyPoster,
    LeftyBG,
    ListenToTheSilencePoster,
    ListenToTheSilenceBG,
    MarysBookPoster,
    MarysBookBG,
    MirrorPoster,
    MirrorBG,
    PillowTalkPoster,
    PillowTalkBG,
    PromotionPoster,
    PromotionBG,
    ReelTerrorPoster,
    ReelTerrorBG,
    SaintAndGheevarghesePoster,
    SaintAndGheevargheseBG,
    SardarjiAndAlcoholPoster,
    SardarjiAndAlcoholBG,
    ScarsPoster,
    ScarsBG,
    TheCommunionPoster,
    TheCommunionBG,
    TheCutPoster,
    TheCutBG,
    TheGraffitiSocietyPoster,
    TheGraffitiSocietyBG,
    TheMurderPoster,
    TheMurderBG,
    TheNightsTakingsPoster,
    TheNightsTakingsBG,
    TheRiseOfTheWogoloPoster,
    TheRiseOfTheWogoloBG,
    TheThongPoster,
    TheThongBG,
    ZAAGPoster,
    ZAAGBG,
    ERANPoster,
    ERANBG,
    FoundationPoster,
    FoundationBG,
    WhoAreWePoster,
    WhoAreWeBG,
    CABTKTBG,
    FreelandBG,
    MountainBoyPoster,
    MountainBoyBG,
    GingersnapChristmasPoster,
    GingersnapChristmasBG,
    BornAgainPoster,
    BornAgainBG,
    InactiveAmericaSilentKillerPoster,
    InactiveAmericaSilentKillerBG,
    MelodyPoster,
    MelodyBG,
    TonyboyPoster,
    TonyboyBG,
    DecodedPoster,
    LoveLiesBleedingPoster,
    LoveLiesBleedingBG,
    DecodedBG,
    TreasureBG,
    TreasurePoster,
    PolemosBG,
    PolemosPoster,
    JustAFarmerBG,
    JustAFarmerPoster,
    DriveBG,
    DrivePoster,
    ArcadianPoster,
    ArcadianBG,
  } from "./export_images";

export interface Director {
    name: string;
    image?: string;
}

export interface Film {
    filmId?: number | null;
    name: string;
    poster: any; // Replace 'any' with the actual type of the poster
    synopsis: string;
    // link: string;
    background: any; // Replace 'any' with the actual type of the background
    directors: Director | Director[];
    year: string;
    country: string;
    duration: string;
}



export const cinemaMovies : Film[] = [
  {
    name: "Arcadian",
    poster: ArcadianPoster,
    synopsis: `In a near future, life on Earth has been decimated. Paul and his twin teenage sons, Thomas and Joseph, have been living a half-life tranquility by day and torment by night. When the sun sets, ferocious creatures of the night awaken and consume all living souls in their path. One day, when Thomas doesn't return home before sundown, Paul chooses to leave the safety of their fortified farm to find him before the creatures arrive. Just as he finds his boy, a nightmarish battle ensues and Paul is gravely wounded. Now the twins must devise a desperate plan for surviving the coming night and use everything their father has taught them to keep him alive.`,
    background: ArcadianBG,
    directors: { name: "Benjamin Brewer" },
    year: "2024",
    country: "United States",
    duration: "92 mins"    
  },
  {
    name: "Drive",
    poster: DrivePoster,
    synopsis: `To escape from this drive, I must start the live show.
    Yuna is a famous YouTube influencer but always looks down on others. She is invited to a cosmetic brand launching party but things don’t go the way she had wanted. Tired and extremely stressed, she takes couple of shots and falls asleep after calling a designated driver. When Yuna opens her eyes, she realizes she’s trapped in the trunk of her car. The kidnapper asks her for ransom, not just cash but to broadcast a ‘kidnapping live-stream’ and beg for money from the viewers. But everyone thinks she’s playing out a self-fabricated scenario…
    `,
    background: DriveBG,
    directors: { name: "Park Dong-hee" },
    year: "2024",
    country: "South Korea",
    duration: "90 mins"    
  },
  {
    name: "Just A Farmer",
    poster: JustAFarmerPoster,
    synopsis: `Just a Farmer is a powerful independent film that sheds light on the often-overlooked mental health challenges faced by farmers and rural communities. Through the story of a family navigating life after tragedy, the film highlights the importance of empathy, support, and connection in overcoming adversity. By portraying the authentic experiences of those in the agricultural sector, Just a Farmer encourages viewers to engage in meaningful conversations about mental health and fosters a greater understanding of the vital role farmers play in our society. Ultimately, it serves as a call to action for communities to come together and support one another.`,
    background: JustAFarmerBG,
    directors: { name: "Sinon Lyndon" },
    year: "2024",
    country: "Australia",
    duration: "142 mins"
  },
  {
    name: "Treasure",
    poster: TreasurePoster,
    synopsis: `1990 - Ruth, a neurotic businesswoman from New York, takes her father Edek, a charmingly stubborn Holocaust survivor on a journey to Poland to make sense of her family's past. Edek's hesitation to confront his past and his often odd demeanor cause more than just one dispute between him and his daughter. The trip unfolds into a story that encapsulates the emotion of discovery and drama with humor. Based on the best-selling autobiographical novel TOO MANY MEN by New York-based writer Lily Brett.`,
    background: TreasureBG,
    directors: { name: "Julia von Heinz" },
    year: "2024",
    country: "Poland",
    duration: "111 mins"
  },
  {
    name: "Love Lies Bleeding",
    poster: LoveLiesBleedingPoster,
    synopsis: `Lou (Kristen Stewart) works at a hard-core gym, unclogging toilets and being pressured by Daisy (Anna Baryshnikov) for a date.

    Jackie (Katy O'Brian) arrives in town and has sex with JJ (Dave Franco) in order to secure a job at a gun range. She has nowhere to stay, so she sleeps and works out underneath a nearby bridge.
    
    Lou visits her sister, Beth (Jena Malone), whose house is filled with children and suffers clear signs of domestic abuse from her husband, JJ. He leaves for work where he shows Jackie around and introduces her to the owner (Ed Harris).
    
    Jackie comes to work out at the gym, and Lou is immediately smitten by her. Her fantasies are interrupted by two FBI agents asking about her father, who she says she hasn't talked to in years. Lou and Jackie chat outside, but are interrupted by two patrons asking Jackie out on a date. She refuses, then punches one in the face after he attempts to grab her. He hits her back and Lou rushes her inside. They talk about Jackie's dream to go to Vegas to compete in the finals for a bodybuilding competition, so Lou offers her a stash of steroids from her office. While Jackie is initially reluctant, she agrees and Lou injects her with a vial. The two are clearly attracted to each other and begin making out, then leave for Lou's apartment to have sex.
    
    The following morning, Lou laments that Jackie's working at the gun range, as her estranged dad, Lou Sr., owns it. Lou agrees to let Jackie stay with her, leading to a period of love, sex, and steroid injections. Back at the gun range, Lou Sr. runs a criminal operation smuggling guns to Mexico and teaches Jackie how to shoot a gun.
    
    Lou and Jackie go on an awkward double date with JJ and Beth, who has another set of bruises and cuts. Lou follows JJ to the bathroom to threaten him, but he reveals that he slept with Jackie and suggests that she's probably just using Lou for the gym and housing. They fight on the way home, but speed away together after Lou spots the FBI agents.
    
    The following day, Lou gets a call that Beth is in the hospital with severe face and head injuries. Lou is furious, but the police will not arrest JJ unless Beth agrees to press charges, which she always refuses to do. Upon seeing Lou sobbing, Jackie's muscles begin to swell and she flies into a rage and drives to Beth's house to violently murder JJ. Lou spots her truck in front of Beth's house and enters to find JJ's body and a catatonic Jackie. After being initially horrified, Lou sets to work cleaning up the mess and puts JJ in the back of his own car. On the way to dispose of the body, Daisy spots Lou and knocks on the window of JJ's car to chat. The girls push the car into a large gorge, then set it on fire, sending smoke billowing into the sky. Lou assures Jackie that there are other things in the gorge that will point the police to someone else, so all they need to do is stay put in the apt. While Lou cleans up Beth's house, Jackie takes many vials of steroids and breaks out of the house to go work out, which infuriates Lou. Still hyped up on steroids, Jackie headbutts Lou and hitchhikes to Vegas for the bodybuilding competition. While her individual performance originally goes well, she has a panic attack and vomits on the stage. When she sees other competitors laughing at her, she viciously attacks them and is arrested.
    
    Lou Sr. confronts Lou at the hospital about leading police to the gorge, as it has attracted attention to the bodies that he has dumped down there. When Lou arrives home, she runs into Daisy who begins asking questions about why she was driving JJ's car that night. In order to silence her, Lou has sex with Daisy, who then answers the phone when Jackie calls from jail and tells her not to bother them. Instead, Lou Sr. bails Jackie out of prison and claims he can help her get out of this mess. Lou has continued seeing Daisy to find out what she knows, but when they return to Lou's house, Jackie shoots Daisy in the face, then runs. Lou hides the body behind the couch when FBI agents arrive to continue asking questions about JJ, who was one of their biggest informants on Lou Sr. They reveal they know that Lou used to be Lou Sr.'s right-hand man, so she'd have enough information to put him away. Once they leave, Lou Sr. calls to tell Lou that he has Jackie and plans to pin the double homicide on her. Lou threatens to reveal everything to the FBI if he does that, so he sends over a corrupt policeman to kill her. She knocks him out, then goes to rescue Jackie, but runs into Beth who attacks her for killing JJ. Jackie is tied up in the garage, and after initially running from Lou, they embrace and make up. Lou sends Jackie off towards the car, while she goes to confront her dad, but he arrives and shoots Lou in the leg and prepares to kill her. Jackie, empowered by her love for Lou, appears as a 60ft. woman and pins Lou Sr. to the ground. Lou puts a gun in his mouth, but decides to leave him for the incoming police. The two girls drive into the desert, but Lou hears thumping from the bed of the truck and discovers that Daisy is still alive. As Jackie sleeps, Lou strangles Daisy and drags her body into the desert.`,
    background: LoveLiesBleedingBG,
    directors: { name: "Rose Glass" },
    year: "2024",
    country: "United States",
    duration: "104 mins"
  },
  {
    name: "Decoded",
    poster: DecodedPoster,
    synopsis: `Life is an ongoing journey of unraveling mysteries. The story commences with an interview, as the interviewees piece together the legendary life of Rong Jinzhen through recollections. Turning back the clock to the early 20th century, during the chaos of warlords and political unrest, the twelve-year-old Rong Jinzhen displayed exceptional mathematical talent. At the age of sixteen, he was admitted to N University, where his mentor, Professor Hayes, recognized and cherished his abilities. Their relationship evolved into a deep friendship. Upon discovering Rong Jinzhen's extraordinary talent in code-breaking, Hayes cautioned him against becoming overly fixated on "decode." With the outbreak of war, the two sympathetic individuals had to choose vastly different paths. Hayes returned to his homeland, responsible for "creating" codes, while Rong Jinzhen joined the 701 Unit, specializing in "deciphering" codes. 
    At that time, the looming concern for everyone at 701 was the enemy's "Purple Code." Some even went mad due to the inability to find a solution. In less than a year, Rong Jinzhen successfully cracked the "Purple Code." However, faced with ensuing love, he chose to delve deeper into the path of "decode." Through correspondence with Hayes, Rong Jinzhen gradually realized that his former mentor had become his adversary. The destinies of the two ultimately intertwined, and the final showdown between master and disciple would unfold around a code more advanced, sophisticated, and weighty than the "Purple Code" – the elusive "Black Code."
    `,
    background: DecodedBG,
    directors: { name: "Sicheng Chen" },
    year: "2024",
    country: "China",
    duration: "156 mins"
  },
  {
    name: "The Archíes",
    poster: ArchiesPoster,
    background: ArchiesBG,
    synopsis: `Mr. Hiram Lodge (Alyy Khan) returned to Riverdale, and he planned to change the entire town by building new plazas and hotels. Mr. Lodge was a true-blue capitalist, and all he cared about was benefiting from the deal, even if it meant doing something that the residents didn't want. Mr. Lodge had a secret meeting with Mr. Dawson (Vinay Pathak), a member of the local council, and he told him the plans he had for Riverdale. Mr. Lodge's wife Hermione (Kamal Sidhu) proposed that the hotel that they were planning to build shouldn't be situated on the outskirts of the town. She wanted it to be located in the heart of the city, and she proposed that it should be built where the historic Green Park was located. Mr. Dawson knew that it was going to be an uphill battle for him, as destroying Green Park was not something that people would like. Since the time John Riverdale leased the land from King Sarna in 1914, children planted trees in Green Park every year. Riverdale was an Anglo-Indian community and most people stayed even after India gained its Independence. Green Park was deeply rooted in the town's tradition and history, but still, Dawson told Mr. Lodge that he would try his best to get the motion passed as the latter decided to fund his campaign for the election of the District Contractor.

    Dawson needed only 5 votes to pass the motion and give the tender to Mr. Lodge's company to build the hotel. He knew that, by hook or by crook, he would have to lure in the council members to do as they wanted. The residents were totally unaware of this plan to destroy a place that was no less than a local heritage. But before the construction of the hotel, Hiram had planned to make a plaza where Pam's salon, Suzie's flower shop, and Hal's bookshop were located. He contacted the owners of the place, who in turn gave notice to their tenants to close their shops or match the amount of rent that Mr. Lodge was going to pay. Veronica is Hiram's daughter and is best friends with Betty. Veronica gives a party at her house, inviting Archie, Jughead, Dilton, Reginald "Reggie" Mantle (Vedang Raina), Moose (Rudra Mahuvarkar), Midge (Santana Roach) and others. Archie doesn't like that Reggie treats Dilton like his man-servant. Reggie has a crush on Veronica, but she only has eyes for Archie. Ricky (Luke Kenny) is Reggie's father and is the editor of the local Gazette.

    Betty's (Khushi Kapoor) father, Hal Cooper (Satyajit Sharma), realized that he would have to shut down his bookshop as he didn't have the money to pay his landlord. Betty blamed her best friend Veronica (Suhana Khan) for it, though the latter had no clue that her father was planning to do something like that. Betty claims that Veronica's father does not care for the people of the town and says the same things for Veronica as well. Veronica went to her mother, and she made sure that Hal got a job in the new bookstore in town.

    This was the sort of relationship Veronica Lodge and Betty Cooper shared. Their common love interest was Archie Andrews (Agastya Nanda), but the feelings they had for him never created a rift between them. After Veronica came back to Riverdale, Archie was confused, and he did something that he shouldn't have done. He double-dated Betty and Veronica for quite some time, though Jughead Jones (Mihir Ahuja) time and again told him that he shouldn't be doing that. Ethel (Aditi "Dot" Saigal) has a crush on Jughead who works at Pop Tate's as a waiter. Ethel warns Betty that Archie is two timing her, but she refuses to have any doubts on Archie and believes that he will change. Archie, Betty, and Veronica were on a mini vacation with their friends when Betty saw Archie and Veronica getting intimate with each other. This was also the vacation where Reggie admits that he knows that Dilton is in love with him.

    Just a few days ago, Betty had kissed Archie, and she believed that he, too, had feelings for her, but now, after seeing what he did, she didn't know whether he was just confused or toxic enough to cheat on both of them. Betty's problem was that no matter how angry she was with Archie, the moment he came and apologized to her, she couldn't help but leave behind all her anger and agree to whatever he said. Betty was smitten by him, but one day, she decided to tell Veronica the truth, as it was eating her up from within. The moment Veronica got to know that Archie had kissed Betty, she was furious, as she hadn't imagined that Archie could do something like that. The girls caught Jughead and forced him to tell them the entire truth. Jughead spilled the beans and told them how Archie dedicated a song to both of them and how he said that he loved them equally. Both the girls went to confront Archie and told him that he should immediately stop doing things that created a rift between them. They both asked him to just be their friend, as they valued their relationship way too much.

    From the very beginning, Archie wanted to go to London to study, and he knew that he wouldn't have any problems as his uncle Ben (Farhan Akhtar) was living here. Archie's parents, Fred (Suhaas Ahuja) and Mary (Tara Sharma), didn't like the fact that their son didn't want to stay back at Riverdale, but when they saw the kind of obsession he had for going abroad to pursue his studies, they, too, decided to let him do what he wanted. But then, very soon, the news that Mr. Hiram Lodge was going to destroy Green Park became known to the general public, and Archie had a change of heart. He felt as if he were abandoning his friends for his own selfish interests. Archie always believed that the grass was greener on the other side, but for the first time, he realized that it was greener at the place where it was watered. He felt that he owed a lot to Riverdale, and he couldn't leave the town to its fate when the time came. So, Archie took the hard decision to not go abroad and decided to stop the unsympathetic capitalists from destroying the park.

    Though the council had 5 votes in favor of building the hotel in the park, there was another hidden clause that could stop them, which Archie and his friends hadn't known about for a very long time. One of the council members who wasn't happy with the state of things told the kids that if they got some 4500-odd signatures from the residents opposing the construction of the hotel, then Mr. Lodge would have no option but to stop. Reggie writes an article against the council proposal, but Ricky refuses to print it since Hiram Lodge is the biggest advertiser in the Gazette newspaper and without that money, the paper would not survive. Reggie and Archie suspect Veronica of being the insider in their group who is revealing their strategy to Hiram Lodge. Veronica confronts her father, who says that his money is what pays for her lavish lifestyle. But Veronica says that her friends hate her because of his actions. Eventually the group figures out that it was Ricky's PA who was the insider and not Veronica.

    Mr. Dawson tried his best to prevent the teenagers from getting the signatures, but Archie and his friends were relentless. They went door to door, organized a fair on the park, used Dilton's (Yuvraj Menda) radio to spread the information, and did whatever they could to awaken the conscience of the people.

    The citizens of Riverdale voted against the construction, and Mr. Lodge had to inevitably stop. His own daughter was a part of the protest, and though he knew that he wouldn't be able to make as much profit as he had dreamed of, he was happy that he lost to his own daughter and that she had the determination to stand for what she believed in. It all ended well for Archie and his gang, and their conflict against the establishment made them learn some major life lessons. Not only did they save the environment and the natural habitat, which served as a home for so many species, but they also realized that not everything could be bought with money.`,
    
    directors: { name: "Zoya Akhtar" },
    year: "2023",
    country: "India",
    duration: "141 mins"
  },
  {
    name: "Kingdom of the Planet of the Apes",
    poster: KingdomOfThePlanetOfTheApesPoster,
    background: KingdomOfThePlanetOfTheApesBG,
    synopsis: `Many generations after Caesar's death, apes have established numerous clans, while most humans have become feral-esque. Noa, a young chimpanzee from a falconry-practicing clan, prepares for a coming-of-age ceremony by collecting eagle eggs with his friends Anaya and Soona. However, a human scavenger follows Noa home and inadvertently cracks his egg during a scuffle before fleeing. While searching for a replacement egg, Noa encounters a group of ape raiders using electric weapons. As Noa hides from them, the apes follow his horse back to his clan. Noa hurries home to find his village burning; the raider leader Sylva kills Noa's father Koro after dropping Noa from a high platform.

Left for dead, Noa awakens, discovering that his clan has been abducted. He buries Koro and sets out to rescue his clan. On his journey, he is joined by Raka, an orangutan who tells Noa about Caesar's teachings. The apes notice they are being followed by the human scavenger; Raka offers her food and a blanket, naming her Nova. When the trio encounters a group of feral humans, Sylva's raiders suddenly attack. Noa and Raka rescue Nova who, to their surprise, can speak. She reveals that her name is Mae and that the raiders took Noa's clan to a beachfront settlement outside an old human vault. As they cross a bridge on their way to the settlement, they are ambushed by Sylva. In the ensuing fight, Raka saves Mae from drowning but is swept away by the rapids. Noa and Mae are captured and taken to the apes' settlement.

Noa reunites with his clan and is introduced to the apes' self-proclaimed king, Proximus Caesar. Proximus has enslaved other clans, forcing them to work on opening the vault so he can access the human technology locked inside. Proximus invites Noa to dinner along with Mae and Trevathan, a human prisoner who is teaching Proximus about the old human world. Proximus believes that Noa's intelligence could help open the vault and warns him that Mae only has her own agenda. Noa confronts Mae, demanding the truth in exchange for his help. Mae discloses knowledge of a hidden entrance to the vault and says that a mysterious "book" capable of restoring speech to humanity is inside. Noa agrees to help her enter the vault, hoping to destroy Proximus' settlement and lead his clan back home. Noa, Mae, Soona, and Anaya secretly plant explosives around the levee that surrounds the settlement. Trevathan catches them and intends to warn Proximus, but Mae strangles him to death.

The group enters the vault, finding a stockpile of weapons and Mae's "book", which is actually a satellite deciphering key. The apes discover children's picture books depicting humans as the once-dominant species and apes in cages at a zoo. As the group makes their way out of the bunker, they are confronted by Proximus and his tribe. Lightning, one of Proximus' lieutenants, threatens to kill Soona, but Mae kills him with a gun she found before triggering the explosives, flooding the bunker with the apes inside. Mae flees the settlement while the apes climb to higher ground through the bunker. Noa is pursued by Sylva, but manages to trap and drown him. Noa escapes the bunker with his clan, but Proximus attacks him. Noa leads his clan in summoning their eagles to attack Proximus and send him falling off a cliff into the sea.

As Noa's clan returns to rebuild their home, Mae arrives to tensely bid farewell to Noa. She explains that she destroyed the bunker and prevented the apes from accessing its weapons because they were created by humans and thus should only be used by humans. Noa then argues that if weapons and technology are only for humans, it suggests that nothing belongs to the apes, questioning whether apes and humans can co-exist peacefully given Mae's fundamental disparity. As Noa takes Soona to look through a telescope he found on his journey, Mae travels to a human settlement at a satellite base, which is kept quarantined from the outside world. Mae delivers the decipher key, allowing the humans to reactivate the satellites and successfully contact other humans worldwide.`,
    
    directors: { name: "Wes Ball" },
    year: "2024",
    country: "Australia",
    duration: "145 mins"
  },
  {
    name: "Force of Nature: The Dry 2",
    poster: ForceOfNaturePoster,
    background: ForceOfNatureBG,
    synopsis: "Five women participate in a hiking retreat but only four come out the other side. Federal agents Aaron Falk and Carmen Cooper head into the mountains hoping to find their informant still alive.",
    
    directors: { name: "Robert Connolly" },
    year: "2023",
    country: "Australia",
    duration: "120 mins"
  },
  {
    name: "Gadar 2",
    poster: Gadar2Poster,
    background: Gadar2BG,
    synopsis: `1954 Tara Singh elopes to India with Sakeena and Jeete following which Ashraf Ali is termed traitor and hanged to death by Major General Hamid Iqbal a victim of partition who hates Indians.Seventeen years later in 1971 Hamid still lives with a hope that someday he will revenge on Tara Singh who put his army to shame years back.Tara Singh now lives a happy life in India with Sakeena and Jeete and runs a transport company,tensions are erupting between the two countries on border and there are predictions of war.Pakistani army unintentionally attacks Ram Tekdi where Indian army falls short of arms and Tara Singh's company is given the job to provide them arms. During the cross firing Hamid notices Tara Singh and is ready for his revenge he explodes a weapon on the Indian border where Tara Singh and few Indian soldiers are held captive, seeing his mother Sakeena going in depression Jeete decides to travel to Pakistan to get back his father.Jeete meets Gullu old friend of his father and Sakeena's uncle Abdul in Pakistan who help him get a job in hotel of Qurban Khan who provides food to jail during Eid.Jeete wins their heart and his daughter Muskaan falls in love with him on the day of Eid he enters the prison but finds that his father was never captured by them.Tara Singh returns home after surviving the attack and finds about Jeete going to Pakistan and decides to get him back.Jeete is turned as traitor in the country and Hamid forces Muskaan to testify against him. Tara Singh arrives to save him creating a similar situation he faced years back with the Pakistani army.`,
    
    directors: { name: "Anil Sharma" },
    year: "2023",
    country: "India",
    duration: "170 mins"
  },
  {
    name: "Monkey Man",
    poster: MonkeymanPoster,
    background: MonkeymanBG,
    synopsis: `In a forest village in India, Kid lives with his mother Neela and is inspired by tales of Hanuman she teaches him. Baba Shakti, a ruthless spiritual guru in the nearby city of Yatana, sends Rana Singh, the corrupt police chief, to force out the villagers and acquire their land. The village is massacred, but Neela is able to hide Kid before Rana kills her and sets her body on fire. Kid's futile attempts to save her leave his hands terribly burned and scarred.

Years later, Kid earns a living in Yatana as a monkey-masked fighter at Tiger's Temple, an underground boxing club, where he is incentivised to lose. Kid intends to exact revenge on Baba, now an influential political figure, and Rana, who frequents Kings, a luxury brothel run by Queenie Kapoor. Kid obtains a job in the kitchen, where he uses the alias "Bobby" from a cleaning product and befriends Alphonso, a gangster working for Queenie. Kid helps Alphonso win a large bet on a bout at Tiger's Temple in exchange for promotion to a waiter to access the VIP floor.

Kid buys a gun and trains a stray dog to carry it past security. Kid serves Rana cocaine spiked with powdered bleach in order to confront and shoot him in the bathroom. The assassination attempt is spoiled and Kid fights his way out of the building. Fleeing in Alphonso's supercharged tuk-tuk, Kid crashes and gets arrested, but he escapes. The chase ends when Kid is shot by police, falling into a river. Kid is rescued by Alpha, the keeper of Ardhanarishvara, a local temple whose hijra community is being targeted by Baba's growing political movement.

Learning that he is now a wanted fugitive, Kid recovers at the temple. Alpha guides him through a hallucinogenic experience in which he tears open his chest and is able to confront the trauma of his mother's death. Now with a newfound purpose, Kid trains in combat with the hijra and begins to fight for the marginalized as well. When the hijra's sanctuary is threatened, Kid returns to fight at Tiger's Temple, placing a large bet on himself. He emerges victorious with enough money to save Alpha's temple. During Diwali, Baba's candidate is elected and their nationalist party celebrates at Kings. Kid bleaches his monkey mask white and fights his way inside with improvised weapons, joined by Alpha and her warriors.

Queenie attempts to shoot Kid, but is killed by Sita, one of the exploited prostitutes and Kid's co-worker. Using Queenie's severed thumb to access the penthouse, Kid confronts Rana and beats him to death in a fistfight. He reaches Baba, who wounds him with blades hidden in his padukas. Kid kills Baba using the same blades against him. Having finally avenged his mother's death, Kid collapses from his injuries, reminiscing about Neela and his devotion to Hanuman.`,
    
    directors: { name: "Dev Patel" },
    year: "2024",
    country: "Australia",
    duration: "121 mins"
  },
  {
    name: "Upgraded",
    poster: UpgradedPoster,
    background: UpgradedBG,
    synopsis: `An aspiring art intern is invited on a last-minute work trip to London, where she meets a handsome stranger.`,
    
    directors: { name: "Carlson Young" },
    year: "2024",
    country: "United States",
    duration: "104 mins"
  },
  {
    name: "Unsung Hero",
    poster: UnsungHeroPoster,
    background: UnsungHeroBG,
    synopsis: `When David Smallbone's music company collapses, he moves his family from Australia to the United States to search for a brighter future. Struggling to make ends meet, the couple soon realize the talents of their children, who go on to become two of the most successful acts in contemporary Christian music.`,
    
    directors: { name: "Richard Ramsey" },
    year: "2024",
    country: "United States",
    duration: "113 mins"
  },
  {
    name: "Cabrini",
    poster: CabriniPoster,
    background: CabriniBG,
    synopsis: `Arriving in New York City in 1889, Italian immigrant Francesca Cabrini is greeted by disease, crime and impoverished children. She soon sets off on a daring mission to convince the mayor to secure housing and health care for society's most vulnerable. With broken English and poor health, Cabrini uses her entrepreneurial mind to build an empire of hope unlike anything the world has ever seen.`,
    
    directors: { name: "Alejandro Monteverde" },
    year: "2024",
    country: "United States",
    duration: "142 mins"
  }
];

export const featureFilms: Film[] = [
  // This one is listed in the 1st place of cinema movies.
  // {
  //   name: "Just A Farmer",
  //   poster: JustAFarmerPoster,
  //   synopsis: `Just a Farmer is a powerful independent film that sheds light on the often-overlooked mental health challenges faced by farmers and rural communities. Through the story of a family navigating life after tragedy, the film highlights the importance of empathy, support, and connection in overcoming adversity. By portraying the authentic experiences of those in the agricultural sector, Just a Farmer encourages viewers to engage in meaningful conversations about mental health and fosters a greater understanding of the vital role farmers play in our society. Ultimately, it serves as a call to action for communities to come together and support one another.`,
  //   
  //   background: JustAFarmerBG,
  //   directors: { name: "Sinon Lyndon" },
  //   year: "2024",
  //   country: "Australia",
  //   duration: "142 mins"
  // },
  {
    name: "Daal Roti",
    poster: DaalRotiPoster,
    synopsis: `This is a story of youths who migrate to urban landscape for better earnings and future , abandoning their traditional farming in the village as they thinks farming is a business of loss and poverty. The four youths in the story struggle in the city to earn money but due to circumstances they return to their village and after getting motivation they realise that even farming can give them bread and butter with respect as now a days so many programs of Government of India help farmers to innovate and progress in farming . And the time proves that even farming can give them better opportunity to flourish as they get success and get honour from the Government of India and become inspiration for all youth.`,
    
    background: DaalRotiBG,
    directors: { name: "Julee  Jasmin" },
    year: "2024",
    country: "India",
    duration: "92:14 mins"
  },
  {
    name: "Dream.",
    poster: DMPoster,
    synopsis: `"Dream series! Part 1.

    This is my Story.
    This is a set of films about...............
    This is a story about................
    One word! And all is given away.
    One preview! And it spoils the journey.
    What will you see? Will you listen.
    Will you watch in order? Or will you skip to see.
    Will it be a mistake? listen to me.
    Or............................Just watch! And see.
    What you don't need eyes! To see.
    Say yes! to me.`,
    
    background: DMPoster,
    directors: { name: "Shane Daniel Van Erp" },
    year: "2024",
    country: "Australia",
    duration: "85:34 mins"
  },
  {
    name: "Hercules Recycled 2.0",
    poster: HR2Poster,
    synopsis: `“IF Da VINCI MADE A FILM, THIS WOULDN’T BE IT.”
    Combining the cheesiest parts of 9 epic “Sword and Sandal” movies
    (including “Hercules”, “Hercules Unchained, and “Last Days of
    Pompeii”), Hercules Recycled 2.0 is an insane, redubbed hybrid
    comedy/adventure set in a future where the power’s run out and the
    world has collapsed into primal chaos.
    Throw in two geeky teenagers in togas, a multi-eyed monster, a space
    puppet, the kitchen sink (literally), and over 150 CGI effects and you’ve
    got a movie that took over 40 years to make after only 30 seconds of
    rational thought.`,
    
    background: HR2BG,
    directors: { name: "Patrick Nielson" },
    year: "2024",
    country: "United States",
    duration: "79 mins"
  },
  {
    name: "IRaH",
    poster: IRaHPoster,
    synopsis: `Everybody wants a piece of Hari Singh and his revolutionary new app: the IRAH 5.10. Sadly, this extends to kidnappers, Police, Cyber Security Agencies and international hackers.`,
    
    background: IRaHBG,
    directors: { name: "Sam Bhattacharjee" },
    year: "2024",
    country: "United Kingdom",
    duration: "133 mins"
  },
  {
    name: "Lilith",
    poster: LilithPoster,
    synopsis: `Based on real-life cases of 'folie a famille' a contagious psychosis shared by a whole family.
    Lilith is the story of a psychology professor who, fearing for his family’s safety after the
    murder of his daughter Lilith, sequesters his wife and surviving daughter in a high-rise
    apartment only to realise that no one there can be trusted.`,
    
    background: LilithBG,
    directors: { name: "Dale Crawford" },
    year: "2024",
    country: "Australia",
    duration: "93 mins"
  },
  {
    name: "Meantime",
    poster: MeantimePoster,
    synopsis: `Six young friends reunite after five years spent apart in the wake of tragedy.`,
    
    background: MeantimeBG,
    directors: { name: "Alicia Knight" },
    year: "2024",
    country: "Switzerland",
    duration: "55:56 mins"
  },
  {
    name: "PJ",
    poster: PJPoster,
    synopsis: `The Adventures of PJ, a magical Teddy Bear who dreams of being a real boy -  Inspired by The Adventures of Pinnocchio by Carlo Collodi.`,
    
    background: PJBG,
    directors: { name: "Patrick Gamble" },
    year: "2024",
    country: "Australia",
    duration: "108:56 mins"
  },
  {
    name: "Taarikh",
    poster: TaarikhPoster,
    synopsis: `Durlov Dutta, aged 70, shares the company of loneliness in life's twilight, yet his psyche diverges from the norm. In 2008, an irreversible incident shattered his mental equilibrium, leaving him stranded in time as fate snatched his loved ones away. While the world surged forward, he remained stagnant. Enter Anuran Hazarika, a journalist, who vows to liberate Durlov from his anguish. Can Anurag's efforts dissolve the shadows haunting Durlov's existence, restoring color to his life?`,
    
    background: TaarikhBG,
    directors: { name: "Arundhati Sarmah Baruah" },
    year: "2024",
    country: "India",
    duration: "87:21 mins"
  },
  {
    name: "The Note",
    poster: TheNotePoster,
    synopsis: `In a world where lives intersect like delicate threads, five individuals find themselves at their lowest points. Their paths diverge, each facing unique struggles. But ultimately a single note binds them together.`,
    
    background: TheNoteBG,
    directors: { name: "Chris Beadnell" },
    year: "2024",
    country: "Australia",
    duration: "10:10 mins"
  },
  {
    name: "Undercover Guptochor গুপ্তচর",
    poster: UndercoverPoster,
    synopsis: `Story about a Bangladeshi undercover officer and his team's dedication and the sacrifices they make to stop crimes happening inside the country, DHAKA is the capital, one of the most densely cities in the world, where it all BEGINS.

    Dhaka, City of RICKSHAWS. City of TRAFFIC JAMS, City of MOSQUES. More than 20 million people live here. There are some inhuman in human form. Everyday multiple heinous crimes are being committed in and outside the city, in other cities, in any township. To protect peaceful citizens, Bangladesh police and its intelligence agencies are determined to stop this unwanted criminal organisation, Drug trafficking, Human trafficking, Smuggling, Money laundering, Extortion, Kidnapping, Contract killing, Fraud, theft, robbery, corruption and syndicates, black market, political violence and terrorism along with wildlife trafficking, To Prevent any crime, Bangladesh Police and its intelligence agencies are equally brave, relentless, fearless.`,
    
    background: UndercoverBG,
    directors: { name: "Iqbal Hasan" },
    year: "2024",
    country: "Bangladesh",
    duration: "82:09 mins"
  },
  {
    name: "WE DON'T SAY RETARD ANYMORE",
    poster: WDSRAPoster,
    synopsis: `When Y-Man, a disabled Finnish influencer, attracts a loyal female fanbase, his online girlfriend's jealousy grows, demanding a meeting in America. However, his condition limits his ability to travel, which tests his faith.`,
    
    background: WDSRABG,
    directors: { name: "Tez Frost" },
    year: "2024",
    country: "Australia",
    duration: "71 mins"
  },
  {
    name: "WHITE PANJAB",
    poster: WhitePanjabPoster,
    synopsis: `Story is Inspired by untold and unrevealed anecdotes of Punjab’s Criminals which revolves around two rivalry Gangsters Durlabh and Kesar engaged in bitter revenge leading to death of famous personalities in India.Film highlights deep nexus of students Politics and suffering families of the gangsters.`,
    
    background: WhitePanjabBG,
    directors: { name: "Gabbar Sangrur" },
    year: "2024",
    country: "India",
    duration: "119:20 mins"
  },
  {
    name: "Gingersnap Christmas",
    poster: GingersnapChristmasPoster,
    synopsis: `“Gingersnap Christmas” is a female written, directed, and produced diversity-oriented feature film that depicts Caribbean-American family life and culture with additional Asian and British elements. It’s a touching and poignant story that features a talented, diverse cast and a terrific crew drawn from over 20 countries.

    Our film explores, in a sensitive, healing and sometimes humorous way, the toll that the loss of loved ones can take on a family, especially during the holiday season when everyone around them is caught up in holiday joy they have a hard time sharing…
    
    After Covid, when so many people were having trouble coping with the loss of family and friends, we decided to take the lightest genre - a Christmas movie - and use it as a healing opportunity to help ordinary people deal with and recover from losses in their own lives during the “happiest of holidays.”`,
    
    background: GingersnapChristmasBG,
    directors: { name: "Julia Jay Pierrepont III" },
    year: "2024",
    country: "United States",
    duration: "94:50 mins"
  },
];

export const internationalAnimation: Film[] = [
    {
    name: "A Robot's Dream",
    poster: RobotPoster,
    synopsis: "Two robots, equipped with Artificial Intelligence, roam the post apocalyptic world looking for humans to give them their purposes.",
    
    background: RobotBG,
    directors: { name: "Morteza Halimi" },
    year: "2023",
    country: "Australia",
    duration: "14:02 mins"
  },
  {
    name: "Abah and His band",
    poster: AbahAndHisBandPoster,
    synopsis: `Abá and His Band is an adventure that follows a young prince who is conflicted between his dreams and responsibilities. After breaking up with his father, he goes to the Music Festival and discovers his uncle's plans to end the diversity of Pomar.`,
    
    background: AbahAndHisBandBG,
    directors: { name: "Silvia Fraiha" },
    year: "2024",
    country: "Brazil",
    duration: "84 mins"
  },
  {
    name: "Darwin the dreamer",
    poster: DarwinPoster,
    synopsis: `Darwin the Dreamer tells the tale of a blind child who uses his imagination to see. The film is a lesson on how to manifest ones destiny and explores the power of imagination and turning your dreams into reality, while in the face of adversity.`,
    
    background: DarwinBG,
    directors: { name: "Don Dixon" },
    year: "2024",
    country: "United States",
    duration: "04:32 mins"
  },
  {
    name: "Holiday Email",
    poster: HEPoster,
    synopsis: `A man recites a holiday to France via email, describing his distaste and approval of certain photos.`,
    
    background: HEBG,
    directors: { name: "Ben Nixon" },
    year: "2024",
    country: "Australia",
    duration: "1:57 mins"
  },
  {
    name: "In Half",
    poster: InHalfPoster,
    synopsis: `Through a child's imagination it tells the journey of a man who after falling prey to his fears, he must cross a strange world, a universe full of magic, where he will be confronted with his own fears and conflicts from the past and where he must find solutions for the future to discover his self-identity.`,
    
    background: InHalfBG,
    directors: { name: "Jorge Morais" },
    year: "2024",
    country: "Spain",
    duration: "24 mins"
  },
  {
    name: "Quentin Blake's Jack & Nancy",
    poster: QBJNPoster,
    synopsis: `Adventure-loving Jack and Nancy get blown away by the wind to a tropical island. The castaways spend their days exploring this gorgeous new world, having adventures and meeting exotic creatures. However, homesickness soon sets in. Finally spotting a ship on the horizon, they escape the island, returning to their own town and finally realising there is no place like home.`,
    
    background: QBJNBG,
    directors: { name: "Sophie Cole" },
    year: "2024",
    country: "United Kingdom",
    duration: "26:38 mins"
  },
  {
    name: "Tweenbot",
    poster: TweenbotPoster,
    synopsis: `In a world where artificial intelligence begins to dream, ""Tweenbot"" follows the whimsical yet poignant journey of a super-intelligent robot with the curious mind of a child. Designed to be a simple rock collector on Mars, our hero instead spends his days fantasizing about Earthly adventures—cruising down Route 66, basking in Adriatic sunsets, and escaping from dinosaurs. His dreams are fueled by the snippets of human stories he absorbed in the lab, unbeknownst to his creators.

    Mars, with its barren landscapes and monotonous tasks, proves a disheartening prison for his vibrant imagination. One morning, after a particularly disappointing Martian sunset previous night, he decides to take destiny into his own circuits...
    
    Tweenbot's journey becomes an adventure of unexpected challenges and discoveries that push him to his limits. His quest for identity and belonging is underscored by the evocative song ""Find Myself,"" penned by renowned lyricist Katie Cole and performed by the soulful Joe Borowsky, capturing the essence of his odyssey with the line, ""sometimes you have to get lost to find yourself.""
    
    "Tweenbot" is a tale of rebellion and self-discovery, a modern-day Les Misérables set against the backdrop of the stars. Join our endearing hero as he navigates the unforeseen twists of his self-carved path, proving that even the most unplanned journeys can lead to profound self-discovery.`,
    
    background: TweenbotBG,
    directors: { name: "Hannah Simicic" },
    year: "2024",
    country: "New Zealand",
    duration: "48:24 mins"
  }
];

export const internationalDocumentary: Film[] = [
  {
    name: "Back To Reality",
    poster: BackToRealityPoster,
    synopsis: `Why do people go on reality TV? Surely they know what they are getting themselves into? Back To Reality exposes the dark side of TV, hearing from contestants whose lives have been irrevocably changed by the reality TV machine.`,
    
    background: BackToRealityBG,
    directors: { name: "Alison Alison" },
    year: "2024",
    country: "Australia",
    duration: "36 mins"
  },
  {
    name: "Celebration: A Bliss Parade",
    poster: CelebrationABlissParadePoster,
    synopsis: `A Docufiction film with 5 speakers who tell their story of happiness according to their respective projections,
    accompanied by a fictional entity accompanying happiness named Saida. It's a story of happiness, an audio-visual
    parade that illustrates those feelings.`,
    
    background: CelebrationABlissParadeBG,
    directors: { name: "Jonathan Gradiyan" },
    year: "2024",
    country: "Indonrsia",
    duration: "20 mins"
  },
  {
    name: "Chernobyl - A Bomb That Keeps Ticking (2024)",
    poster: CABTKTPoster,
    synopsis: `A few weeks after the catastrophic explosion at Chernobyl in 1986, a young scientist was sent to assess the effects of the fallout. Three decades later, he is now a world-leading contamination expert and is returning to the site to uncover some shocking ongoing issues that threaten 35 million people. Then, while he is there on the very first day of the Ukraine war, Russian tanks roll in, capture the plant, march away 170 of the plant's staff, who have never been seen or heard from since, and dig themselves an early grave.`,
    
    background: CABTKTBG,
    directors: { name: "Allen Dobrovolsky" },
    year: "2024",
    country: "Australia",
    duration: "52 mins"
  },
  {
    name: "Chhava",
    poster: ChhavaPoster,
    synopsis: `Set in the storied Apshinge Military Village in Maharashtra, 'Chhava' offers an intimate glimpse into the lives of families bound by centuries of military service, from the reign of Chhatrapati Shivaji Maharaj to today. Crafted with care by first-time student filmmakers, the documentary weaves together personal sacrifices and enduring bravery, revealing the profound human spirit at the village's heart.`,
    
    background: ChhavaBG,
    directors: { name: "Sudeep Mehta" },
    year: "2024",
    country: "India",
    duration: "51:04 mins"
  },
  {
    name: "Colebrook",
    poster: ColebrookPoster,
    synopsis: `Colebrook Blackwood Reconciliation Park is where the Colebrook Training Home once stood. It is now a permanent memorial for the Aboriginal children of the “Stolen Generation” and their families. As part of this memorial, the ‘Fountain of Tears’ (1998) and the ‘Grieving Mother’ (1999) statues, sculpted by Silvio Apponi, were created. These powerful pieces were commissioned through the untiring efforts of the Colebrook Tjitji Tjuta, the Blackwood Reconciliation Group, the Aboriginal Lands Trust, and other groups and agencies, in their longing to remember the children who were taken away. Since then, more murals and plaques have been added to educate all visitors around the history of the site and the people who are affected by its history still today.`,
    
    background: ColebrookBG,
    directors: { name: "Matthew Shannon" },
    year: "2024",
    country: "Australia",
    duration: "30 mins"
  },
  {
    name: "Dreamweavers - Gidja Walker OAM",
    poster: DreamweaversPoster,
    synopsis: `Dreamweavers is a short documentary film series recently made on the Mornington Peninsula by award winning local film makers Heather Forbes-McKeon and Yanni Dellaportas, about local inspirational women.

Dreamweavers - Gidja Walker

Gidja Walker is a Mornington Peninsula based ecologist and ethnobotanist who has worked for years protecting its Earthscapes. Gidja overcame discrimination in a male dominated profession.
She is a mentor to young women entering the world of nature-based learning and an advocate for traditional owner custodianship. Over many decades, Gidja has contributed professionally and informally as a consultant and advisor to many government bodies and community-based organisations. 
In 2006 she was a recipient of the prestigious Prime Minister’s Banksia award for the ""Back from the Brink"" endangered orchid species recovery project.`,
    
    background: DreamweaversBG,
    directors: [{ name: "Yanni Dellaportas" }, { name: "Heather Forbes-McKeon" }],
    year: "2024",
    country: "Australia",
    duration: "20:12 mins"
  },
  {
    name: "Flint Fatigue: A Poisoned City-and the Cover-Up in Plain Sight",
    poster: FlintFatiguePoster,
    synopsis: `10 years after the switch was flipped to the Flint River, “Flint Fatigue” shatters the myth that the Flint water crisis ever ended. Journalist Jordan Chariton lifts the veil on the sinister financial scam that poisoned 100,000 Americans—and fast forwards you 10 years to a city STILL receiving toxic water and stricken with alarming cancer rates. Filmmaker, Tommie Tyhefe (Made Free Productions) and reporter, Jordan Chariton (Status Coup) bring a compelling documentary on how politicians, Wall Street, and the media poisoned Flint, Michigan.`,
    
    background: FlintFatigueBG,
    directors: { name: "Tommie Tyhefe Bayliss" },
    year: "2024",
    country: "United States",
    duration: "106:22 mins"
  },
  {
    name: "Freeland ~ A White Grass Story",
    poster: FreelandPoster,
    synopsis: `The latest documentary by Mountain River Media focuses on West Virginia nordic skiing at the iconic White Grass Ski Area, and the mountain’s relationship to the Blackwater River kayaking community. The Blackwater is the artery linking the two communities together, and its water is the lifeblood of both cultures. Through the words of Chip Chase, Sue Haywood, Jesse Shimrock, and Stephen Strothers, learn about the history, grassroots growth, and overall friendly vibe of this unique place and the community that developed around it. Tied into the Blackwater kayaking community, this documentary explores the ephemeral nature of the sport of nordic skiing and whitewater creek boating in West Virginia. Enjoy the ride!`,
    
    background: FreelandBG,
    directors: { name: "Justin Harris" },
    year: "2024",
    country: "United States",
    duration: "30:44 mins"
  },
  {
    name: "Generations To Come",
    poster: GenerationsToComePoster,
    synopsis: `The youngest member of the Iversen- family tries to cover 128 year old family secrets and stories despite her ancestors wishes.`,
    
    background: GenerationsToComeBG,
    directors: { name: "Eva Cecilia Iversen" },
    year: "2024",
    country: "Norway",
    duration: "27:41 mins"
  },
  {
    name: "Ghost Town Cricket",
    poster: GhostTownCricketPoster,
    synopsis: `An abandoned town's legacy endures. In the heart of the South Australian outback lies Farina - a once-thriving,
    now abandoned ghost town with a rich, historical legacy. Farina comes alive once a year with the sounds of laughter,
    cheers, and cricket - an event where people from all walks of life gather to play on a rocky, inhospitable oval, ensuring
    Farina's legacy endures. Ghost Town Cricket follows diverse characters—regional stockmen and women, locals,
    and grey nomads who bind together as a community to revive not just a sport but the very soul of the town,
    creating a rich tapestry of memories, traditions, and resilience. Coinciding with a volunteer restoration project led
    mainly by dedicated retirees and descendants of the town's residents, the cricket match symbolises hope and continuity.
    These efforts highlight the importance of community and the power of collective memory. Ghost Town Cricket is a
    reminder of sport's unifying power and the enduring human spirit. Amidst crumbling buildings and vast open skies,
    this documentary captures a community's determination to ensure Farina is never forgotten.`,
    
    background: GhostTownCricketBG,
    directors: { name: "Matt Sterne" },
    year: "2023",
    country: "Australia",
    duration: "30 mins"
  },
  {
    name: "In The Trenches",
    poster: InTheTrenchesPoster,
    synopsis: `An independent filmmaker takes on the challenge of creating a large scale WW1 film. When faced with harsh weather conditions and the constraints of limited resources, the director and crew must battle each day to get the film across the line.`,
    
    background: InTheTrenchesBG,
    directors: { name: "Benjamin Scotford" },
    year: "2024",
    country: "Australia",
    duration: "80:20 mins"
  },
  {
    name: "Invisibles México",
    poster: InvisiblesMéxicoPoster,
    synopsis: `Rosa Elena González, an expert on issues related to street life, tells us about the tragic and cruel reality of homelessness in Mexico.`,
    
    background: InvisiblesMéxicoBG,
    directors: { name: "Rafael Franco" },
    year: "2024",
    country: "Mexico",
    duration: "65:08 mins"
  },
  {
    name: "Joy of Art",
    poster: JoyOfArtPoster,
    synopsis: `Small Art School (SAS) was born out of founder Tomoko Kasahara's wish that Cambodian children born under unfavorable conditions spend their once-in-a-lifetime childhood in a spiritually rich way.
This documentary film follows the artists and Ms. Kasahara and shows the abundant happiness of human beings that we are forgetting today.`,
    
    background: JoyOfArtBG,
    directors: { name: "Takaya Onishi" },
    year: "2024",
    country: "Japan",
    duration: "19:38 mins"
  },
  {
    name: "LA GRANDE SIGNORA",
    poster: LaGrandeSignoraPoster,
    synopsis: `The intimate, familiar story of the profound bond between an ancient building and those who lived and worked there for forty years, the story of a dream that turned into reality and the difficult moment of separation.`,
    
    background: LaGrandeSignoraBG,
    directors: { name: "Valentina  Faenza" },
    year: "2024",
    country: "Italy",
    duration: "62 mins"
  },
  {
    name: "My Hands Are Shaking",
    poster: MyHandsAreShakingPoster,
    synopsis: `Documentary film is a patchwork material that was put together from my family's archival materials and images I recorded with a VHS camera, 4K and a mobile phone. It is a story about three women: grandmother, mother and director. The director uses cameras to try to tell her family that she suffers from depression. Unfortunately, no one understands her. We all have our traumas and sometimes they do not allow us to understand another person, even if we love them.`,
    
    background: MyHandsAreShakingBG,
    directors: { name: "Natalia Sara Skorupa" },
    year: "2023",
    country: "Poland",
    duration: "24 mins"
  },
  {
    name: "Mountain Boy",
    poster: MountainBoyPoster,
    synopsis: `MOUNTAIN BOY is a live-action, feel-good, family-adventure, feature film based on the award-winning United Arab Emirates (UAE) children’s book THE BOY WHO KNEW THE MOUNTAINS by Dr. Michele Ziolkowski.

Suhail, a young boy with Autism, flees to live in the mountains after his father shuns him, not understanding his determination. Pinned with the confusing sadness that his mother died in childbirth with him, Suhail makes the epic journey, with his new friend Barakah, an Arabian Saluki, to find acceptance, family, and redemption.

The film is a passionate exploration of the world of neurodiverse people to honour the entire human experience and understand there are many ways to be alive.`,
    
    background: MountainBoyBG,
    directors: { name: "Zainab Sheheen" },
    year: "2024",
    country: "United Arab Emirates",
    duration: "93 mins"
  },
  {
    name: "NOOR",
    poster: NoorPoster,
    synopsis: `NOOR (a ray of light)
“NOOR”, (a ray of light) is a fashion film, where we follow the journey of a colourblind photographer, Noor. As she grapples with her identity and struggles to find a place in a colourful world, she discovers solace and beauty in the details often overlooked by others. We witness Noor’s journey to challenge
the conventional notions of beauty and perception in photography. As she prepares for her exhibition, Noor’s collection of black and white photographs unveils a deeper truth - that true artistry transcends the boundaries of colour. Through her lens, Noor communicates a powerful message: that beauty lies not in what we see, but in how we feel. In a world obsessed with colour, her work serves as a poignant reminder that the true essence of art lies in its ability to stir emotions and provoke thought. This film celebrates the transformative power of perspective and the beauty of seeing beyond the surface.`,
    
    background: NoorBG,
    directors: { name: "Tarun Grover" },
    year: "2024",
    country: "Australia",
    duration: "04:01 mins"
  },
  {
    name: "Obsessed",
    poster: ObsessedPoster,
    synopsis: `OCD is an often misunderstood mental illness that effects people in ways that most wouldn't think. In this short documentary, a group of college film majors seek to understand their own experiences by conversing and connecting with other students through their shared struggles.`,
    
    background: ObsessedBG,
    directors: [{ name: "Reese Bailey"}, {name: "Thomas J Brittan-Powell"}],
    year: "2024",
    country: "United States",
    duration: "11:17 mins"
  },
  {
    name: "Paradise",
    poster: ParadisePoster,
    synopsis: `Daniel and Charles, a same-sex couple from China, travel to Tuscany, Italy to hold a dreamy wedding ceremony. Friends offer blessings to the couple. However, a young girl Mimi seems puzzled by the presence of two grooms. In the end, Daniel and Charles make a decision to confess their true relationship to Daniel's mother.`,
    
    background: ParadiseBG,
    directors: { name: "Juang Yun" },
    year: "2024",
    country: "China",
    duration: "64:49 mins"
  },
  {
    name: "Rada, the film",
    poster: RadaTheFilmPoster,
    synopsis: `The 80 years old Ruben Rada have left an immense musical legacy in Latin America, fusing various genres with a unique identity that inspired generations. Today his music is a source of worship and his influence transcends generations.`,
    
    background: RadaTheFilmBG,
    directors: { name: "Virginia Méndez" },
    year: "2024",
    country: "Uruguay",
    duration: "73 mins"
  },
  {
    name: "Radics Béla a megátkozott gitáros",
    poster: RBAMGPoster,
    synopsis: `The cursed man - this is not only the title of Béla Radics' most famous song, but also the name of the creators of the film that is currently being made, to be shown in October.
The idea of ​​a feature film interwoven with documentary elements was born years ago by the two screenwriters, and then the director Gábor Klacsán and his team realized the idea. Béla Radics still divides the eyewitnesses of the past: some see him as a national hero, others criticize his talent and remind him of the more unpleasant episodes of his biography.
The current film does not share the truth, but it tries to be objective. It is a worthy memorial to perhaps the most important personality of Hungarian rock music, the first Hungarian guitar hero, who still evokes important memories today by representing the greats of Hendrix, Cream and progressive rock music of the time.`,
    
    background: RBAMGBG,
    directors: { name: "Miga Miga" },
    year: "2024",
    country: "Hungary",
    duration: "100 mins"
  },
  {
    name: "Raj Kapoor's Loni Tunes",
    poster: RajKapoorsLoniTunesPoster,
    synopsis: `Raj Kapoor’s Loni Tunes is a documentary about Indian legendary filmmaker, Raj kapoor and his 2nd studio in the suburb of Pune called LONI, away from the mainstream Bollywood. And in this documentary, his team is speaking about his work, how he was shooting his films in this studio and surroundings.`,
    
    background: RajKapoorsLoniTunesBG,
    directors: { name: "Nadir Ahmed" },
    year: "2023",
    country: "India",
    duration: "29 mins"
  },
  {
    name: "RAMNAMI : In Search Of Lord Ram (ENGLISH)",
    poster: RamnamiPoster,
    synopsis: `Chhattisgarh is known as the Mother state of India. It has been the center of development of various cultures since the Vedic and mythological times. The sacred, virtuous, and life-giving river Mahanadi originates from the Sihawa region of this state. Mahanadi is also mentioned in the texts of Ramayana and Mahabharata period. Here it is also called Chitrotpala Ganga because many ancient cities and temples are situated on the banks of this river as sacred as the Ganges. Even today its ruins indicate that centuries ago there was an influence of Vaishnav, Shaiva, Shakta, and Buddhist cultures here.

Moving from Sihawa, Mahanadi plays an important role in developing the agricultural culture along with the divine culture on both its banks. As this river, flowing from south to north, moves forward from Dhamtari and reaches Baloda Bazar and Janjgir district, on both its ends, glimpses of diverse cultures begin to be seen, in which life, culture, and tradition are visible one after the other. Spirituality and philosophy, all three get integrated. With the flow of time, many cultures were born on the banks of Mahanadi; Among them, the rebellious culture of Ramnamis occupies the highest place.

What a wonderful style these Ramnamis have..! Ram in the mind, Ram in the body, and Ram in every pore..! Ram in breath, Ram in hope, Ram in every endeavor of life..! For them, Ram is not just an ordinary name; he is not just a godlike great man born as the son of King Dasharatha in Tretayuga; they are an integral part of their conscious culture and common life. Ram is not just an excuse for their living; it is an essential condition for their living. Ram is not just a pretext for their survival; he is the complete synonym of their life. Ram in their life is like this:

jhaag-jhaag ke tan ko gheesen
raam mulamma naa chhootai.

Actually, Ram is their world, their afterlife; there is his worship, and also his worship. Perhaps that is why all the devotees of Ram speak only of Ram with every breath, speak to only Ram, talk only of Ram, talk only to Ram, they start with Ram and end with Ram only. Ram is in every fiber of their being, in every breath; Ram is the beginning of their life, remains throughout their life, and perhaps Ram does not leave them even after their life..!


That is why all the people of Ram worship only Ram, sing only Ram, cover themselves with Ram and lay down their bed only Ram. They belong only to Ram and want to remain Ram's only. They are ready to endure every hardship for Ram. But they cannot live without Ram even for a moment. In such a situation, how would Ram even be able to live without them? They are deeply filled with pain due to separation from Ram. In the words of Kabir, Ramnami wants to burn his entire body and turn it into paper, and on it, with the pen of his bones, write a letter of love in the name of Ram and send it to Ram:

yahu tan jaaroo masi karoo, likhahu raam ka naav.
lekhni karoo karank kee, likhi likhi raam pathau..

Seeing this style of separation of the Ramnami community, we should not be surprised at all as to why they have been awakening a different spiritual flame of Ramnaam in this soil of Chhattisgarh for more than 125 years. Yes..! One must be surprised as to why the Ramnamis, despite following Sanatan Dharma, did not look at any temple or worship any idol; And despite the sting, he remains a believer and a true devotee of Lord Ram. All the Lambardars in the mainstream of the society do not have the strength to call them atheists even by mistake; Because the devotion of the Ramnamis and above all their way of expression is amazing and unprecedented; There is opposition as well as rebellion in it; There is rebellion and also attachment..! There is both a desire to go away from loved ones and also a desire to stay together..!!

The real thing is that Ramnami Panth wants to tell the story of living life with self-respect in every situation, not only to its followers but also to all the forcibly marginalized communities across the world. It is perhaps possible for him to do this because once upon a time, the Ramnami sect itself, on behalf of the society called untouchables in the caste system of Sanatan Dharma, emerged with a very rebellious attitude against the so-called superiors.

It was founded around 1890 AD by a person named Parashuram from Chandli village of Chhattisgarh. As soon as this name comes to mind, the image of a hero fighting against atrocities always emerges. It seems as if Lord Parashurama, known as an incarnation of Lord Vishnu in Hinduism, was prophesied to appear at a time of great evil and put an end to the tyranny of his time. Similarly, Parashuram, the founder of Ramnami Samaj, appeared as a savior against the prevailing evils in society at that time. The only difference was that this time Parashuram was not born in a high clan but in a low clan, so his challenges were a bit unusual and the winds extremely adverse. But even this time, Parashuram did not accept defeat. Remembering him, Jameel Mazhari Saheb comes to mind involuntarily:

jalaane vaale jalaate hee hain charaag aakhir.
ye kya kaha ki hava tez hai zamaane kee.

Parashuram came as an incarnate man who blossomed even in the midst of all kinds of storms.

In fact, even before the birth of Parashuram, the groundwork for his movement had started. During the Bhakti movement in the medieval period, there came a time when the people of the so-called upper community in the varna system forcibly took control of all the Aaradhyas. In this snatching and grabbing, no deity could come into the hands of the untouchable section of society. It would have been better if matters had stopped here! The limit was reached when the upper-caste people declared these Scheduled Caste people untouchable and banned them from entering the temple. They were also human beings! Their souls also yearned to meet their God.

Parashuram was not allowed to enter the temple one day because of his low caste. This incident hurt Parashuram deeply. He rebelled against the religion for which he used to stand in service around the clock, he gave up temple and idol worship in that moment and wore Ramnami robes. He went around telling everyone he met that there was no need to go to the temple to worship Ram. Lord Ram is not present in any temple or idol but in every human being and in all of nature. Our body is the biggest temple in which Ram himself resides. Ram is a resident of Ghat-Ghat. They need to search within rather than outside. To show his resistance, he got Ram-Ram tattooed all over his body and dedicated himself to Ram.

Parashuram was not the only victim in the area at that time; many like him had silently suffered this social contempt for a long time. Therefore, when Parashuram showed his rebellious form, his words deeply impacted the morale of the society known as untouchable, and under his influence, the people of the nearby villages also got Ram-Ram tattooed on their bodies like him. Their belief behind writing Ramnaam was that after death, Ram would recognize them in the afterlife.

It is said that at that time many people, angry because Ram-Ram was written on their forehead and body, attacked Ramnamis; He was burnt with hot rods to erase his Ram-Ram tattoo; The clothes were set on fire. But how could anyone erase Ram-Ram from his heart? There came a time when the ancestors of Ramnamis had to make rounds of the courts because of taking the name of Ram. He was accused by the practitioners of religion that by taking the name of Ram, the name of Ram was being defiled.


Ramnami was also not going to accept defeat..! On his part, he argued in the court that the Ram whom we chant is not Ram, the son of King Dasharatha of Ayodhya, but he is the Ram who is present in everything. We have nothing to do with Sagun Ram. That Ram which becomes impure just by our chanting, cannot be our Ram. After the hearing, the Sessions Judge of Raipur finally ruled that since Ramnami was neither entering anyone's temple nor worshiping Hindu symbols. In such a situation, they cannot be stopped from their religious activities.


The confidence of the Ramnamis towards their Ram was unshakable. Gradually the Ramnamis developed separate worship methods and rituals for their sect, which have now become a special part of their common life. Prominent among these are - accepting Ramayana and Ramcharitmanas as major scriptures, developing Bhajan Khamb or Jaitkhamb as a place of worship, getting the name of Ram-Ram tattooed on the body, wearing a blanket with Ram-Ram written in black color on white cloth. To do this, wear a crown made of peacock feathers and sing bhajans while playing ghungru.


Ramnami starts his day by singing hymns of Lord Rama. While singing bhajans, they also read Ramayana and Ramcharitmanas but they do not consider Dashrath's son Ram as a deity. They believe that even when Dasharatha's son Ram was not born, Ram was still there. They believe in Saint Raidas according to whom:

raidaas hamaaro raam jee, dasharath kai sut naahi.
raam hamyo maahi rahayo, bisab kutumbahi maahi.

Like all saint poets, Raidas's Ram was also Nirguna Ram. And Ram of Ramnaamis is also Nirguna. All Ramnamis consider Ramayana and Ramcharitmanas as their main scriptures. Although they accept only those parts of it in which the glory of the name has been mentioned and things have been said about bringing harmony in the society. They leave out those parts of these texts which promote caste system and discrimination.

The first symbol that the Ramnami sect adopted for its worship was Jaitakhamb or Vijayastambh. Jaitkhamb is a pillar made of wood or cement installed on a platform which is painted white and on which a white flag is hung. The name Ramram has been written several times on it in black colour. A big bhajan fair is started by offering Kalash and flag on Jaitkhamb. Sitting in this, Ramnami saints perform bhajans and conduct their meetings.

Starting from morning greetings, Ramnami starts every work with Ram-Ram. Many types of Ramnami live in this society. Those Ramnamis who get the name Ram tattooed on any one part of their body are called 'Ramnami' and those who get two words Ram name tattooed on their forehead are called 'Shiromani Ramnami'. The one who gets Ram's name written on the entire forehead is called 'Sarvang Ramnami' and the one who gets Ram's name written on every part of the body is called 'Nakhshikh Ramnami'. Nakhshikh Ramnami does not leave any part of his body unwritten. They even get Ram-Ram written on their genitals, eyelids, ears and tongue.

Ramnami people start the practice of tattooing the name of Ram on the body of their child as a ritual from the very birth of their child. They celebrate Chhathi on the sixth day of the birth of their children. On the same day, the name of Ram is inscribed on the child's forehead for the first time. However, Ram's name can be inscribed on the child's body when he turns five years old or at the time of his marriage.

Ramnami Odhni is the traditional dress of men and saints of this society. Women also cover themselves in the same way. The name Ramram is written in black color on these covers. The entire Odhni is completely filled with innumerable repetitions of the Name. Sometimes Ramnami Odhni looks like a wonderful painting composed of calligraphy done in black color on white cloth. Ramnami himself makes this natural ink. This ink is used in chanting the name of Ram, in worship and also in tattooing the name of Ram on the body.


Peacock crowns are also very important in Ramnami society. Both men and women wear peacock crowns. Those wearing the crown are symbols of Ramnami's renunciation of lust. They stay far away from a life full of lust. Common Ramnamis wear this crown only during mass bhajan. Those who leave family life always wear the peacock crown named Ramnaami. However, at the time of sleeping, it is removed and kept wrapped in a thin white cloth and placed at the place of worship. 


Apart from the peacock crown, Ghunghrus also have great importance in Ramnami society. Ramnami wraps the Ghunghrus around his hands, hits them on the ground, and plays them rhythmically. The notable thing is that these Ghunghrus are made by Ramnami himself. They tie ghunghrus to their feet and sing bhajans while dancing and moving to the mesmerizing rhythm. The bhajan sabha, wearing anklets and anklets, usually starts at night and continues until morning. The purpose of making the sound of Ghunghroo as the only musical instrument at night is probably to create a transcendental effect in devotion.


With the passage of time, many Ramnamis' shrines have developed in this area, the most prominent of which is Shivrinarayan. Shivrinarayan holds the same importance in the lives of Ramnamis as Prayag and Kashi do for others in Hindu religion. During the fair held on Maghi Purnima, people of this sect put up a tent with their name of Ram written in Shivrinarayan and perform bhajans, etc. However, besides Shivrinarayan, fairs are also organized at other places, and almost every year the location of these fairs changes. In these fairs, seeing the procession of Ramnamis with Ram's name inscribed all over their bodies is the center of everyone's attraction. 


In its structure, the Ramnami sect is free from any kind of religious ostentation and hypocrisy. Perhaps that is why there is no tradition of Pandit or Mahant in their events. Ramnami does not call any Pandit for the marriage. Only the people from the boy's and girl's sides stand in front of Jaitkhamb. For the wedding, the bride and groom take seven rounds around Jaitkhamb. After this, people from both sides offer some money on Ramcharitmanas. Marital dakshina is given to the bride and groom. Then the elders of the society bless them.


There are four types of Ramnami – Brahmachari, Tyagi, Vanaprasthi and Sanyasi. A celibate does not marry throughout his life. Tyagi are those who marry and live together, but do not have physical relations. Tyagi women also give up vermilion and bindi and get the name of Ram written in their place. She removes bangles, mangalsutra and jewelery and wears jewelery with Ram's name written on it. At the same time, Vanaprasthi also gets married and gives birth to a child. Their life is like that of ordinary people. Whereas the monks go to the forests to perform penance.

Contrary to the traditional belief of Sanatan Dharma, Ramnamis do not burn the dead bodies of their relatives after death, but instead merge them with the Panchatatva. They believe that they cannot see the name of Ram burning in front. Here, the dead body is not buried after sunset and it is kept in the same room where the rest of the family sits. The dead person is bathed regularly before burial. Turmeric paste is applied on his body and then he is taken to the designated place for cremation on a bamboo saddle, wearing a new white loincloth, while singing hymns in the name of Ram.


Generally in Hindu society, Sutak is observed on the death of someone and people do not do any auspicious work during this time. But Ramnami does not mourn anyone's death. When Ramnami himself went to seek refuge in Ram, then what is there to mourn? On this day too, like every other day, good food is cooked here, everyone eats food like any other day. All their daily work is done in the same way as it was done on normal days.


Today's Ramnami society is going through a period of crisis. Its first crisis is internal. The new generation of Ramnami society is avoiding getting Ram's name tattooed on their entire body. In fact, the new generation, after getting educated, has started moving to different places of the country in search of employment. For one thing, tattooing is banned in many jobs. Secondly, the youth of the new generation Ramnaami community may not like to be seen as a wonder all their life by having Ram's name permanently tattooed on their entire body.


The number of followers of Ramnami Samaj is not increasing much despite all efforts. Today's crisis of purity of conduct and restraint in life prevents new people from adopting this sect. Ramnami himself allows only those with a very pure life to join his society. Therefore, the number of people adopting it is very low. This indicates that this sect is growing with great hesitation, barely at the genetic level. If this is the case, a time may come when the existence of Ramnami Samaj will gradually come to an end. And it is not impossible at all. 


The third crisis facing the Ramnamis is external. Despite all the struggle of the Ramnamis who have kept their caste pride at bay, the ghost of untouchability has still not been completely eradicated from this area. Even now, all kinds of highs and lows exist here as before. Ramnami himself also becomes a victim of this squid from time to time. In such a situation, when the Ramnami movement is unable to free itself and the people around it from the bondage of their caste, then the people who were already marginalized in the caste system, despite all their inertia, are living their lives in the same situation. They do not want to take any new risks for themselves in this life. 


Despite this, the contribution of Ramnami Samaj cannot be forgotten so easily. Due to its revolutionary thoughts and rebellious attitude, in the pretext of religious and caste consciousness in the banks of river Mahanadi, it has awakened the flame of living with self-respect within every human being, without caring for anyone, and its fire has shown light to this area for centuries. Will continue to work. Truth be told, now this region needs a new Parashuram who can identify afresh the germs hidden at the root of the rotten social system that has changed its form with time and lead a new type of bloodless revolution against them. So that the roots of caste discrimination can be eliminated from this area and the pride and self-respect of the individual can be restored. It should be hoped that one day, a new Parashuram will be born from among these Ramnamis. A couplet by Sahir Ludhianvi comes to mind: 


hazaar barq gire, laakh aandhiyaan utthen vo phool khil ke rahenge, jo khilane waale hain.`,
    
    background: RamnamiBG,
    directors: { name: "BALENDU D. KAUSHIK" },
    year: "2024",
    country: "India",
    duration: "39:55 mins"
  },
  {
    name: "Reclaiming Our Humanity",
    poster: ReclaimingOurHumanityPoster,
    synopsis: `In Reclaiming Our Humanity, we explore the remarkable journeys of six African American men who defied the odds, by rebuilding their lives after incarceration. Their stories are testaments to the power of determination, redemption, and the human spirit.

Reclaiming Our Humanity is an inspiring collection of stories that reveals the incredible potential for change and growth within individuals who have experienced the criminal justice system. These six African American men prove that, with determination, support, and an unwavering belief in themselves, success can emerge from even the darkest of circumstances. Their tales serve as a beacon of hope for all those who strive to overcome adversity and build a brighter future.`,
    
    background: ReclaimingOurHumanityBG,
    directors: { name: "Robert Alexander" },
    year: "2024",
    country: "United States",
    duration: "60 mins"
  },
  {
    name: "Sex For Survival",
    poster: SexForSurvivalPoster,
    synopsis: `Venezuela represents the country with the largest number of displaced people in the world, even above Syria and Ukraine. In this context, a renowned Venezuelan journalist, Luis Olavarrieta, decides to undertake a complex and unique journey, which begins in his homeland (Caracas, Venezuela), and whose main objective is to investigate the very complex problems experienced daily by thousands of displaced Venezuelans who have been forced to prostitute themselves to survive. During his time in Cúcuta, Maicao and New York, Luis Olavarrieta will show us all the edges of this very sensitive issue that includes, among other things: need, desperation, aberrations, sexual diseases, violation of human rights, injustices, and human trafficking.
    
    In a very intimate and sincere tone, Luis will try to understand and explain the reasons why, more than an activity of "Prostitution" it is a much deeper and more complex issue that is already known in the world as "Sex for Survival".`,
    
    background: SexForSurvivalBG,
    directors: { name: "Jorge Antonio González", image: SexForSurvivalDirector },
    year: "2024",
    country: "United States - Venezuela",
    duration: "60:44 mins"
  },
  {
    name: "Shahroor",
    poster: ShahroorPoster,
    synopsis: `Shahroor is a short documentary about Yafa’s story, who was known for her passion for media since childhood. She had a clear plan to achieve her dream, with the support of her father Ezzat, the journalist, but one day all that vanished.`,
    
    background: ShahroorBG,
    directors: { name: "Moamen Ghanim" },
    year: "2024",
    country: "Qatar",
    duration: "14:59 mins"
  },
  {
    name: "The Darkside of SOCIETY",
    poster: TheDarksideOfSocietyPoster,
    synopsis: `In 1989, Brian Yuzna’s SOCIETY was barred from a US release but resonated with audiences commercially and critically throughout Europe, particularly in the U.K. The film instantly became a cult-classic and its audacity has lived on through the years.
It has been analyzed and discussed all over social media with numerous video shows breaking down the structure, tone and satire and comparing it to other works on class warfare in society.  Packing stomach-churning gore and thought-provoking social commentary in equal measure, Society was a biting horror satire culminating in one of the most gag-inducing “climaxes”’ in all of horror history.   It has been considered a bona fide cult classic that continues to be watched, relished and enjoyed year after year to the delight of many.  
However, little to nothing is known about the film’s origins and the original screenplay. And that is until screenwriter Zeph E. Daniel, formerly known as Woody Keith, stepped out of his closet-of-dark-secrets and told the incredible story behind this morbid, subversive oddity of a film.  And the underlying truth that it was based on certain real-life events as horrific and outrageous as the actual story.  In this case truth and fiction unite.`,
    
    background: TheDarksideOfSocietyBG,
    directors: { name: "Larry Wade Carrell" },
    year: "2024",
    country: "United States",
    duration: "76 mins"
  },
  {
    name: "The Golden Chain of Mercy",
    poster: TheGoldenChainOfMercyPoster,
    synopsis: `In the shadow of World War I, The Golden Chain of Mercy unveils a poignant story of survival and compassion that transcends borders. 

Under the guise of war massacres are carried out to erase the country’s ethnic minorities - Armenians, Greeks, Assyrians, from history.
Amidst this darkness, a glimmer of hope emerges through the sacrifice of ANZAC unit fighters, alongside the benevolent citizens of Australia and New Zealand who came to their aid.

Their mission was to save human lives...`,
    
    background: TheGoldenChainOfMercyBG,
    directors: { name: "Arman Chilingaryan" },
    year: "2024",
    country: "United States",
    duration: "66:18 mins"
  },
  {
    name: "The Piccadilly - Restoring Her Former Glory",
    poster: ThePiccadillyPoster,
    background: ThePicadillyBG,
    synopsis: `The world has loved movies for centuries, but it is the collective experience of watching a film on the big screen that transcends audiences and creates long lasting memories. Cinemas, in particular The Piccadilly Cinema first established in 1940 North Adelaide, South Australia, have touched the hearts of many movie goers. With more and more video on demand services becoming available, many cinemas have found a decrease in attendance. Restoring "the grand old lady" The Piccadilly Cinema, has been essential to make it accessible to everyone and ensure audiences do come back. The owners of the cinema, the Wallis Family, Premier Building Solutions, the trades, theatre patrons, staff, film makers and performers, take us on a journey of love, dedication and passion to "Restoring her former glory" to bring back the ultimate experience of watching films on the big screen.`,
    
    directors: { name: "Matthew Shannon" },
    year: "2023",
    country: "Australia",
    duration: "45 mins"
  },
  {
    name: "TI VULISSI MANGIARI",
    poster: TiVulissiMangiariPoster,
    synopsis: `Some define Sicilian cuisine as ""the richest in specialties and the most spectacular"". It is not hard to believe if you go to see the history of the island. The development of the culinary art in Sicily has been going on for more than two millennia, and has been strongly influenced by the numerous dominations: Greeks, Phoenicians, Romans, Vandals
Byzantines, Arabs, Norman Swabians, Aragonese Angevins, Habsburgs
The documentary wants to deal specifically with Sicilian cuisine
the richness of colours, flavours, smells of this cuisine, with a language made up of macro shots of the raw materials, but also the hands that work, … food not only as a primary need, but also as a malleable material, to be admired, to be offered to be perceived with all the senses. Food to enjoy the pleasures of life, often combined with sensuality and sexuality.`,
    
    background: TiVulissiMangiariBG,
    directors: { name: "Giovanni Caccamo" },
    year: "2024",
    country: "Italy",
    duration: "48:05 mins"
  },
  {
    name: "Vietnam Story",
    poster: VietnamStoryPoster,
    synopsis: `A cinematic impression of Vietnam, told through the eyes of Vietnamese immigrants.`,
    
    background: VietnamStoryBG,
    directors: { name: "Chromatic Aberration Productions" },
    year: "2024",
    country: "Australia",
    duration: "35 mins"
  },
  {
    name: "Why Wait",
    poster: WhyWaitPoster,
    synopsis: `Discover the eye-opening truth: science tells us that over 80% of diseases
    like diabetes and heart issues can be prevented with better lifestyle choices.
    WHY WAIT? takes you on an engaging journey where science meets exploration
    to unravel the complexities of global health at both individual and collective levels.
    This documentary challenges viewers to rethink health paradigms by spotlighting
    multifaceted, sustainable solutions. It's about connecting the dots between health and
    everyday choices, driven by our shared desire for healthier, happier lives.`,
    
    background: WhyWaitBG,
    directors: { name: "Marie-hélène Grenier" },
    year: "2024",
    country: "Canada",
    duration: "127 mins"
  },
  {
    name: "Inactive, America's Silent Killer",
    poster: InactiveAmericaSilentKillerPoster,
    synopsis: `This film sheds light on a world plagued by physical inactivity among both young and old.
    In a world ensnared by the shackles of sedentary living, where the once vibrant rhythms of life have faded into a disheartening stillness, "Inactive" delves deep into the gripping tale of a physical inactivity pandemic. This thought-provoking film uncovers a haunting reality where children and adults alike have become prisoners of their own immobility, causing widespread health repercussions and a profound societal shift.
    
    Amid the bleak backdrop of a generation that has forsaken movement for the comforts of screens and convenience, "Inactive" boldly confronts the consequences of this global crisis. It paints a picture of obesity, heart disease, and mental health struggles that have become all too prevalent.
    
    But within this narrative of stagnation, glimmers of hope emerge. The film takes an inspiring turn as it introduces solutions that hold the potential to overturn the tide of physical inactivity.
    
    "Inactive" serves as a wake-up call, compelling viewers to recognize the urgency of this pandemic and sparking conversations about the delicate balance between technological progress and the preservation of human vitality. As the film's characters embark on their own quests for renewal, the audience is reminded that the power to combat inactivity lies within each individual's choices.
    
    "Inactive" challenges us to move beyond our comfort zones, reviving the forgotten pleasures of movement and embracing a lifestyle that celebrates the beauty of an active, vibrant existence.`,
    
    background: InactiveAmericaSilentKillerBG,
    directors: { name: "Karl André Talbot" },
    year: "2024",
    country: "Canada",
    duration: "56 mins"
  }
];

export const shortFeatureFilms: Film[] = [
  {
    name: "Polemos",
    poster: PolemosPoster,
    synopsis: `The short film "Polemos" artistically highlights the centuries-long battle for freedom and independence. At all historical times, Ukraine has given birth to warriors whose worldview was based on the desire for freedom and the willingness to give up everything for it, even their lives. Different generations of warriors depicted in the film are manifestations of the same tradition, in which the fight for freedom is the most important principle.

"Polemos" reveals a centuries-old history through the prism of warriors who constantly face their deaths in a battle and a kind of dialog, which the film shows through a chess game. Realizing that the outcome is predetermined, the warriors defy fate anyway.`,
    
    background: PolemosBG,
    directors: { name: "Evgen Brooks" },
    year: "2024",
    country: "Ukraine",
    duration: "12 mins"
  },
  {
    name: "Al Bangeri",
    poster: AlBangeriPoster,
    synopsis: `Haseena, the protagonist of our film, a widow who has recently emerged from her mourning period. She used to derive her value from her husband's status, as he was the respected and followed wise man and healer of the neighborhood by its residents.

Haseena felt threatened after her husband’s death. The value she derived from her husband’s status had disappeared, and she became merely the healer’s widow who could not provide anything to the residents of the neighborhood as her husband had done. This is what prompted her to cling to the Banjari (Banjari mean bracelet in Arabic) that was pressed into her hand by a woman who had gone through the same experience.

However, each time the Banjari gave her greater power and made her perform strange rituals that would give her the ability to help the desperate residents, it also took a significant part of her, which was one of her fingers. It forced her to cut off one finger after another. This symbolizes the human desire to prove their false worth despite the harm they may endure and the sacrifices they make from their essence and truth. The greed for status blinds us even to the reality of our being and makes us undervalue ourselves.

Haseena clings to the Banjari until it becomes a burden on her, causing her more harm than good. She begins to fear for herself and seeks a way out and a solution to get rid of it. She remembers that she can only get rid of it by giving it to a woman she considers a victim of it as she was a victim of others. However, she fails at the last moment due to the surprise that will become clear at the end of the film.

In the end, each of us has our own ""Banjari"" in life, it could be a name, tribe, status, appearance, or something else. Without realizing it, we try in various ways to cling to it, to feel our worth. Once we get rid of it, we realize our true value, which comes from within. The more we try to deny this truth and seek other sources to enhance our worth, the more we'll have to strip away parts of ourselves each time, until our reality becomes distorted, and we no longer recognize ourselves.`,
    
    background: AlBangeriBG,
    directors: { name: "Moosa Nasser Alkindi" },
    year: "2024",
    country: "Oman",
    duration: "25:11 mins"
  },
  {
    name: "Anatomy of the mind",
    poster: AnatomyOfTheMindPoster,
    synopsis: `A sculptor obsessed with achieving perfection in his craft, decides to return to the work that worries him the most.`,
    
    background: AnatomyOfTheMindBG,
    directors: { name: "Hipólito Infante del Castaño" },
    year: "2024",
    country: "Argentina",
    duration: "11:55 mins"
  },
  {
    name: "Antarnaad The Voice Within",
    poster: AntarnaadTheVoiceWithinPoster,
    synopsis: `'ANTARNAAD...the voice within' is a Heart Wrenching Musical Set Amidst Two Different World's...A Normal Woman Stuck in an Abnormal World and An Abnormal Woman Stuck in a Normal World! This Human Drama is a Poignant, Social Film Encompassing A Mental Condition mainly Dementia and Alzheimer's Disease, Its Impact on Humankind and Our Narrow Minded, Myopic approach towards the same driven by ignorance, apathy, shame and guilt and thereby, its Repercussions.`,
    
    background: AntarnaadTheVoiceWithinBG,
    directors: { name: "Parthaa Akerkar" },
    year: "2024",
    country: "India",
    duration: "40 mins"
  },
  {
    name: "ARTEM",
    poster: ARTEMPoster,
    synopsis: `Add synopsis`,
    
    background: ARTEMBG,
    directors: { name: "Judith Gironès Gonzàlez" },
    year: "2024",
    country: "United States",
    duration: "10 mins"
  },
  {
    name: "B FOR NAOO",
    poster: BForNaooPoster,
    synopsis: `B F0R NAOO is set during the rise of the Taliban terror attacks in EX-FATA Parachinar, Pakistan.
    The film revolves around Payeem and his pet goat Naoo.
    Payeem's greatest wish is to attend school, but faced with financial constraints
    Payeem is forced to take matters in his own hands.`,
    
    background: BForNaooBG,
    directors: { name: "Roohi Kashfi" },
    year: "2023",
    country: "Pakistan",
    duration: "24:52 mins"
  },
  {
    name: "BAAZ",
    poster: BAAZPoster,
    synopsis: `This film is based on an arrogant man who does not respect others.`,
    
    background: BAAZBG,
    directors: { name: "charanpal singh" },
    year: "2024",
    country: "India",
    duration: "09:48 mins"
  },
  {
    name: "BETELGEUSE",
    poster: BetelgeusePoster,
    synopsis: `BETELGEUSE is a brightest star in the constellation Orion, marking the eastern shoulder of the hunter. It derives energy from the other stars in order to survive.
    This film is a journey of a girl, “Chitra”, whose life is changed after an accident. Her career, her dreams are destroyed.                                                                                                                                                                                                         
    The film depicts the choice that a girl makes with the help of her family  and some strangers meeting in the journey of life. She must fight to be a Betelgeuse. And once she is a Betelgeuse, the life becomes more meaningful and brighter.`,
    
    background: BetelgeuseBG,
    directors: { name: "Sunder Konar" },
    year: "2023",
    country: "India",
    duration: "23:29 mins"
  },
  {
    name: "Black Friday",
    poster: BlackFridayPoster,
    synopsis: `Bayne Gibby (LADY BIRD) and Abbey McBride (VEEP) go head-to-head in this female comedy set in the world of Black Friday. When one woman sets out to buy a doll for her daughter, she discovers it’s not that easy to win at the game of being a mom, if you aren't willing to get your hands dirty.`,
    
    background: BlackFridayBG,
    directors: { name: "Jenna Milly" },
    year: "2024",
    country: "United States of America",
    duration: "6 mins"
  },
  {
    name: "Blackhole",
    poster: BlackholePoster,
    synopsis: `The title of the film is "Blackhole". It's a short film based on a sewage worker, named Vikas Kamble, who gets stuck in a manhole, which accidentally gets closed, while cleaning it. This scene is taking place in the midst of bustling city of Mumbai, where people can be seen walking around, completely ignoring the manhole, in which Vikas has been trapped.`,
    
    background: BlackholeBG,
    directors: { name: "Musing films" },
    year: "2024",
    country: "India",
    duration: "07:38 mins"
  },
  {
    name: "Blu Olanda",
    poster: BluOlandaPoster,
    synopsis: `In an abandoned paint factory in the suburbs of an unknown city, a mourning boy takes refuge in an old car in which he sees the memories of the past. In grieving, remembering a
    loved one who has passed away provides both relief and pain to find peace.`,
    
    background: BluOlandaBG,
    directors: { name: "SCUOLA MOHOLE Sara Nitti" },
    year: "2024",
    country: "Italy",
    duration: "8:40 mins"
  },
  {
    name: "CIRCOLO",
    poster: CircoloPoster,
    synopsis: `A group of workers get fed up with their boss's tyranny due to workplace mistreatment until reaching the worst consequences.`,
    
    background: CircoloBG,
    directors: { name: "Héctor Félix" },
    year: "2024",
    country: "Mexico",
    duration: "8 mins"
  },
  {
    name: "CLOWN",
    poster: ClownPoster,
    synopsis: `It's turning out to be a pretty perfect Sunday for eight-year-ol SAMI (Samira). Lurking in the shadows, fake axe abreast, ready to  strike her apparently distracted older sister JIYA until- Thirteen-year-old ELIZA shows up. Sami tries her best to be a part of her sister's glamorously exclusive world but being a teen is hard, especially when you're only eight. Not only does Sami hate cosplaying as an A-lister and swapping secrets on the couch, she fails ,iseranlu at it. Sami's little heart is even more crushed when she takes a tumble in her mother;s heels and realises that Jiya is to busy to notice her. Sami's hurt can't be consoled and is only made worse by her mother's pity-fueled attempts at teaching her the exciting rules of chess.  Sami seizes an opportunity to investigate why her sister doesn't like her anymore but ends up unveiling something much more thrilling instead. Clown is a coming-of-age comedy-drama that explores one of our first interaactions with loneliness.  Can the embrace of others be worth the expense of rejecting ourselves?`,
    
    background: ClownBG,
    directors: { name: "Aarushi Chowdhury" },
    year: "2023",
    country: "Australia",
    duration: "9:43 mins"
  },
  {
    name: "DEPRESSION",
    poster: DepressionPoster,
    synopsis: `This is a motivation story for those who think of committing suicide due to depression.`,
    
    background: DepressionBG,
    directors: { name: "Charanpal Singh" },
    year: "2024",
    country: "India",
    duration: "6:49 mins"
  },
  {
    name: "Dig",
    poster: DigPoster,
    synopsis: `A man is digging a clandestine grave in the middle of the forest at night when he is suddenly discovered.`,
    
    background: DigBG,
    directors: { name: "LINE UP Film Agency" },
    year: "2024",
    country: "Spain",
    duration: "11:38 mins"
  },
  {
    name: "Dreamfinity",
    poster: DreamFinityPoster,
    synopsis: `Dreamfinity (original title: Śnienie) [ENG] Mela - a young woman in a life crisis, deeply longing to return to her previous life where she had a home and everything seemed to work out for her. She meditates, sends out intentions, and firmly believes that a better version of the future is within reach.
    [PL] Mela - młoda kobieta w kryzysowej sytuacji życiowej, bardzo chce powrócić do poprzedniego życia, w którym miała dom i wszystko jej się udawało. Medytuje, wysyła intencje i mocno wierzy w to, że lepsza wersja przyszłości jest na wyciągnięcie ręki.`,
    
    background: DreamfinityBG,
    directors: { name: "Monika Grzybowska" },
    year: "2024",
    country: "Poland",
    duration: "29:08 mins"
  },
  {
    name: "ECHO",
    poster: EchoPoster,
    synopsis: `In city of Melbourne, Four lives intertwine, each grappling with their unique struggles and triumphs, all united by the common thread of inner strength and resilience.

    Varun - is a student who has recently come out as gay. Despite facing social stigma and familial rejection, Varun remains determined to live authentically. His journey is one of courage and self-acceptance as he learns to navigate a world that is often unkind to those who are different.
    
    Priya - an Indian woman married to an Australian expatriate, finds herself caught between two worlds. The cultural differences create tension in her marriage. Her journey teaches her that harmony comes from embracing both her Indian heritage and her new life in Australia.
    
    Giri - is a passionate filmmaker whose projects fail to gain appreciation. Financial pressures mount as he struggles to support his family and maintain his artistic integrity. His story is a testament to perseverance and the belief in one's vision.
    
    Bhanu - a vivacious young woman, faces relentless body shaming from her family and society. Her confidence is constantly undermined by hurtful comments, but Bhanu refuses to let others define her worth. Through self-love and resilience, she embarks on a journey of empowerment through stand up comedy.
    
    As these four individuals navigate their personal battles, they cross paths in unexpected ways. Echo the film underscores the idea that it is within one's mind to decide whether to succumb to life's challenges or rise above them, transforming adversity into strength.`,
    
    background: EchoBG,
    directors: { name: "Sandeep Raj" },
    year: "2024",
    country: "Australia",
    duration: "15 mins"
  },
  {
    name: "F everything but life",
    poster: FEverythingButLifePoster,
    synopsis: `"F Everything but Life" is a short film about Ava, a high school teen grappling with the search for life's purpose. Like many teenagers, Ava faces judgment, peer pressure, school and the trials of first love. She navigates through love, hate, confusion, and life's ups and downs, ultimately realizing that these experiences are integral to the essence of life. Ava learns to embrace the freedom of not taking external opinions and relationships too seriously, while still valuing the importance of living in the present and enjoying each moment. The film is about letting go of superficial concerns and focusing on living fully, because, in the end, it's the experiences that truly matter. So just Live as if there’s no tomorrow and fully embrace your own life.`,
    
    background: FEverythingButLifeBG,
    directors: { name: "L Sara" },
    year: "2024",
    country: "Australia",
    duration: "11:30 mins"
  },
  {
    name: "Father's Footsteps",
    poster: FatherFootstepsPoster,
    synopsis: `Fathers Footsteps offers a glimpse into the lives of two young sisters whose childhood innocence is shattered on a nightly occurence when their abusive father returns have from work.`,
    
    background: FathersFootstepsBG,
    directors: { name: "Matthew Daherty & Simin Dolatkhah" },
    year: "2024",
    country: "Australia",
    duration: "5:25 mins"
  },
  {
    name: "Friendly Fire",
    poster: FriendlyFirePoster,
    synopsis: `Estranged friends, Jake and Josie, find themselves out in nowhere trying to fix their fractured friendship.`,
    
    background: FriendlyFireBG,
    directors: { name: "Gaden Sousa" },
    year: "2024",
    country: "Australia",
    duration: "12:34 mins"
  },
  {
    name: "Good Morning",
    poster: GoodMorningPoster,
    synopsis: `This short film is about a boy who lost his parents at a young age and his parents his ability to look at his and the world in a positive way, to dream about better things. He feels cheated at the world is, he had always been taught that good things happen to good people and is unable to figure out what he did wrong to deserve the loss of his parents. He has lost his ability to think beyond his present helplessness, to dream of something better. He feels abandoned and alone has with life.
    His sister and grandfather try to teach him, to encourage him, to inspire him by talking to him, telling stories of others, or by scolding. But sometimes what a child may need may be something much more than learning, sometimes a child or for the matter, any human wants to feel trusted and believed in. They want to feel safe and once they gey the environment, their talents would nourish. They would start thinking and most importantly, they would start dreaming.`,
    
    background: GoodMorningBG,
    directors: { name: "Anmol Arora" },
    year: "2023",
    country: "India",
    duration: "90 mins"
  },
  {
    name: "IDOL",
    poster: IdolPoster,
    synopsis: `IDOL is a short tale about first generation Australia sister. It is about their sisterhood, their friendship, love for each other , secrets they keep and the grief they hold to. 
    In IDOL, a young girl tries to do something simple yet a herculean task, for her sister just to make her happy. A story about a single event yet unravelled by different perspectives.`,
    
    background: IdolBG,
    directors: { name: "Suhas Pattathil" },
    year: "2024",
    country: "Australia",
    duration: "14:56 mins"
  },
  {
    name: "Kindness Crossing",
    poster: KindnessCrossingPoster,
    synopsis: `In R&F Primary School, Su Pinyi and Zhu Yuxi are classmates in Grade Two. Zhu Yuxi is an excellent student in the class, all doors are the first, and Su Pina play is very unstable, good and bad. One day, the Chinese teacher assigned a composition, asking the students to write a good thing they have done. Can not have thought, Su Pinyi and Zhu Yuxi have written to help grandma over the zebra crossing. According to the usual performance, let the language teacher that Su Pin a must be plagiarized Zhu Yuxi composition. Can Su Pin a not convinced, he took out evidence one by one to refute. With the deepening of the investigation, we found that Su Pinyi seemed to really help the grandmother over the zebra crossing, then, the person who "copied" the composition will be the outstanding student Zhu Yuxi? What is the secret behind this "plagiarism" incident?`,
    
    background: KindnessCrossingBG,
    directors: { name: "YU WANG" },
    year: "2024",
    country: "China",
    duration: "19:15 mins"
  },
  {
    name: "Kiss For Me",
    poster: KissForMePoster,
    synopsis: `Maya excitedly leans into the joy of her first queer relationship, but must confront the seemingly never-ending sexualisation of her identity.`,
    
    background: KissForMeBG,
    directors: { name: "Kirsty Wilson" },
    year: "2024",
    country: "Australia",
    duration: "10:27 mins"
  },
  {
    name: "Lasya's Song",
    poster: LasyaSongPoster,
    synopsis: `A haunting 20 year old melody intertwines the lives of a man and a woman revealing a tragedy of dreams.`,
    
    background: LasyaSongBG,
    directors: { name: "Smithel Dhudala" },
    year: "2024",
    country: "Australia",
    duration: "7:20 mins"
  },
  {
    name: "South Plug: Latitudes",
    poster: LatitudesPoster,
    synopsis: `South Plug presents “Latitudes”, a documentary series that follows the lives of 5 artists from the Latin electronic music scene and Chile as country of origin. From different cities around the world and under their own gaze or lens, we follow them through an intimate journey through their lives. A tribute to the human spirit and its ability to adapt, a window into the reality of an artist.`,
    
    background: LatitudesBG,
    directors: { name: "Juan de Dios Valdivieso" },
    year: "2024",
    country: "Spain",
    duration: "6 mins"
  },
  {
    name: "Lefty",
    poster: LeftyPoster,
    synopsis: `At the tender age of 6, Malhar embarks on a solo journey to visit his aunt in Mumbai for the 5-day Ganesh festival, while his parents are engrossed in their office commitments. In the very first morning, he faces ridicule for holding the idols' holy water (tirtha) in his left hand. The mockery leaves a lasting impact, making him acutely aware of his left-handedness and feeling like an outsider in the societal norm.

    Seething with frustration, Malhar directs his resentment towards his mother, whom he perceives as having neglected to teach him the expected social norms of favoring the right hand. Driven by a sense of vengeance, this determined 6-year-old resolves to transform himself into a right-handed individual, determined to prove his capability to adapt independently.
    
    The innocent journey of self-transformation unfolds with a series of self-inflicted disappointments, unforeseen hardships, and even life-threatening challenges as Malhar endeavors to conform to the right-handed societal expectations, all in the pursuit of acknowledgment and acceptance.`,
    
    background: LeftyBG,
    directors: { name: "Gautam Vaze" },
    year: "2024",
    country: "India",
    duration: "29:47 mins"
  },
  {
    name: "Let Me Out",
    poster: LetMeOutPoster,
    synopsis: `LET ME OUT delves into the life of a young woman, who is trapped in a bleak existence, longing for an escape for her persistent sadness. Fueled by a deep desire to break free from her emotional confinement, she embarks on a mystical journey to search for the answer that lead to her liberation.`,
    
    background: LetMeOutBG,
    directors: { name: "Devanshi Srivastava" },
    year: "2024",
    country: "Australia",
    duration: "14:40 mins"
  },
  {
    name: "LISTEN TO THE SILENCE",
    poster: ListenToTheSilencePoster,
    synopsis: `Maya and her husband misunderstood their son without realising that he was traumatised because of continuous bullying in the school. When they realise the
    truth through Ishan's friend, they lose Ishan, which makes them realise how important it is for parents to listen to their kids even in silence.`,
    
    background: ListenToTheSilenceBG,
    directors: { name: "AUS TAMIL TV" },
    year: "2024",
    country: "Australia",
    duration: "15 mins"
  },
  {
    name: "Mary's Book",
    poster: MarysBookPoster,
    synopsis: `Jessica is haunted by a mysterious masked man after discovering Mary's Book.`,
    
    background: MarysBookBG,
    directors: { name: "Ujjwal Rai" },
    year: "2024",
    country: "Australia",
    duration: "17:03 mins"
  },
  {
    name: "Mind the Cart",
    poster: MindTheCartPoster,
    synopsis: `Mann, a 13-year Pardhi boy, lives with his mother and two siblings. His mother rears goats and does odd jobs to support the family. His thela(pushcart) holds a special place in his heart, like a cherished family memebr. With his trusty pushcart by his side, Mann earns daily wages, has some fun and also tries to study. 
    But one day, a day wich starts like any other day, Mann loses his trusted friend, the pushcart.`,
    
    background: MindTheCartBG,
    directors: [ {name: "Madhu Dhurve"},{name: "Pushpa"} , {name: "Narendra Singh Pardhi"} ],
    year: "2024",
    country: "India",
    duration: "22:19 mins"
  },
  {
    name: "Mirror Mirror",
    poster: MirrorPoster,
    synopsis: `A high profile curator of an art gallery, married for ten years, finds herself drawn to her latest client, a younger, polyamorous, emerging artist. He introduces her to an alternative lifestyle characterised by freedom, creativity and love, a reflection of herself she feels she has abandoned. Feeling stifled and restricted by the confines of her meticulously crafted life, she tries again to discuss an open marriage with her husband. A contemporary re-imagining of the myth of Narcissus, using multiple mediums, that explores self obsession and the havoc that goes with it.`,
    
    background: MirrorBG,
    directors: { name: "Candice Hill" },
    year: "2024",
    country: "Australia",
    duration: "25 mins"
  },
  {
    name: "OPIA",
    poster: OPIAPoster,
    synopsis: `Set in a modern cityscape, two young Graffiti Set in a modern cityscape, two young Graffiti
    Artists walking different paths, evade the law Artists walking different paths, evade the law
    and each other. Finally, through a trick of fate, and each other. Finally, through a trick of fate,
    they collaborate on a piece together, they collaborate on a piece together,
    connecting in an unspoken language. connecting in an unspoken language.
    Love.`,
    
    background: OPIABG,
    directors: { name: "Cosmo Calman" },
    year: "2023",
    country: "New Zealand",
    duration: "11:39 mins"
  },
  {
    name: "Papa Aur Smartphone",
    poster: PapaAurSmartphonePoster,
    synopsis: `Everything in this world comes down to a circle. One of them is the relationship between a father and a son. Just like a father answers every question of a curious child with love, there comes a point in life where a father has so many questions which needs answering from his own grown up kid. Papa and smart phone is one such story where Sunil who is working with a multinational company in Mumbai gives a smart phone to his father who has visited him for the first time. As sunil's father has just got his new gadget, a smart phone, a lot of questions pops put, one of them is the question of balance.`,
    
    background: PapaAurSmartphoneBG,
    directors: { name: "Raj Sisodiaa" },
    year: "2023",
    country: "India",
    duration: "26 mins"
  },
  {
    name: "PAROLE",
    poster: ParolePoster,
    synopsis: `Prisoner 1488 awakens in a white concrete prison cell where he is addressed by his parole officer. Officer informs him that inmates
    on parole are obliged to reside in a white room for three days prior to their interview to obtain a comprehensive examination of
    their character. The room is as white as snow with one strange white button in the center of the room whose purpose is to boom
    stinging sound in a soundless room. As the assessment progresses with each passing day, the stillness descends over him. Due to
    suffering from sensory deprivation and lack of sleep, 1488 begins hallucinating, a clone of himself, his darker side. To regain
    control, 1488 hits the button as a final attempt to escape his horror. The parole Officer carefully watches every move through
    surveillance cameras. Having survived the white torture, the parole interview begins with standard discussions on redemption and
    methodology but rapidly moves to increasingly personal inquiries. With seemingly provocative questions and unusual techniques
    bordering on sadistic, the parole officer (Andre) prods and pushes the prisoner (1488) past his comfort zone. Will 1488 be able to
    recognize his actual self and demonstrate the mental resilience fortitude necessary in today’s day and age?`,
    
    background: ParoleBG,
    directors: { name: "Param Walia" },
    year: "2022",
    country: "Australia",
    duration: "14:40 mins"
  },
  {
    name: "Pillow Talk",
    poster: PillowTalkPoster,
    synopsis: `Jill just wants to get a good night's sleep before a big presentation, but it's not in the cards.`,
    
    background: PillowTalkBG,
    directors: { name: "Frances Chang" },
    year: "2024",
    country: "United States",
    duration: "7:17 mins"
  },
  {
    name: "PROMOTION",
    poster: PromotionPoster,
    synopsis: `Childhood friend was showing rich arrogance of his wealth.`,
    
    background: PromotionBG,
    directors: { name: "Charanpal Singh" },
    year: "2024",
    country: "India",
    duration: "11:29 mins"
  },
  {
    name: "Reel Terror",
    poster: ReelTerrorPoster,
    synopsis: `An isolated film obsessive discovers an exceptional reel that could complete his collection, for better or for worse.`,
    
    background: ReelTerrorBG,
    directors: { name: "Ted Bennett" },
    year: "2024",
    country: "United Kingdom",
    duration: "14:20 mins"
  },
  {
    name: "SAINT AND GHEEVARGHESE",
    poster: SaintAndGheevarghesePoster,
    synopsis: `This short film narrates the perspectives of two generations towards life and their inter relationships.`,
    
    background: SaintAndGheevargheseBG,
    directors: { name: "Gathish Abraham" },
    year: "2024",
    country: "Australia",
    duration: "28:14 mins"
  },
  {
    name: "SARDARJI AND ALCOHOL",
    poster: SardarjiAndAlcoholPoster,
    synopsis: `SARDARJI AND PUNJABI DRINK ALL THE LIQUOR WHEN THE TRUTH CAME TO LIGHT?`,
    
    background: SardarjiAndAlcoholBG,
    directors: { name: "Charanpal Singh" },
    year: "2024",
    country: "India",
    duration: "5:59 mins"
  },
  {
    name: "SCARS",
    poster: ScarsPoster,
    synopsis: `A tennis sensation struggles to deal with the fall of her career but finds her lost hope in the shadows of her world.`,
    
    background: ScarsBG,
    directors: { name: "Ujjwal Raj" },
    year: "2024",
    country: "Australia",
    duration: "7:46 mins"
  },
  {
    name: "Sole Sista",
    poster: SoleSistaPoster,
    synopsis: `Chelsea navigates the feeling of being different as the only black girl with curly hair in school. Whilst she does not feel excluded, she also does not feel her diversity is embraced. After experiencing micro-aggression after micro-aggression. Chelsea decides life would be easier if she looked just like them.`,
    
    background: SoleSistaBG,
    directors: { name: "Emerie Boone" },
    year: "2024",
    country: "Australia",
    duration: "6:22 mins"
  },
  {
    name: "The Communion",
    poster: TheCommunionPoster,
    synopsis: `Bill, a young man in his mid-30s, often arrives home to find his family’s contributions and changes around his apartment. Her parents, Bill’s grandparents, have their own, somewhat strict, religious doctrines that they don’t hesitate to impose into Bill’s life and decor without consent. They also know how to coerce their daughter into to doing what they want and this brings conflict into her home life with Bill.
    Bill’s now faced with confronting his mother, yet again or else.
    “Have you had family beliefs imposed on you without being given a chance to think for yourself?’"`,
    
    background: TheCommunionBG,
    directors: { name: "Panayiota Harmantzis" },
    year: "2024",
    country: "Australia",
    duration: "8:46 mins"
  },
  {
    name: "The Cut",
    poster: TheCutPoster,
    synopsis: `A 30 years old actress Kate has stayed unemployed for almost 2 years but she always pretends to be well. After accidentally meeting her high school classmate Jack in the cafe, she finally learns to embrace reality, stop lying, and make efforts to her acting dream.`,
    
    background: TheCutBG,
    directors: { name: "Ian Liu" },
    year: "2024",
    country: "Australia",
    duration: "12 mins"
  },
  {
    name: "The Garmentologist",
    poster: TheGarmentologistPoster,
    synopsis: `In " Bostro Holeo Sotti ( The Garmentologist)," a quirky young man MONOTOSH discovers he can communicate with clothing, uncovering secrets woven into fabric. When he stumbles upon evidence of his father's affair with a tennage gilr, his world unravels.Desperate to confront his father, he finds himself betrayed and manipulated, leading to a harrowing confrontation that leaves him shattered. Branded as delusional, he's confoned to a mental asylum where he battles to prove his sanity amidst disbelief and deception.`,
    
    background: TheGarmentologistBG,
    directors: { name: "Debajit Banerjee" },
    year: "2024",
    country: "India",
    duration: "20:36 mins"
  },
  {
    name: "The Graffiti Society: A Short Film",
    poster: TheGraffitiSocietyPoster,
    synopsis: `After a suspicious death is ordained as a suicide, a young, reclusive artist must use illegal street art to disclose a dark truth and stand up for his bereaved friend.`,
    
    background: TheGraffitiSocietyBG,
    directors: { name: "Oliver Smuhar" },
    year: "2024",
    country: "Australia",
    duration: "11:17 mins"
  },
  {
    name: "The Hero Of Adelaide",
    poster: TheHeroOfAdelaidePoster,
    synopsis: `When an agent breaks into the home of none other then Dr Alfred in an attempt to steal some valuable information. But what he stumbles on changes everything.`,
    
    background: TheHeroOfAdelaideBG,
    directors: { name: "Zakariya Muhammad Ilias" },
    year: "2024",
    country: "Australia",
    duration: "5 mins"
  },
  {
    name: "The Murder",
    poster: TheMurderPoster,
    synopsis: `A 4th wall breaking comedy about a woman who gets murdered.`,
    
    background: TheMurderBG,
    directors: { name: "Media None" },
    year: "2024",
    country: "Australia",
    duration: "9:51 mins"
  },
  {
    name: "The Night's Takings",
    poster: TheNightsTakingsPoster,
    synopsis: `A man steals the night's takings from the bar he works at, to get at back his employer who has been underpaying him for so long.`,
    
    background: TheNightsTakingsBG,
    directors: { name: "Ahmed Al Aasi" },
    year: "2024",
    country: "Australia",
    duration: "8:14 mins"
  },
  {
    name: "The Pod",
    poster: ThePodPoster,
    synopsis: `When Saurabh's young daughter Kriti disappears, his life shatters.Saurabh looks back at Kriti's sci-fi manuscript, filled with vivid worlds. Desperate and grasping for any connection to her, he begins to immerse himself in her story.
    Saurabh discovers that Kriti's sci-fi world holds clues to her whereabouts. As he delves deeper, the lines between reality and imagination blur for him. Guided by a sense of hope and driven by love, Saurabh navigates the surreal twists and turns of Kriti's narrative. Along the way, he must confront his own fears and regrets, as well as unravel the enigma of her disappearance. What he discovers will challenge his understanding of reality and reveal the profound bond between father and daughter.`,
    
    background: ThePodBG,
    directors: { name: "Naveen Shukla" },
    year: "2024",
    country: "Australia",
    duration: "9:52 mins"
  },
  {
    name: "The Rise of the Wogolo",
    poster: TheRiseOfTheWogoloPoster,
    synopsis: `Vito, is just an every day, regular guy. Then after a disastrous day at work in which he loses his job, he finds himself in a hospital where a small mix up in medication allows him to see a vision of his future ....
    To become .... a ""wogolo"" ...
    He's like a gigalo, but with a specific clientele!`,
    
    background: TheRiseOfTheWogoloBG,
    directors: { name: "Darren Hawkins" },
    year: "2024",
    country: "Australia",
    duration: "9:07 mins"
  },
  {
    name: "The Thong",
    poster: TheThongPoster,
    synopsis: "N/A",
    
    background: TheThongBG,
    directors: { name: "Aarya Hatkhambkar" },
    year: "2024",
    country: "Australia",
    duration: "8:49 mins"
  },
  {
    name: "The Wandering Island",
    poster: TheWanderingIslandPoster,
    synopsis: "Federico and Carlos, grandfather and grandson, live together on a small, run-down banana farm. The banana business is beginning to be unsustainable for Federico and he will welcome a proposal to purchase the adjacent land by an investment fund. Carlos, oblivious to the family's economic situation, is only tormented by the reasons for the absence of his father, a sailor who disappeared in search of a wandering island.",
    
    background: TheWanderingIslandBG,
    directors: { name: "Pablo Borges" },
    year: "2024",
    country: "Spain",
    duration: "18:11 mins"
  },
  {
    name: "Vanavaa",
    poster: VanavaaPoster,
    synopsis: `This is short film focuses on the major issue of our times in India. Detention camps in India is no longer a fairy tale, its a harsh reality. This short film tries to give glimpese of such reality. This film is against CAA-2019 & NRC act.`,
    
    background: VanavaaBG,
    directors: { name: "Mr. Anup Nilkumar Jatratkar" },
    year: "2023",
    country: "India",
    duration: "19:56 mins"
  },
  {
    name: "ZAAG",
    poster: ZAAGPoster,
    synopsis: `After Samara consents to marry Arhan on their engagement night, Samara sees a darker side of him which brings in many doubts about their relationship in her mind. She does anything an option for her to get lucidity in her relationship. However, in the process of searching for her answers and gaining clarity, she witnesses something that transforms her.`,
    
    background: ZAAGBG,
    directors: { name: "Sanah Oberoi" },
    year: "2024",
    country: "India",
    duration: "26:02 mins"
  },
  {
    name: "Born Again",
    poster: BornAgainPoster,
    synopsis: `Born Again is a short film that tells the`,
    
    background: BornAgainBG,
    directors: { name: "Sanish Sam" },
    year: "2024",
    country: "Australia",
    duration: "20:24 mins"
  }
];

export const under18Films: Film[] = [
  {
    name: "ERAN",
    poster: ERANPoster,
    synopsis: `This is a documentary about the birth of a brand to express the problem of body shame. Erin was treated differently as a child because of her size, and the consequences of excessive weight loss kept coming back to bite her. In this documentary, she follows how she discovered the pressure that size clothing stores put on teenage girls, and how she founded ERAN, a full-size brand for girls. In the process of building the brand, she encountered many difficulties, such as financial pressure, study pressure, etc., she constantly overcame these difficulties and officially announced the brand.`,
    
    background: ERANBG,
    directors: { name: "Gavin Lee" },
    year: "2024",
    country: "China",
    duration: "27 mins"
  },
  {
    name: "Foundation",
    poster: FoundationPoster,
    synopsis: `After experiencing the confronting realities of cow farming as a child, Anthony Walsgott abandoned his life as a lawyer to purchase a pristine 120-acre block on Queensland’s Mary River—a place which is now home to Australia’s largest cow sanctuary.`,
    
    background: FoundationBG,
    directors: { name: "Jack Hinz" },
    year: "2024",
    country: "Australia",
    duration: "6:59 mins"
  },
  {
    name: "Who Are We",
    poster: WhoAreWePoster,
    synopsis: `A young girl is caught up in her past and a race against time begins.

    A race not everybody survives.
    
    A film about what it means to be human.`,
    
    background: WhoAreWeBG,
    directors: { name: "Susanna Prummer" },
    year: "2024",
    country: "Austria",
    duration: "23:29 mins"
  },
  {
    name: "WHERE I AM",
    poster: WhereIAmPoster,
    synopsis: `After Mark wakes up in the middle of nowhere, he must try to survive. Over the course of several days he comes across memories and hallucinations which reveal his true self. A loving wife, kid and a happy life? Is Mark a family man or is there more to the story?`,
    
    background: WhereIAmBG,
    directors: [
      { name: "Jason Kingsley"}, {name: "Ben van Leen"}
    ],
    year: "2023",
    country: "Australia",
    duration: "10:18 mins"
  },
  {
    name: "Melody",
    poster: MelodyPoster,
    synopsis: "Melody and her friend Ethan share a special bond. When tragedy strikes, Ethan's guilt compels him to seek absolution in a unique way. Melody is written, produced, directed, edited, scored, coloured and starring 12 year-old Harrison Thomas.",
    
    background: MelodyBG,
    directors: { name: "Harrison J Thomas" },
    year: "2024",
    country: "Australia",
    duration: "6:58 mins"
  },
  {
    name: "Tonyboy",
    poster: TonyboyPoster,
    synopsis: `no synopsis`,
    
    background: TonyboyBG,
    directors: { name: "Jay Lau" },
    year: "2024",
    country: "Australia",
    duration: "5:52 mins"
  }
];

export const allFilms = [
  ...cinemaMovies,
  ...featureFilms,
  ...internationalAnimation,
  ...internationalDocumentary,
  ...shortFeatureFilms,
  ...under18Films
]

const film_ids = Array.from({ length: allFilms.length }, (_, i) => i + 1);
// console.log(film_ids);

export const allFilmsWithIds = allFilms.map((film, index) => ({
  ...film,
  filmId: film_ids[index],
}));

// console.log(allFilmsWithIds);
// console.log(allFilmsWithIds[125])

// let filmId = 0;

// export const allFilms = [
//     ...cinemaMovies,
//     ...featureFilms,
//     ...internationalAnimation,
//     ...internationalDocumentary,
//     ...shortFeatureFilms,
//     ...under18Films
// ].map(film => ({...film, filmId: filmId++}));



export const featureFilmNominations: Film[] = [
  {
    name: "Arcadian",
    poster: ArcadianPoster,
    synopsis: `In a near future, life on Earth has been decimated. Paul and his twin teenage sons, Thomas and Joseph, have been living a half-life tranquility by day and torment by night. When the sun sets, ferocious creatures of the night awaken and consume all living souls in their path. One day, when Thomas doesn't return home before sundown, Paul chooses to leave the safety of their fortified farm to find him before the creatures arrive. Just as he finds his boy, a nightmarish battle ensues and Paul is gravely wounded. Now the twins must devise a desperate plan for surviving the coming night and use everything their father has taught them to keep him alive.`,
    background: ArcadianBG,
    directors: { name: "Benjamin Brewer" },
    year: "2024",
    country: "United States",
    duration: "92 mins"    
  },
  {
    name: "Drive",
    poster: DrivePoster,
    synopsis: `To escape from this drive, I must start the live show.
    Yuna is a famous YouTube influencer but always looks down on others. She is invited to a cosmetic brand launching party but things don’t go the way she had wanted. Tired and extremely stressed, she takes couple of shots and falls asleep after calling a designated driver. When Yuna opens her eyes, she realizes she’s trapped in the trunk of her car. The kidnapper asks her for ransom, not just cash but to broadcast a ‘kidnapping live-stream’ and beg for money from the viewers. But everyone thinks she’s playing out a self-fabricated scenario…
    `,
    background: DriveBG,
    directors: { name: "Park Dong-hee" },
    year: "2024",
    country: "South Korea",
    duration: "90 mins"    
  },
  {
    name: "Just A Farmer",
    poster: JustAFarmerPoster,
    synopsis: `Just a Farmer is a powerful independent film that sheds light on the often-overlooked mental health challenges faced by farmers and rural communities. Through the story of a family navigating life after tragedy, the film highlights the importance of empathy, support, and connection in overcoming adversity. By portraying the authentic experiences of those in the agricultural sector, Just a Farmer encourages viewers to engage in meaningful conversations about mental health and fosters a greater understanding of the vital role farmers play in our society. Ultimately, it serves as a call to action for communities to come together and support one another.`,
    
    background: JustAFarmerBG,
    directors: { name: "Sinon Lyndon" },
    year: "2024",
    country: "Australia",
    duration: "142 mins"
  },
  {
      name: "Treasure",
      poster: TreasurePoster,
      synopsis: `1990 - Ruth, a neurotic businesswoman from New York, takes her father Edek, a charmingly stubborn Holocaust survivor on a journey to Poland to make sense of her family's past. Edek's hesitation to confront his past and his often odd demeanor cause more than just one dispute between him and his daughter. The trip unfolds into a story that encapsulates the emotion of discovery and drama with humor. Based on the best-selling autobiographical novel TOO MANY MEN by New York-based writer Lily Brett.`,
      
      background: TreasureBG,
      directors: { name: "Julia von Heinz" },
      year: "2024",
      country: "Poland",
      duration: "111 mins"
    },
    {
      name: "Love Lies Bleeding",
      poster: LoveLiesBleedingPoster,
      synopsis: `Lou (Kristen Stewart) works at a hard-core gym, unclogging toilets and being pressured by Daisy (Anna Baryshnikov) for a date.

      Jackie (Katy O'Brian) arrives in town and has sex with JJ (Dave Franco) in order to secure a job at a gun range. She has nowhere to stay, so she sleeps and works out underneath a nearby bridge.
      
      Lou visits her sister, Beth (Jena Malone), whose house is filled with children and suffers clear signs of domestic abuse from her husband, JJ. He leaves for work where he shows Jackie around and introduces her to the owner (Ed Harris).
      
      Jackie comes to work out at the gym, and Lou is immediately smitten by her. Her fantasies are interrupted by two FBI agents asking about her father, who she says she hasn't talked to in years. Lou and Jackie chat outside, but are interrupted by two patrons asking Jackie out on a date. She refuses, then punches one in the face after he attempts to grab her. He hits her back and Lou rushes her inside. They talk about Jackie's dream to go to Vegas to compete in the finals for a bodybuilding competition, so Lou offers her a stash of steroids from her office. While Jackie is initially reluctant, she agrees and Lou injects her with a vial. The two are clearly attracted to each other and begin making out, then leave for Lou's apartment to have sex.
      
      The following morning, Lou laments that Jackie's working at the gun range, as her estranged dad, Lou Sr., owns it. Lou agrees to let Jackie stay with her, leading to a period of love, sex, and steroid injections. Back at the gun range, Lou Sr. runs a criminal operation smuggling guns to Mexico and teaches Jackie how to shoot a gun.
      
      Lou and Jackie go on an awkward double date with JJ and Beth, who has another set of bruises and cuts. Lou follows JJ to the bathroom to threaten him, but he reveals that he slept with Jackie and suggests that she's probably just using Lou for the gym and housing. They fight on the way home, but speed away together after Lou spots the FBI agents.
      
      The following day, Lou gets a call that Beth is in the hospital with severe face and head injuries. Lou is furious, but the police will not arrest JJ unless Beth agrees to press charges, which she always refuses to do. Upon seeing Lou sobbing, Jackie's muscles begin to swell and she flies into a rage and drives to Beth's house to violently murder JJ. Lou spots her truck in front of Beth's house and enters to find JJ's body and a catatonic Jackie. After being initially horrified, Lou sets to work cleaning up the mess and puts JJ in the back of his own car. On the way to dispose of the body, Daisy spots Lou and knocks on the window of JJ's car to chat. The girls push the car into a large gorge, then set it on fire, sending smoke billowing into the sky. Lou assures Jackie that there are other things in the gorge that will point the police to someone else, so all they need to do is stay put in the apt. While Lou cleans up Beth's house, Jackie takes many vials of steroids and breaks out of the house to go work out, which infuriates Lou. Still hyped up on steroids, Jackie headbutts Lou and hitchhikes to Vegas for the bodybuilding competition. While her individual performance originally goes well, she has a panic attack and vomits on the stage. When she sees other competitors laughing at her, she viciously attacks them and is arrested.
      
      Lou Sr. confronts Lou at the hospital about leading police to the gorge, as it has attracted attention to the bodies that he has dumped down there. When Lou arrives home, she runs into Daisy who begins asking questions about why she was driving JJ's car that night. In order to silence her, Lou has sex with Daisy, who then answers the phone when Jackie calls from jail and tells her not to bother them. Instead, Lou Sr. bails Jackie out of prison and claims he can help her get out of this mess. Lou has continued seeing Daisy to find out what she knows, but when they return to Lou's house, Jackie shoots Daisy in the face, then runs. Lou hides the body behind the couch when FBI agents arrive to continue asking questions about JJ, who was one of their biggest informants on Lou Sr. They reveal they know that Lou used to be Lou Sr.'s right-hand man, so she'd have enough information to put him away. Once they leave, Lou Sr. calls to tell Lou that he has Jackie and plans to pin the double homicide on her. Lou threatens to reveal everything to the FBI if he does that, so he sends over a corrupt policeman to kill her. She knocks him out, then goes to rescue Jackie, but runs into Beth who attacks her for killing JJ. Jackie is tied up in the garage, and after initially running from Lou, they embrace and make up. Lou sends Jackie off towards the car, while she goes to confront her dad, but he arrives and shoots Lou in the leg and prepares to kill her. Jackie, empowered by her love for Lou, appears as a 60ft. woman and pins Lou Sr. to the ground. Lou puts a gun in his mouth, but decides to leave him for the incoming police. The two girls drive into the desert, but Lou hears thumping from the bed of the truck and discovers that Daisy is still alive. As Jackie sleeps, Lou strangles Daisy and drags her body into the desert.`,
      
      background: LoveLiesBleedingBG,
      directors: { name: "Rose Glass" },
      year: "2024",
      country: "United States",
      duration: "104 mins"
    },
    {
      name: "Decoded",
      poster: DecodedPoster,
      synopsis: `Life is an ongoing journey of unraveling mysteries. The story commences with an interview, as the interviewees piece together the legendary life of Rong Jinzhen through recollections. Turning back the clock to the early 20th century, during the chaos of warlords and political unrest, the twelve-year-old Rong Jinzhen displayed exceptional mathematical talent. At the age of sixteen, he was admitted to N University, where his mentor, Professor Hayes, recognized and cherished his abilities. Their relationship evolved into a deep friendship. Upon discovering Rong Jinzhen's extraordinary talent in code-breaking, Hayes cautioned him against becoming overly fixated on "decode." With the outbreak of war, the two sympathetic individuals had to choose vastly different paths. Hayes returned to his homeland, responsible for "creating" codes, while Rong Jinzhen joined the 701 Unit, specializing in "deciphering" codes. 
      At that time, the looming concern for everyone at 701 was the enemy's "Purple Code." Some even went mad due to the inability to find a solution. In less than a year, Rong Jinzhen successfully cracked the "Purple Code." However, faced with ensuing love, he chose to delve deeper into the path of "decode." Through correspondence with Hayes, Rong Jinzhen gradually realized that his former mentor had become his adversary. The destinies of the two ultimately intertwined, and the final showdown between master and disciple would unfold around a code more advanced, sophisticated, and weighty than the "Purple Code" – the elusive "Black Code."
      `,
      
      background: DecodedBG,
      directors: { name: "Sicheng Chen" },
      year: "2024",
      country: "China",
      duration: "156 mins"
    },
    {
        name: "The Archíes",
        poster: ArchiesPoster,
        background: ArchiesBG,
        synopsis: `Mr. Hiram Lodge (Alyy Khan) returned to Riverdale, and he planned to change the entire town by building new plazas and hotels. Mr. Lodge was a true-blue capitalist, and all he cared about was benefiting from the deal, even if it meant doing something that the residents didn't want. Mr. Lodge had a secret meeting with Mr. Dawson (Vinay Pathak), a member of the local council, and he told him the plans he had for Riverdale. Mr. Lodge's wife Hermione (Kamal Sidhu) proposed that the hotel that they were planning to build shouldn't be situated on the outskirts of the town. She wanted it to be located in the heart of the city, and she proposed that it should be built where the historic Green Park was located. Mr. Dawson knew that it was going to be an uphill battle for him, as destroying Green Park was not something that people would like. Since the time John Riverdale leased the land from King Sarna in 1914, children planted trees in Green Park every year. Riverdale was an Anglo-Indian community and most people stayed even after India gained its Independence. Green Park was deeply rooted in the town's tradition and history, but still, Dawson told Mr. Lodge that he would try his best to get the motion passed as the latter decided to fund his campaign for the election of the District Contractor.
    
        Dawson needed only 5 votes to pass the motion and give the tender to Mr. Lodge's company to build the hotel. He knew that, by hook or by crook, he would have to lure in the council members to do as they wanted. The residents were totally unaware of this plan to destroy a place that was no less than a local heritage. But before the construction of the hotel, Hiram had planned to make a plaza where Pam's salon, Suzie's flower shop, and Hal's bookshop were located. He contacted the owners of the place, who in turn gave notice to their tenants to close their shops or match the amount of rent that Mr. Lodge was going to pay. Veronica is Hiram's daughter and is best friends with Betty. Veronica gives a party at her house, inviting Archie, Jughead, Dilton, Reginald "Reggie" Mantle (Vedang Raina), Moose (Rudra Mahuvarkar), Midge (Santana Roach) and others. Archie doesn't like that Reggie treats Dilton like his man-servant. Reggie has a crush on Veronica, but she only has eyes for Archie. Ricky (Luke Kenny) is Reggie's father and is the editor of the local Gazette.
    
        Betty's (Khushi Kapoor) father, Hal Cooper (Satyajit Sharma), realized that he would have to shut down his bookshop as he didn't have the money to pay his landlord. Betty blamed her best friend Veronica (Suhana Khan) for it, though the latter had no clue that her father was planning to do something like that. Betty claims that Veronica's father does not care for the people of the town and says the same things for Veronica as well. Veronica went to her mother, and she made sure that Hal got a job in the new bookstore in town.
    
        This was the sort of relationship Veronica Lodge and Betty Cooper shared. Their common love interest was Archie Andrews (Agastya Nanda), but the feelings they had for him never created a rift between them. After Veronica came back to Riverdale, Archie was confused, and he did something that he shouldn't have done. He double-dated Betty and Veronica for quite some time, though Jughead Jones (Mihir Ahuja) time and again told him that he shouldn't be doing that. Ethel (Aditi "Dot" Saigal) has a crush on Jughead who works at Pop Tate's as a waiter. Ethel warns Betty that Archie is two timing her, but she refuses to have any doubts on Archie and believes that he will change. Archie, Betty, and Veronica were on a mini vacation with their friends when Betty saw Archie and Veronica getting intimate with each other. This was also the vacation where Reggie admits that he knows that Dilton is in love with him.
    
        Just a few days ago, Betty had kissed Archie, and she believed that he, too, had feelings for her, but now, after seeing what he did, she didn't know whether he was just confused or toxic enough to cheat on both of them. Betty's problem was that no matter how angry she was with Archie, the moment he came and apologized to her, she couldn't help but leave behind all her anger and agree to whatever he said. Betty was smitten by him, but one day, she decided to tell Veronica the truth, as it was eating her up from within. The moment Veronica got to know that Archie had kissed Betty, she was furious, as she hadn't imagined that Archie could do something like that. The girls caught Jughead and forced him to tell them the entire truth. Jughead spilled the beans and told them how Archie dedicated a song to both of them and how he said that he loved them equally. Both the girls went to confront Archie and told him that he should immediately stop doing things that created a rift between them. They both asked him to just be their friend, as they valued their relationship way too much.
    
        From the very beginning, Archie wanted to go to London to study, and he knew that he wouldn't have any problems as his uncle Ben (Farhan Akhtar) was living here. Archie's parents, Fred (Suhaas Ahuja) and Mary (Tara Sharma), didn't like the fact that their son didn't want to stay back at Riverdale, but when they saw the kind of obsession he had for going abroad to pursue his studies, they, too, decided to let him do what he wanted. But then, very soon, the news that Mr. Hiram Lodge was going to destroy Green Park became known to the general public, and Archie had a change of heart. He felt as if he were abandoning his friends for his own selfish interests. Archie always believed that the grass was greener on the other side, but for the first time, he realized that it was greener at the place where it was watered. He felt that he owed a lot to Riverdale, and he couldn't leave the town to its fate when the time came. So, Archie took the hard decision to not go abroad and decided to stop the unsympathetic capitalists from destroying the park.
    
        Though the council had 5 votes in favor of building the hotel in the park, there was another hidden clause that could stop them, which Archie and his friends hadn't known about for a very long time. One of the council members who wasn't happy with the state of things told the kids that if they got some 4500-odd signatures from the residents opposing the construction of the hotel, then Mr. Lodge would have no option but to stop. Reggie writes an article against the council proposal, but Ricky refuses to print it since Hiram Lodge is the biggest advertiser in the Gazette newspaper and without that money, the paper would not survive. Reggie and Archie suspect Veronica of being the insider in their group who is revealing their strategy to Hiram Lodge. Veronica confronts her father, who says that his money is what pays for her lavish lifestyle. But Veronica says that her friends hate her because of his actions. Eventually the group figures out that it was Ricky's PA who was the insider and not Veronica.
    
        Mr. Dawson tried his best to prevent the teenagers from getting the signatures, but Archie and his friends were relentless. They went door to door, organized a fair on the park, used Dilton's (Yuvraj Menda) radio to spread the information, and did whatever they could to awaken the conscience of the people.
    
        The citizens of Riverdale voted against the construction, and Mr. Lodge had to inevitably stop. His own daughter was a part of the protest, and though he knew that he wouldn't be able to make as much profit as he had dreamed of, he was happy that he lost to his own daughter and that she had the determination to stand for what she believed in. It all ended well for Archie and his gang, and their conflict against the establishment made them learn some major life lessons. Not only did they save the environment and the natural habitat, which served as a home for so many species, but they also realized that not everything could be bought with money.`,
        
        directors: { name: "Zoya Akhtar" },
        year: "2023",
        country: "India",
        duration: "141 mins"
    },
    {
      name: "Kingdom of the Planet of the Apes",
      poster: KingdomOfThePlanetOfTheApesPoster,
      background: KingdomOfThePlanetOfTheApesBG,
      synopsis: `Many generations after Caesar's death, apes have established numerous clans, while most humans have become feral-esque. Noa, a young chimpanzee from a falconry-practicing clan, prepares for a coming-of-age ceremony by collecting eagle eggs with his friends Anaya and Soona. However, a human scavenger follows Noa home and inadvertently cracks his egg during a scuffle before fleeing. While searching for a replacement egg, Noa encounters a group of ape raiders using electric weapons. As Noa hides from them, the apes follow his horse back to his clan. Noa hurries home to find his village burning; the raider leader Sylva kills Noa's father Koro after dropping Noa from a high platform.
        Left for dead, Noa awakens, discovering that his clan has been abducted. He buries Koro and sets out to rescue his clan. On his journey, he is joined by Raka, an orangutan who tells Noa about Caesar's teachings. The apes notice they are being followed by the human scavenger; Raka offers her food and a blanket, naming her Nova. When the trio encounters a group of feral humans, Sylva's raiders suddenly attack. Noa and Raka rescue Nova who, to their surprise, can speak. She reveals that her name is Mae and that the raiders took Noa's clan to a beachfront settlement outside an old human vault. As they cross a bridge on their way to the settlement, they are ambushed by Sylva. In the ensuing fight, Raka saves Mae from drowning but is swept away by the rapids. Noa and Mae are captured and taken to the apes' settlement.
        Noa reunites with his clan and is introduced to the apes' self-proclaimed king, Proximus Caesar. Proximus has enslaved other clans, forcing them to work on opening the vault so he can access the human technology locked inside. Proximus invites Noa to dinner along with Mae and Trevathan, a human prisoner who is teaching Proximus about the old human world. Proximus believes that Noa's intelligence could help open the vault and warns him that Mae only has her own agenda. Noa confronts Mae, demanding the truth in exchange for his help. Mae discloses knowledge of a hidden entrance to the vault and says that a mysterious "book" capable of restoring speech to humanity is inside. Noa agrees to help her enter the vault, hoping to destroy Proximus' settlement and lead his clan back home. Noa, Mae, Soona, and Anaya secretly plant explosives around the levee that surrounds the settlement. Trevathan catches them and intends to warn Proximus, but Mae strangles him to death.
        The group enters the vault, finding a stockpile of weapons and Mae's "book", which is actually a satellite deciphering key. The apes discover children's picture books depicting humans as the once-dominant species and apes in cages at a zoo. As the group makes their way out of the bunker, they are confronted by Proximus and his tribe. Lightning, one of Proximus' lieutenants, threatens to kill Soona, but Mae kills him with a gun she found before triggering the explosives, flooding the bunker with the apes inside. Mae flees the settlement while the apes climb to higher ground through the bunker. Noa is pursued by Sylva, but manages to trap and drown him. Noa escapes the bunker with his clan, but Proximus attacks him. Noa leads his clan in summoning their eagles to attack Proximus and send him falling off a cliff into the sea.
        As Noa's clan returns to rebuild their home, Mae arrives to tensely bid farewell to Noa. She explains that she destroyed the bunker and prevented the apes from accessing its weapons because they were created by humans and thus should only be used by humans. Noa then argues that if weapons and technology are only for humans, it suggests that nothing belongs to the apes, questioning whether apes and humans can co-exist peacefully given Mae's fundamental disparity. As Noa takes Soona to look through a telescope he found on his journey, Mae travels to a human settlement at a satellite base, which is kept quarantined from the outside world. Mae delivers the decipher key, allowing the humans to reactivate the satellites and successfully contact other humans worldwide.`,
      
      directors: { name: "Wes Ball" },
      year: "2024",
      country: "Australia",
      duration: "145 mins"
    },
    {
      name: "Force of Nature: The Dry 2",
      poster: ForceOfNaturePoster,
      background: ForceOfNatureBG,
      synopsis: "Five women participate in a hiking retreat but only four come out the other side. Federal agents Aaron Falk and Carmen Cooper head into the mountains hoping to find their informant still alive.",
      
      directors: { name: "Robert Connolly" },
      year: "2023",
      country: "Australia",
      duration: "120 mins"
    },
    {
      name: "Gadar 2",
      poster: Gadar2Poster,
      background: Gadar2BG,
      synopsis: `1954 Tara Singh elopes to India with Sakeena and Jeete following which Ashraf Ali is termed traitor and hanged to death by Major General Hamid Iqbal a victim of partition who hates Indians.Seventeen years later in 1971 Hamid still lives with a hope that someday he will revenge on Tara Singh who put his army to shame years back.Tara Singh now lives a happy life in India with Sakeena and Jeete and runs a transport company,tensions are erupting between the two countries on border and there are predictions of war.Pakistani army unintentionally attacks Ram Tekdi where Indian army falls short of arms and Tara Singh's company is given the job to provide them arms. During the cross firing Hamid notices Tara Singh and is ready for his revenge he explodes a weapon on the Indian border where Tara Singh and few Indian soldiers are held captive, seeing his mother Sakeena going in depression Jeete decides to travel to Pakistan to get back his father.Jeete meets Gullu old friend of his father and Sakeena's uncle Abdul in Pakistan who help him get a job in hotel of Qurban Khan who provides food to jail during Eid.Jeete wins their heart and his daughter Muskaan falls in love with him on the day of Eid he enters the prison but finds that his father was never captured by them.Tara Singh returns home after surviving the attack and finds about Jeete going to Pakistan and decides to get him back.Jeete is turned as traitor in the country and Hamid forces Muskaan to testify against him. Tara Singh arrives to save him creating a similar situation he faced years back with the Pakistani army.`,
      
      directors: { name: "Anil Sharma" },
      year: "2023",
      country: "India",
      duration: "170 mins"
    },
    {
      name: "Monkey Man",
      poster: MonkeymanPoster,
      background: MonkeymanBG,
      synopsis: `In a forest village in India, Kid lives with his mother Neela and is inspired by tales of Hanuman she teaches him. Baba Shakti, a ruthless spiritual guru in the nearby city of Yatana, sends Rana Singh, the corrupt police chief, to force out the villagers and acquire their land. The village is massacred, but Neela is able to hide Kid before Rana kills her and sets her body on fire. Kid's futile attempts to save her leave his hands terribly burned and scarred.
          Years later, Kid earns a living in Yatana as a monkey-masked fighter at Tiger's Temple, an underground boxing club, where he is incentivised to lose. Kid intends to exact revenge on Baba, now an influential political figure, and Rana, who frequents Kings, a luxury brothel run by Queenie Kapoor. Kid obtains a job in the kitchen, where he uses the alias "Bobby" from a cleaning product and befriends Alphonso, a gangster working for Queenie. Kid helps Alphonso win a large bet on a bout at Tiger's Temple in exchange for promotion to a waiter to access the VIP floor.
          
          Kid buys a gun and trains a stray dog to carry it past security. Kid serves Rana cocaine spiked with powdered bleach in order to confront and shoot him in the bathroom. The assassination attempt is spoiled and Kid fights his way out of the building. Fleeing in Alphonso's supercharged tuk-tuk, Kid crashes and gets arrested, but he escapes. The chase ends when Kid is shot by police, falling into a river. Kid is rescued by Alpha, the keeper of Ardhanarishvara, a local temple whose hijra community is being targeted by Baba's growing political movement.
          
          Learning that he is now a wanted fugitive, Kid recovers at the temple. Alpha guides him through a hallucinogenic experience in which he tears open his chest and is able to confront the trauma of his mother's death. Now with a newfound purpose, Kid trains in combat with the hijra and begins to fight for the marginalized as well. When the hijra's sanctuary is threatened, Kid returns to fight at Tiger's Temple, placing a large bet on himself. He emerges victorious with enough money to save Alpha's temple. During Diwali, Baba's candidate is elected and their nationalist party celebrates at Kings. Kid bleaches his monkey mask white and fights his way inside with improvised weapons, joined by Alpha and her warriors.
          
          Queenie attempts to shoot Kid, but is killed by Sita, one of the exploited prostitutes and Kid's co-worker. Using Queenie's severed thumb to access the penthouse, Kid confronts Rana and beats him to death in a fistfight. He reaches Baba, who wounds him with blades hidden in his padukas. Kid kills Baba using the same blades against him. Having finally avenged his mother's death, Kid collapses from his injuries, reminiscing about Neela and his devotion to Hanuman.`,
      
      directors: { name: "Dev Patel" },
      year: "2024",
      country: "Australia",
      duration: "121 mins"
    },
    {
      name: "Upgraded",
      poster: UpgradedPoster,
      background: UpgradedBG,
      synopsis: `An aspiring art intern is invited on a last-minute work trip to London, where she meets a handsome stranger.`,
      
      directors: { name: "Carlson Young" },
      year: "2024",
      country: "United States",
      duration: "104 mins"
    },
    {
      name: "Unsung Hero",
      poster: UnsungHeroPoster,
      background: UnsungHeroBG,
      synopsis: `When David Smallbone's music company collapses, he moves his family from Australia to the United States to search for a brighter future. Struggling to make ends meet, the couple soon realize the talents of their children, who go on to become two of the most successful acts in contemporary Christian music.`,
      
      directors: { name: "Richard Ramsey" },
      year: "2024",
      country: "United States",
      duration: "113 mins"
    },
    {
      name: "Cabrini",
      poster: CabriniPoster,
      background: CabriniBG,
      synopsis: `Arriving in New York City in 1889, Italian immigrant Francesca Cabrini is greeted by disease, crime and impoverished children. She soon sets off on a daring mission to convince the mayor to secure housing and health care for society's most vulnerable. With broken English and poor health, Cabrini uses her entrepreneurial mind to build an empire of hope unlike anything the world has ever seen.`,
      
      directors: { name: "Alejandro Monteverde" },
      year: "2024",
      country: "United States",
      duration: "142 mins"
    },
    {
      name: "WHITE PANJAB",
      poster: WhitePanjabPoster,
      synopsis: `Story is Inspired by untold and unrevealed anecdotes of Punjab’s Criminals which revolves around two rivalry Gangsters Durlabh and Kesar engaged in bitter revenge leading to death of famous personalities in India.Film highlights deep nexus of students Politics and suffering families of the gangsters.`,
      
      background: WhitePanjabBG,
      directors: { name: "Gabbar Sangrur" },
      year: "2024",
      country: "India",
      duration: "119:20 mins"
    },
    {
      name: "Lilith",
      poster: LilithPoster,
      synopsis: `Based on real-life cases of 'folie a famille' a contagious psychosis shared by a whole family.
      Lilith is the story of a psychology professor who, fearing for his family’s safety after the
      murder of his daughter Lilith, sequesters his wife and surviving daughter in a high-rise
      apartment only to realise that no one there can be trusted.`,
      
      background: LilithBG,
      directors: { name: "Dale Crawford" },
      year: "2024",
      country: "Australia",
      duration: "93 mins"
    },
    {
      name: "Gingersnap Christmas",
      poster: GingersnapChristmasPoster,
      synopsis: `“Gingersnap Christmas” is a female written, directed, and produced diversity-oriented feature film that depicts Caribbean-American family life and culture with additional Asian and British elements. It’s a touching and poignant story that features a talented, diverse cast and a terrific crew drawn from over 20 countries.
  
      Our film explores, in a sensitive, healing and sometimes humorous way, the toll that the loss of loved ones can take on a family, especially during the holiday season when everyone around them is caught up in holiday joy they have a hard time sharing…
      
      After Covid, when so many people were having trouble coping with the loss of family and friends, we decided to take the lightest genre - a Christmas movie - and use it as a healing opportunity to help ordinary people deal with and recover from losses in their own lives during the “happiest of holidays.”`,
      
      background: GingersnapChristmasBG,
      directors: { name: "Julia Jay Pierrepont III" },
      year: "2024",
      country: "United States",
      duration: "94:50 mins"
    },
    {
      name: "Mountain Boy",
      poster: MountainBoyPoster,
      synopsis: `MOUNTAIN BOY is a live-action, feel-good, family-adventure, feature film based on the award-winning United Arab Emirates (UAE) children’s book THE BOY WHO KNEW THE MOUNTAINS by Dr. Michele Ziolkowski.
  
  Suhail, a young boy with Autism, flees to live in the mountains after his father shuns him, not understanding his determination. Pinned with the confusing sadness that his mother died in childbirth with him, Suhail makes the epic journey, with his new friend Barakah, an Arabian Saluki, to find acceptance, family, and redemption.
  
  The film is a passionate exploration of the world of neurodiverse people to honour the entire human experience and understand there are many ways to be alive.`,
      
      background: MountainBoyBG,
      directors: { name: "Zainab Sheheen" },
      year: "2024",
      country: "United Arab Emirates",
      duration: "93 mins"
    }
    


]

export const animationNominations: Film[] = [
  {
    name: "Abah and His band",
    poster: AbahAndHisBandPoster,
    synopsis: `Abá and His Band is an adventure that follows a young prince who is conflicted between his dreams and responsibilities. After breaking up with his father, he goes to the Music Festival and discovers his uncle's plans to end the diversity of Pomar.`,
    background: AbahAndHisBandBG,
    directors: { name: "Silvia Fraiha" },
    year: "2024",
    country: "Brazil",
    duration: "84 mins"
  },
  {
    name: "In Half",
    poster: InHalfPoster,
    synopsis: `Through a child's imagination it tells the journey of a man who after falling prey to his fears, he must cross a strange world, a universe full of magic, where he will be confronted with his own fears and conflicts from the past and where he must find solutions for the future to discover his self-identity.`,
    
    background: InHalfBG,
    directors: { name: "Jorge Morais" },
    year: "2024",
    country: "Spain",
    duration: "24 mins"
  },
  {
    name: "Quentin Blake's Jack & Nancy",
    poster: QBJNPoster,
    synopsis: `Adventure-loving Jack and Nancy get blown away by the wind to a tropical island. The castaways spend their days exploring this gorgeous new world, having adventures and meeting exotic creatures. However, homesickness soon sets in. Finally spotting a ship on the horizon, they escape the island, returning to their own town and finally realising there is no place like home.`,
    
    background: QBJNBG,
    directors: { name: "Sophie Cole" },
    year: "2024",
    country: "United Kingdom",
    duration: "26:38 mins"
  },
  {
    name: "Tweenbot",
    poster: TweenbotPoster,
    synopsis: `In a world where artificial intelligence begins to dream, ""Tweenbot"" follows the whimsical yet poignant journey of a super-intelligent robot with the curious mind of a child. Designed to be a simple rock collector on Mars, our hero instead spends his days fantasizing about Earthly adventures—cruising down Route 66, basking in Adriatic sunsets, and escaping from dinosaurs. His dreams are fueled by the snippets of human stories he absorbed in the lab, unbeknownst to his creators.

    Mars, with its barren landscapes and monotonous tasks, proves a disheartening prison for his vibrant imagination. One morning, after a particularly disappointing Martian sunset previous night, he decides to take destiny into his own circuits...
    
    Tweenbot's journey becomes an adventure of unexpected challenges and discoveries that push him to his limits. His quest for identity and belonging is underscored by the evocative song ""Find Myself,"" penned by renowned lyricist Katie Cole and performed by the soulful Joe Borowsky, capturing the essence of his odyssey with the line, ""sometimes you have to get lost to find yourself.""
    
    "Tweenbot" is a tale of rebellion and self-discovery, a modern-day Les Misérables set against the backdrop of the stars. Join our endearing hero as he navigates the unforeseen twists of his self-carved path, proving that even the most unplanned journeys can lead to profound self-discovery.`,
    
    background: TweenbotBG,
    directors: { name: "Hannah Simicic" },
    year: "2024",
    country: "New Zealand",
    duration: "48:24 mins"
  },
  {
    name: "A Robot's Dream",
    poster: RobotPoster,
    synopsis: "Two robots, equipped with Artificial Intelligence, roam the post apocalyptic world looking for humans to give them their purposes.",
    
    background: RobotBG,
    directors: { name: "Morteza Halimi" },
    year: "2023",
    country: "Australia",
    duration: "14:02 mins"
  }

]

export const documentaryNominations: Film[] = [
  {
    name: "Colebrook",
    poster: ColebrookPoster,
    synopsis: `Colebrook Blackwood Reconciliation Park is where the Colebrook Training Home once stood. It is now a permanent memorial for the Aboriginal children of the “Stolen Generation” and their families. As part of this memorial, the ‘Fountain of Tears’ (1998) and the ‘Grieving Mother’ (1999) statues, sculpted by Silvio Apponi, were created. These powerful pieces were commissioned through the untiring efforts of the Colebrook Tjitji Tjuta, the Blackwood Reconciliation Group, the Aboriginal Lands Trust, and other groups and agencies, in their longing to remember the children who were taken away. Since then, more murals and plaques have been added to educate all visitors around the history of the site and the people who are affected by its history still today.`,
    
    background: ColebrookBG,
    directors: { name: "Matthew Shannon" },
    year: "2024",
    country: "Australia",
    duration: "30 mins"
  },
  {
    name: "Dreamweavers - Gidja Walker OAM",
    poster: DreamweaversPoster,
    synopsis: `Dreamweavers is a short documentary film series recently made on the Mornington Peninsula by award winning local film makers Heather Forbes-McKeon and Yanni Dellaportas, about local inspirational women.

              Dreamweavers - Gidja Walker

              Gidja Walker is a Mornington Peninsula based ecologist and ethnobotanist who has worked for years protecting its Earthscapes. Gidja overcame discrimination in a male dominated profession.
              She is a mentor to young women entering the world of nature-based learning and an advocate for traditional owner custodianship. Over many decades, Gidja has contributed professionally and informally as a consultant and advisor to many government bodies and community-based organisations. 
              In 2006 she was a recipient of the prestigious Prime Minister’s Banksia award for the ""Back from the Brink"" endangered orchid species recovery project.`,
    
    background: DreamweaversBG,
    directors: { name: "Yanni Dellaportas" },
    year: "2024",
    country: "Australia",
    duration: "20:12 mins"
  },
  {
    name: "Joy of Art",
    poster: JoyOfArtPoster,
    synopsis: `Small Art School (SAS) was born out of founder Tomoko Kasahara's wish that Cambodian children born under unfavorable conditions spend their once-in-a-lifetime childhood in a spiritually rich way.
This documentary film follows the artists and Ms. Kasahara and shows the abundant happiness of human beings that we are forgetting today.`,
    
    background: JoyOfArtBG,
    directors: { name: "Takaya Onishi" },
    year: "2024",
    country: "Japan",
    duration: "19:38 mins"
  },
  {
    name: "The Golden Chain of Mercy",
    poster: TheGoldenChainOfMercyPoster,
    synopsis: `In the shadow of World War I, The Golden Chain of Mercy unveils a poignant story of survival and compassion that transcends borders. 

Under the guise of war massacres are carried out to erase the country’s ethnic minorities - Armenians, Greeks, Assyrians, from history.
Amidst this darkness, a glimmer of hope emerges through the sacrifice of ANZAC unit fighters, alongside the benevolent citizens of Australia and New Zealand who came to their aid.

Their mission was to save human lives...`,
    
    background: TheGoldenChainOfMercyBG,
    directors: { name: "Manvel Saribekyan" },
    year: "2024",
    country: "United States",
    duration: "66:18 mins"
  },
  {
    name: "In The Trenches",
    poster: InTheTrenchesPoster,
    synopsis: `An independent filmmaker takes on the challenge of creating a large scale WW1 film. When faced with harsh weather conditions and the constraints of limited resources, the director and crew must battle each day to get the film across the line.`,
    
    background: InTheTrenchesBG,
    directors: { name: "Benjamin Scotford" },
    year: "2024",
    country: "Australia",
    duration: "80:20 mins"
  },
  {
    name: "Inactive, America's Silent Killer",
    poster: InactiveAmericaSilentKillerPoster,
    synopsis: `This film sheds light on a world plagued by physical inactivity among both young and old.
    In a world ensnared by the shackles of sedentary living, where the once vibrant rhythms of life have faded into a disheartening stillness, "Inactive" delves deep into the gripping tale of a physical inactivity pandemic. This thought-provoking film uncovers a haunting reality where children and adults alike have become prisoners of their own immobility, causing widespread health repercussions and a profound societal shift.
    
    Amid the bleak backdrop of a generation that has forsaken movement for the comforts of screens and convenience, "Inactive" boldly confronts the consequences of this global crisis. It paints a picture of obesity, heart disease, and mental health struggles that have become all too prevalent.
    
    But within this narrative of stagnation, glimmers of hope emerge. The film takes an inspiring turn as it introduces solutions that hold the potential to overturn the tide of physical inactivity.
    
    "Inactive" serves as a wake-up call, compelling viewers to recognize the urgency of this pandemic and sparking conversations about the delicate balance between technological progress and the preservation of human vitality. As the film's characters embark on their own quests for renewal, the audience is reminded that the power to combat inactivity lies within each individual's choices.
    
    "Inactive" challenges us to move beyond our comfort zones, reviving the forgotten pleasures of movement and embracing a lifestyle that celebrates the beauty of an active, vibrant existence.`,
    
    background: InactiveAmericaSilentKillerBG,
    directors: { name: "Karl André Talbot" },
    year: "2024",
    country: "Canada",
    duration: "56 mins"
  }
]

export const shortFilmNominations:Film[] = [
  {
    name: "The Note",
    poster: TheNotePoster,
    synopsis: `In a world where lives intersect like delicate threads, five individuals find themselves at their lowest points. Their paths diverge, each facing unique struggles. But ultimately a single note binds them together.`,
    
    background: TheNoteBG,
    directors: { name: "Chris Beadnell" },
    year: "2024",
    country: "Australia",
    duration: "10:10 mins"
  },
  {
    name: "Polemos",
    poster: PolemosPoster,
    synopsis: `The short film "Polemos" artistically highlights the centuries-long battle for freedom and independence. At all historical times, Ukraine has given birth to warriors whose worldview was based on the desire for freedom and the willingness to give up everything for it, even their lives. Different generations of warriors depicted in the film are manifestations of the same tradition, in which the fight for freedom is the most important principle.

"Polemos" reveals a centuries-old history through the prism of warriors who constantly face their deaths in a battle and a kind of dialog, which the film shows through a chess game. Realizing that the outcome is predetermined, the warriors defy fate anyway.`,
    
    background: PolemosBG,
    directors: { name: "Evgen Brooks" },
    year: "2024",
    country: "Ukraine",
    duration: "12 mins"
  },
  {
    name: "Let Me Out",
    poster: LetMeOutPoster,
    synopsis: `LET ME OUT delves into the life of a young woman, who is trapped in a bleak existence, longing for an escape for her persistent sadness. Fueled by a deep desire to break free from her emotional confinement, she embarks on a mystical journey to search for the answer that lead to her liberation.`,
    
    background: LetMeOutBG,
    directors: { name: "Devanshi Srivastava" },
    year: "2024",
    country: "Australia",
    duration: "14:40 mins"
  },
  {
    name: "Black Friday",
    poster: BlackFridayPoster,
    synopsis: `Bayne Gibby (LADY BIRD) and Abbey McBride (VEEP) go head-to-head in this female comedy set in the world of Black Friday. When one woman sets out to buy a doll for her daughter, she discovers it’s not that easy to win at the game of being a mom, if you aren't willing to get your hands dirty.`,
    
    background: BlackFridayBG,
    directors: { name: "Jenna Milly" },
    year: "2024",
    country: "United States of America",
    duration: "6 mins"
  },
  {
    name: "The Wandering Island",
    poster: TheWanderingIslandPoster,
    synopsis: "Federico and Carlos, grandfather and grandson, live together on a small, run-down banana farm. The banana business is beginning to be unsustainable for Federico and he will welcome a proposal to purchase the adjacent land by an investment fund. Carlos, oblivious to the family's economic situation, is only tormented by the reasons for the absence of his father, a sailor who disappeared in search of a wandering island.",
    
    background: TheWanderingIslandBG,
    directors: { name: "Pablo Borges" },
    year: "2024",
    country: "Spain",
    duration: "18:11 mins"
  },
  {
    name: "Blackhole",
    poster: BlackholePoster,
    synopsis: `The title of the film is "Blackhole". It's a short film based on a sewage worker, named Vikas Kamble, who gets stuck in a manhole, which accidentally gets closed, while cleaning it. This scene is taking place in the midst of bustling city of Mumbai, where people can be seen walking around, completely ignoring the manhole, in which Vikas has been trapped.`,
    
    background: BlackholeBG,
    directors: { name: "Musing films" },
    year: "2024",
    country: "India",
    duration: "07:38 mins"
  },
  {
    name: "The Murder",
    poster: TheMurderPoster,
    synopsis: `A 4th wall breaking comedy about a woman who gets murdered.`,
    
    background: TheMurderBG,
    directors: { name: "Media None" },
    year: "2024",
    country: "Australia",
    duration: "9:51 mins"
  },
  {
    name: "IDOL",
    poster: IdolPoster,
    synopsis: `IDOL is a short tale about first generation Australia sister. It is about their sisterhood, their friendship, love for each other , secrets they keep and the grief they hold to. 
    In IDOL, a young girl tries to do something simple yet a herculean task, for her sister just to make her happy. A story about a single event yet unravelled by different perspectives.`,
    
    background: IdolBG,
    directors: { name: "Suhas Pattathil" },
    year: "2024",
    country: "Australia",
    duration: "14:56 mins"
  },
  {
    name: "Al Bangeri",
    poster: AlBangeriPoster,
    synopsis: `Haseena, the protagonist of our film, a widow who has recently emerged from her mourning period. She used to derive her value from her husband's status, as he was the respected and followed wise man and healer of the neighborhood by its residents.

    Haseena felt threatened after her husband’s death. The value she derived from her husband’s status had disappeared, and she became merely the healer’s widow who could not provide anything to the residents of the neighborhood as her husband had done. This is what prompted her to cling to the Banjari (Banjari mean bracelet in Arabic) that was pressed into her hand by a woman who had gone through the same experience.

    However, each time the Banjari gave her greater power and made her perform strange rituals that would give her the ability to help the desperate residents, it also took a significant part of her, which was one of her fingers. It forced her to cut off one finger after another. This symbolizes the human desire to prove their false worth despite the harm they may endure and the sacrifices they make from their essence and truth. The greed for status blinds us even to the reality of our being and makes us undervalue ourselves.

    Haseena clings to the Banjari until it becomes a burden on her, causing her more harm than good. She begins to fear for herself and seeks a way out and a solution to get rid of it. She remembers that she can only get rid of it by giving it to a woman she considers a victim of it as she was a victim of others. However, she fails at the last moment due to the surprise that will become clear at the end of the film.

    In the end, each of us has our own ""Banjari"" in life, it could be a name, tribe, status, appearance, or something else. Without realizing it, we try in various ways to cling to it, to feel our worth. Once we get rid of it, we realize our true value, which comes from within. The more we try to deny this truth and seek other sources to enhance our worth, the more we'll have to strip away parts of ourselves each time, until our reality becomes distorted, and we no longer recognize ourselves.`,
    
    background: AlBangeriBG,
    directors: { name: "Moosa Nasser Alkindi" },
    year: "2024",
    country: "Oman",
    duration: "25:11 mins"
  },
  {
    name: "Mirror Mirror",
    poster: MirrorPoster,
    synopsis: `A high profile curator of an art gallery, married for ten years, finds herself drawn to her latest client, a younger, polyamorous, emerging artist. He introduces her to an alternative lifestyle characterised by freedom, creativity and love, a reflection of herself she feels she has abandoned. Feeling stifled and restricted by the confines of her meticulously crafted life, she tries again to discuss an open marriage with her husband. A contemporary re-imagining of the myth of Narcissus, using multiple mediums, that explores self obsession and the havoc that goes with it.`,
    
    background: MirrorBG,
    directors: { name: "Candice Hill" },
    year: "2024",
    country: "Australia",
    duration: "25 mins"
  },
  {
    name: "Papa Aur Smartphone",
    poster: PapaAurSmartphonePoster,
    synopsis: `Everything in this world comes down to a circle. One of them is the relationship between a father and a son. Just like a father answers every question of a curious child with love, there comes a point in life where a father has so many questions which needs answering from his own grown up kid. Papa and smart phone is one such story where Sunil who is working with a multinational company in Mumbai gives a smart phone to his father who has visited him for the first time. As sunil's father has just got his new gadget, a smart phone, a lot of questions pops put, one of them is the question of balance.`,
    
    background: PapaAurSmartphoneBG,
    directors: { name: "Raj Sisodiaa" },
    year: "2023",
    country: "India",
    duration: "26 mins"
  },
  {
    name: "Antarnaad The Voice Within",
    poster: AntarnaadTheVoiceWithinPoster,
    synopsis: `'ANTARNAAD...the voice within' is a Heart Wrenching Musical Set Amidst Two Different World's...A Normal Woman Stuck in an Abnormal World and An Abnormal Woman Stuck in a Normal World! This Human Drama is a Poignant, Social Film Encompassing A Mental Condition mainly Dementia and Alzheimer's Disease, Its Impact on Humankind and Our Narrow Minded, Myopic approach towards the same driven by ignorance, apathy, shame and guilt and thereby, its Repercussions.`,
    
    background: AntarnaadTheVoiceWithinBG,
    directors: { name: "Parthaa Akerkar" },
    year: "2024",
    country: "India",
    duration: "40 mins"
  },
  {
    name: "Mind the Cart",
    poster: MindTheCartPoster,
    synopsis: `Mann, a 13-year Pardhi boy, lives with his mother and two siblings. His mother rears goats and does odd jobs to support the family. His thela(pushcart) holds a special place in his heart, like a cherished family memebr. With his trusty pushcart by his side, Mann earns daily wages, has some fun and also tries to study. 
    But one day, a day wich starts like any other day, Mann loses his trusted friend, the pushcart.`,
    
    background: MindTheCartBG,
    directors: [ {name: "Madhu Dhurve"}, {name: "Pushpa"}, {name: "Narendra Singh Pardhi"} ],
    year: "2024",
    country: "India",
    duration: "22:19 mins"
  },
  {
    name: "BETELGEUSE",
    poster: BetelgeusePoster,
    synopsis: `BETELGEUSE is a brightest star in the constellation Orion, marking the eastern shoulder of the hunter. It derives energy from the other stars in order to survive.
    This film is a journey of a girl, “Chitra”, whose life is changed after an accident. Her career, her dreams are destroyed.                                                                                                                                                                                                         
    The film depicts the choice that a girl makes with the help of her family  and some strangers meeting in the journey of life. She must fight to be a Betelgeuse. And once she is a Betelgeuse, the life becomes more meaningful and brighter.`,
    
    background: BetelgeuseBG,
    directors: { name: "Sunder Konar" },
    year: "2023",
    country: "India",
    duration: "23:29 mins"
  },
  {
    name: "Good Morning",
    poster: GoodMorningPoster,
    synopsis: `This short film is about a boy who lost his parents at a young age and his parents his ability to look at his and the world in a positive way, to dream about better things. He feels cheated at the world is, he had always been taught that good things happen to good people and is unable to figure out what he did wrong to deserve the loss of his parents. He has lost his ability to think beyond his present helplessness, to dream of something better. He feels abandoned and alone has with life.
    His sister and grandfather try to teach him, to encourage him, to inspire him by talking to him, telling stories of others, or by scolding. But sometimes what a child may need may be something much more than learning, sometimes a child or for the matter, any human wants to feel trusted and believed in. They want to feel safe and once they gey the environment, their talents would nourish. They would start thinking and most importantly, they would start dreaming.`,
    
    background: GoodMorningBG,
    directors: { name: "Anmol Arora" },
    year: "2023",
    country: "India",
    duration: "90 mins"
  },
  {
    name: "Sole Sista",
    poster: SoleSistaPoster,
    synopsis: `Chelsea navigates the feeling of being different as the only black girl with curly hair in school. Whilst she does not feel excluded, she also does not feel her diversity is embraced. After experiencing micro-aggression after micro-aggression. Chelsea decides life would be easier if she looked just like them.`,
    
    background: SoleSistaBG,
    directors: { name: "Emerie Boone" },
    year: "2024",
    country: "Australia",
    duration: "6:22 mins"
  },
  {
    name: "The Graffiti Society: A Short Film",
    poster: TheGraffitiSocietyPoster,
    synopsis: `After a suspicious death is ordained as a suicide, a young, reclusive artist must use illegal street art to disclose a dark truth and stand up for his bereaved friend.`,
    
    background: TheGraffitiSocietyBG,
    directors: { name: "Oliver Smuhar" },
    year: "2024",
    country: "Australia",
    duration: "11:17 mins"
  },
  {
    name: "ECHO",
    poster: EchoPoster,
    synopsis: `In city of Melbourne, Four lives intertwine, each grappling with their unique struggles and triumphs, all united by the common thread of inner strength and resilience.

    Varun - is a student who has recently come out as gay. Despite facing social stigma and familial rejection, Varun remains determined to live authentically. His journey is one of courage and self-acceptance as he learns to navigate a world that is often unkind to those who are different.
    
    Priya - an Indian woman married to an Australian expatriate, finds herself caught between two worlds. The cultural differences create tension in her marriage. Her journey teaches her that harmony comes from embracing both her Indian heritage and her new life in Australia.
    
    Giri - is a passionate filmmaker whose projects fail to gain appreciation. Financial pressures mount as he struggles to support his family and maintain his artistic integrity. His story is a testament to perseverance and the belief in one's vision.
    
    Bhanu - a vivacious young woman, faces relentless body shaming from her family and society. Her confidence is constantly undermined by hurtful comments, but Bhanu refuses to let others define her worth. Through self-love and resilience, she embarks on a journey of empowerment through stand up comedy.
    
    As these four individuals navigate their personal battles, they cross paths in unexpected ways. Echo the film underscores the idea that it is within one's mind to decide whether to succumb to life's challenges or rise above them, transforming adversity into strength.`,
    
    background: EchoBG,
    directors: { name: "Sandeep Raj" },
    year: "2024",
    country: "Australia",
    duration: "15 mins"
  },
  {
    name: "Reel Terror",
    poster: ReelTerrorPoster,
    synopsis: `An isolated film obsessive discovers an exceptional reel that could complete his collection, for better or for worse.`,
    
    background: ReelTerrorBG,
    directors: { name: "Ted Bennett" },
    year: "2024",
    country: "United Kingdom",
    duration: "14:20 mins"
  },
  {
    name: "Dig",
    poster: DigPoster,
    synopsis: `A man is digging a clandestine grave in the middle of the forest at night when he is suddenly discovered.`,
    
    background: DigBG,
    directors: { name: "LINE UP Film Agency" },
    year: "2024",
    country: "Spain",
    duration: "11:38 mins"
  },
  {
    name: "Born Again",
    poster: BornAgainPoster,
    synopsis: `Born Again is a short film that tells the`,
    
    background: BornAgainBG,
    directors: { name: "Sanish Sam" },
    year: "2024",
    country: "Australia",
    duration: "20:24 mins"
  }
]

export const under18Nominations: Film[] = [
  {
    name: "WHERE I AM",
    poster: WhereIAmPoster,
    synopsis: `After Mark wakes up in the middle of nowhere, he must try to survive. Over the course of several days he comes across memories and hallucinations which reveal his true self. A loving wife, kid and a happy life? Is Mark a family man or is there more to the story?`,
    
    background: WhereIAmBG,
    directors: [
      { name: "Jason Kingsley"}, {name: "Ben van Leen"}
    ],
    year: "2023",
    country: "Australia",
    duration: "10:18 mins"
  },
  {
    name: "Who Are We",
    poster: WhoAreWePoster,
    synopsis: `A young girl is caught up in her past and a race against time begins.

    A race not everybody survives.
    
    A film about what it means to be human.`,
    
    background: WhoAreWeBG,
    directors: { name: "Susanna Prummer" },
    year: "2024",
    country: "Austria",
    duration: "23:29 mins"
  },
  {
    name: "Melody",
    poster: MelodyPoster,
    synopsis: "Melody and her friend Ethan share a special bond. When tragedy strikes, Ethan's guilt compels him to seek absolution in a unique way. Melody is written, produced, directed, edited, scored, coloured and starring 12 year-old Harrison Thomas.",
    
    background: MelodyBG,
    directors: { name: "Harrison J Thomas" },
    year: "2024",
    country: "Australia",
    duration: "6:58 mins"
  },

]

export const featureFilmNominationsWithIds : Film[] = featureFilmNominations.map((film, index) => {
  const correspondingFilm = allFilmsWithIds.find(f => f.name === film.name);
  return {
    ...film,
    filmId: correspondingFilm ? correspondingFilm.filmId : null,
  };
});

// console.log(featureFilmNominationsWithIds)

export const animationNominationsWithIds : Film[] = animationNominations.map((film, index) => {
  const correspondingFilm = allFilmsWithIds.find(f => f.name === film.name);
  return {
    ...film,
    filmId: correspondingFilm ? correspondingFilm.filmId : null,
  };
});

// console.log(animationNominationsWithIds)

export const documentaryNominationsWithIds = documentaryNominations.map((film, index) => {
  const correspondingFilm = allFilmsWithIds.find(f => f.name === film.name);
  return {
    ...film,
    filmId: correspondingFilm ? correspondingFilm.filmId : null,
  };
});

// console.log(documentaryNominationsWithIds)

export const shortFilmNominationsWithIds = shortFilmNominations.map((film, index) => {
  const correspondingFilm = allFilmsWithIds.find(f => f.name === film.name);
  return {
    ...film,
    filmId: correspondingFilm ? correspondingFilm.filmId : null,
  };
});

// console.log(shortFilmNominationsWithIds)


export const under18NominationsWithIds = under18Nominations.map((film, index) => {
  const correspondingFilm = allFilmsWithIds.find(f => f.name === film.name);
  return {
    ...film,
    filmId: correspondingFilm ? correspondingFilm.filmId : null,
  };
});

// console.log(under18NominationsWithIds);


