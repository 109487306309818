import { motion } from "framer-motion";
import { Film } from "../../Data/films";
import { useState } from "react";
import { useNavigate } from "react-router";

type FilmCardProps = {
    film: Film;
};

function convertToThumbnailUrl(googleDriveUrl: string, width = 1000): string {
    let fileId;

    const matchPath = googleDriveUrl.match(/\/d\/(.+?)\//);
    if (matchPath) {
        fileId = matchPath[1];
    } else {
        const matchQuery = googleDriveUrl.match(/id=([^&]+)/);
        if (matchQuery) {
            fileId = matchQuery[1];
        }
    }

    if (!fileId) {
        console.error('Invalid Google Drive URL:', googleDriveUrl);
        return googleDriveUrl;
    }

    return `https://drive.google.com/thumbnail?id=${fileId}&sz=w${width}`;
}



function FilmCardSubmission({ ...props }: FilmCardProps) {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);
    

    const handleFilmClick = (film: Film) => {
        // console.log("Film clicked: ", film);
        navigate("/synopsis/" + film.filmId);
    }

    const fadeInAnimationVariants = {
        initial: {
            opacity: 0,
            y: 100,
        },
        animate: {
            opacity: 1,
            y: 0,
        }
    }

    // const thumbnailUrl = convertToThumbnailUrl(props.film.poster);
    // console.log(thumbnailUrl);

    return (
        <motion.div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => handleFilmClick(props.film)}
            className="relative max-[480px]:w-[80vw] w-[24rem] max-[480px]:h-[106vw] h-[32rem] rounded-[16px] overflow-hidden cursor-pointer transition-transform duration-700 ease-in-out hover:shadow-xl hover:shadow-white"
            variants={fadeInAnimationVariants}
            initial={{ opacity: 0, x: -150 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
        >
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: isHovered ? 1 : 0 }}
                transition={{ duration: 0.3 }}
                className="absolute top-1/2 left-1/2 w-full h-full transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center text-center bg-black/90 rounded-lg shadow-md backdrop-blur-md px-2.5 py-2.5 text-white opacity-0 pointer-events-none transition-opacity duration-300 ease-in-out"
            >
                <h1 className="text-5xl font-semibold">{props.film.name.toUpperCase()}</h1>
                {Array.isArray(props.film.directors)
                    ? props.film.directors.map(
                        (dir, index) =>
                            <p className="font-light" key={index}>
                                {dir.name}
                            </p>
                    )
                    : <p>{props.film.directors.name}</p>}
            </motion.div>
            <img src={props.film.poster} alt={`${props.film.name} Poster`} className="w-full h-full object-cover transition-transform duration-300 ease-in-out" />
        </motion.div>
    );
}

export default FilmCardSubmission;