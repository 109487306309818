import { motion } from "framer-motion"
import { Link } from "react-router-dom"

type NavlinkProps = {
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    link: string,
    text: string,
    isSubmit?: boolean
}

const Navlink = ({...props} : NavlinkProps) => {
    return (

            props.isSubmit ?
            <motion.div className="flex relative items-center ml-0 max-lg:ml-0 group hover:shadow-md hover:shadow-light-gold font-bold text-xl p-2 rounded-lg text-dark-gold"
                whileHover={{ scale: 1.1 }}
                transition={{ type: 'spring', stiffness: 300 }}
            >
                <Link to={props.link} onClick={() => props.setIsOpen(false)}>{props.text}</Link>
                {/* <motion.span className="w-2 h-2 absolute -right-4 rounded-full bg-white group-hover:opacity-0 transition-opacity max-lg:hidden"/> */}
            </motion.div>
            :
            <motion.div className="flex relative items-center ml-0 max-lg:ml-0 group hover:bg-slate-900 font-bold text-xl p-2 rounded-lg text-white max-lg:hover:text-white"
                whileHover={{ scale: 1.1 }}
                transition={{ type: 'spring', stiffness: 300 }}
            >
                {/* <motion.span className="w-2 h-2 absolute -left-4 rounded-full bg-white group-hover:opacity-0 transition-opacity max-lg:hidden"/> */}
                <Link to={props.link} onClick={() => props.setIsOpen(false)}>{props.text}</Link>
            </motion.div>
    )
}

export default Navlink;